
import React from 'react';
import { Message } from 'primereact/message';

const ToastComponent = ({severity,detail}) => {
  
    
    return (
      <>
    <div className='toastmsg'>
    <Message severity={severity} text={detail}/>
    </div>
     </>
    )

}

export default ToastComponent;