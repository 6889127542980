import React ,{useState} from 'react'
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideNav = () => {
  
    const location = useLocation();
    const pathName = location.pathname;
    const userData = JSON.parse(sessionStorage?.getItem("userdata"));
    const data = userData?.data;
    const role = data?.user.role
  return (
 <>
 <div className='leftmenu'>
  <div className='sideicons'>

  
<img  className='logo' src='./images/lattest/newlogo.png'/>
<NavLink
            exact
            to="/market"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
            {pathName === "/market" ? 
            <div className='from_bottomac_active'>
            <img src="\images\lattest\market intelligence_active.svg" alt="leads" />
            <div className='pageiconlabel'>
            <label>Market <br/> Intelligence</label>
            </div>
            </div>
            
             :
             <div className='from_bottomac'>
            <img src="\images\lattest\market intelligence_inactive.svg" alt="leads"  />
             <div className='pageiconlabelhover'>
             <label>Market <br/>Intelligence</label>
            </div>
               </div>
             
             }
          </NavLink>
<NavLink
            exact
            to="/leads"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
        
             {pathName === "/leads" ?
             <div className='from_bottomac_active'>
            <img src="\images\lattest\leadsactive.svg" alt="leads" /> 
             <div className='pageiconlabel'>
             <label>Intelligent <br/> Leads</label>
             </div>
             </div>
              :
              <div className='from_bottomac'>
             <img src="\images\lattest\leadsinactive.svg" alt="leads"   />
               <div className='pageiconlabelhover'>
               <label>Intelligent <br/>Leads</label>
              </div>
                 </div>
              }
            
            
          </NavLink>
          {/* <NavLink
            exact
            to="/buyers"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
            {pathName === "/buyers" ?
             <div className='from_bottomac_active'>
            <img src="\images\lattest\intelligent leads_active.svg" alt="leads" />
              <div className='pageiconlabel'>
              <label>Owner <br/> Profiles</label>
              </div>
              </div>
            
             :
             
             <div className='from_bottomac'>
             <img src="\images\lattest\intelligent leads_inactive.svg" alt="leads"  />
               <div className='pageiconlabelhover'>
               <label>Owner <br/>Profiles</label>
              </div>
                 </div>
             }
          </NavLink> */}
{/* 
          <NavLink
            exact
            to="/analytics"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
        
             {pathName === "/analytics" ?
              <div className='from_bottomac_active'>
              <img src="\images\lattest\deal analytics_active.svg" alt="leads" />
                <div className='pageiconlabel'>
                <label>Deal <br/> Analytics</label>
                </div>
                </div>
               :
               <div className='from_bottomac'>
             <img src="\images\lattest\deal analytics_inactive.svg" alt="leads"   />
               <div className='pageiconlabelhover'>
               <label>Deal <br/>Analytics</label>
              </div>
                 </div>
              }
            
            
             
          </NavLink> */}
 <NavLink
            exact
            to="/datahub"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
        
             {pathName === "/datahub" ?
             <div className='from_bottomac_active'>
             <img src="\images\lattest\buyers n sellers_active.svg" alt="leads" />
             <div className='pageiconlabel'>
              <label>Data Hub</label>
             </div>
             </div>
               :
               <div className='from_bottomac'>
              <img src="\images\lattest\buyers n sellers_inactive.svg" alt="leads"   />
              <div className='pageiconlabelhover'>
              <label>Data Hub</label>
             </div>
                </div>
              }
            
            
            
          </NavLink>
          
         
          {/* {role === 'Broker' ? null  : 
          <> */}
          {role === 1 ? 
          <>
          {/* <NavLink
            exact
            to="/user"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
        
             {pathName === "/user" ?

             <div className='from_bottomac_active'>
              <img src="\images\lattest\user engagement_active.svg" alt="leads" />
                <div className='pageiconlabel'>
                <label>User <br/> Engagement</label>
                </div>
                </div>
               :
               <div className='from_bottomac'>
              <img src="\images\lattest\user engagement_inactive.svg" alt="leads"   />
               <div className='pageiconlabelhover'>
               <label>User <br/>Engagement</label>
              </div>
                 </div>
             
              }
            
          </NavLink>
            */}
          <NavLink
          exact
          to="/rbac"
          className="lmenuitemac"
          activeclassname="lmenuitemactiveac"
        >
      
           {pathName === "/rbac" ?
           <div className='from_bottomac_active'>
        <img src="\images\lattest\Access_active.svg" alt="leads" />
        <div className='pageiconlabel'>
        <label>Access <br/>Control</label>
        </div>
           </div>
             :
             <div className='from_bottomac'>

               <img src="\images\lattest\Access_inactive.svg" alt="leads"   />
               <div className='pageiconlabelhover'>
        <label>Access <br/>Control</label>
        </div>
             </div>
}
          
        </NavLink>
        </>
        : null }
        {/* <NavLink
            exact
            to="/reportnew"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
        
             {pathName === "/reportnew" ?
             <div className='from_bottomac_active'>
             <img src="\images\lattest\report builder_active.svg" alt="leads" />
             <div className='pageiconlabel'>
              <label>Report <br/>Old</label>
             </div>
             </div>
               :
               <div className='from_bottomac'>
              <img src="\images\lattest\report builder_inactive.svg" alt="leads"   />
              <div className='pageiconlabelhover'>
              <label>Report <br/>Old</label>
             </div>
                </div>
              }
            
            
            
          </NavLink> */}
        {/* </>
          
        
         }  */}
         {/* <NavLink
            exact
            to="/ownershipgroup"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
        
             {pathName === "/ownershipgroup" ?
             <div className='from_bottomac_active'>
             <img src="\images\lattest\ownershipactive.svg" alt="leads" />
             <div className='pageiconlabel'>
              <label>Ownership <br/>Group</label>
             </div>
             </div>
               :
               <div className='from_bottomac'>
              <img src="\images\lattest\ownershipinactive.svg" alt="leads"   />
              <div className='pageiconlabelhover'>
              <label>Ownership <br/>Group</label>
             </div>
                </div>
              }
            
            
            
          </NavLink>    */}
   </div>       
<div className='sideiconsHelp'>
 
{/* <NavLink
            exact
            to="/rating"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
            {pathName === "/rating" ?
             <div className='from_bottomac_active'>
            <img src="\images\lattest\customer-support_active.svg" alt="leads" />
              <div className='pageiconlabel'>
              <label>Help & <br/> Support</label>
              </div>
              </div>
            
             :
             
             <div className='from_bottomac'>
             <img src="\images\lattest\customer-support_inactive.svg" alt="leads"  />
               <div className='pageiconlabelhover'>
               <label>Help & <br/> Support</label>
              </div>
                 </div>
             }
          </NavLink>   */}
         </div>
            </div>
 </>

   
  )
}

export default SideNav;