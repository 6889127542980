import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IdleTimeout from '../../pages/idelTimeout';
import './header.css'
import useLogout from '../../pages/Logout';


const HeaderTop = ({ pageTitle, imageUrl }) => {
  const userData = JSON.parse(sessionStorage?.getItem("userdata"));
  let user= userData?.data.user;
  let subauth = userData?.data.message;
  const fname = user.firstName;
  const role = user.role;
  const { handleLogout } = useLogout();

  const handleClick = async (e) => {
    e.preventDefault();
    await handleLogout();
  };
  return (
    <React.Fragment>
     <div className='headertop'>
       <IdleTimeout/>
<div className='hleft'>

<img  className = 'headerimg' src={imageUrl} width='30px' margin='0px 10px'/>
<h4 className='headertitle'> {pageTitle}</h4>
</div>
<div className='hright'>
<div className="dropdownheader">
          <button className="dropbtn p-d-flex p-ai-center" style={{ background: "none", border: "none" }}>
            <img className="headerimg" src="\images\lattest\profile.svg" alt="user" width="20px" />
          <span className="hrightaction"><label style={{marginRight:"5px"}}>Welcome</label>  
          {subauth !== "Please add Subscription" ? fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase() : "User"}
          </span> 
          </button>
          <div className="dropdown-content">
          <Link
              onClick={handleClick}
              style={{ textDecoration: "none", color: "#8D8D8D" }}
            >
              <i
                className="pi pi-sign-out"
                style={{ fontSize: "1em", marginRight: "10px" }}
              ></i>
              <span className="hrightaction">Log Out</span>
            </Link>
          </div>
        </div>
 

    </div>
    </div>
  </React.Fragment>
  );
};

HeaderTop.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default HeaderTop;