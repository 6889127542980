import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   access_token: sessionStorage.getItem('access_token')
  //     ? sessionStorage.getItem('access_token')
  //     : '',
  //   authorizedtoken: sessionStorage.getItem('authorizedtoken')
  //     ? sessionStorage.getItem('authorizedtoken')
  //     : '',
  // },
});

// http.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     return Promise.resolve({ error });
//   }
// );

export default http;
