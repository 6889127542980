import React from 'react'
import "./Loader.css";

const Loader = () => {
  return (
    <>
    <div style={{width:"100%"}}>
      <div className="logoloader">
        <img
          className="logoloaderimg"
          src="./images/lattest/newlogo.png"
        />
      </div>
      <div className="loading">
        <span className="loadingdot"></span>
        <span className="loadingdot"></span>
        <span className="loadingdot"></span>
      </div>
    </div>
  </>
  )
}

export default Loader
