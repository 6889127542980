import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import './IdleTimeout.css';
import IdleTimer from 'react-idle-timer';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import useLogout from './Logout';


const IDLE_TIMEOUT = 3600000; // 60 minutes in milliseconds

const IdleTimeout = () => {
  const { handleLogout } = useLogout();

  const handleClick = async (e) => {
    await handleLogout();
  };

  const [isIdle, setIsIdle] = useState(false);
  const [displayBasic, setDisplayBasic] = useState(true);
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();
  let timeout;
  const dialogFuncMap = {
    'displayBasic': setDisplayBasic,
}

  const renderFooter = (name) => {
    return (
        <div>
            <Button label="OK" onClick={handleClick} className="p-button-text" />
        </div>
    );
}
const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
      handleClick();
    // navigate('/logout');
}
    const handleIdle = () => {
        // Perform actions when the user becomes idle
        // For example, log out the user or redirect to the login page
        console.log('idel time ')
       
      setDialog(true);
      };

  return (
    <IdleTimer
      timeout={3600000} 
      // 60 mint for testing purposes (change to desired timeout)
      onIdle={handleIdle}
    >
     {dialog === true ? 
        <Dialog visible={displayBasic} style={{ width: '50vw', position: 'absolute' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <p> You have been logged out due to inactivity. Please login again</p>
                </Dialog>
                :
                ''
     
     }
    </IdleTimer>

  );
};

export default IdleTimeout;
