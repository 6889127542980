import React, { useState, useEffect , useRef } from "react";
import { InputNumber } from 'primereact/inputnumber';
// import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import TextField from "@mui/material/TextField";
// import { Timeline } from "primereact/timeline";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import moment from "moment";
// import "../../components/AAANEWCOMPONENT/timeline/timeline.css";
import { Dialog } from "primereact/dialog";
import { Button } from "@material-ui/core";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import "./intelligentLeads.css";
import LeadsCards from "../../components/AAANEWCOMPONENT/LeadsCards/leadsCards";
// import RatingComponent from "./rating";
import { formatPhoneNumber } from "../../middlewares/FormatPhoneNumber";
// import Chat from "./chat";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';
import ToastComponent from "../../components/AAANEWCOMPONENT/toast";
import { ListBox } from 'primereact/listbox';
// import { Chip } from 'primereact/chip';
        // import { Button } from 'primereact/button';
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from 'primereact/radiobutton';              
import { Chips } from "primereact/chips";
import { useNavigate } from 'react-router-dom';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Slider } from 'primereact/slider';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import  StateData  from '../../Data/stateData.json'
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";
import useAddAction from "../../components/AAANEWCOMPONENT/ActionAndSession/addAction";
import { IconButton } from '@material-ui/core';
// import { CalendarToday as CalendarIcon } from '@material-ui/icons';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import LeadsData from '../../Data/testleads.json'
import { Sidebar } from 'primereact/sidebar';
const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const IntelligentLeads = () => {
  const[visible,setVisible]=useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [propUnits, setPropUnits] = useState([]);
  const [selectedPropUnits, setSelectedPropUnits] = useState([]);
  const [demographicsData, setDemographicsData] = useState([]);
    const [maxUnit, setMaxUnit] = useState([]);
  const [minUnit, setMinUnit] = useState([]);
  const [maxYear, setMaxYear] = useState([]);
  const [minYear, setMinYear] = useState([]);
  const [market, setMarket] = useState([]);
  const [submarket, setSubmarket] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [yocdates, setYOCDates] = useState(null);
  const [propunitrange, setPropUnitRange] = useState([0,250]);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [searchSelected,setSearchSelected] = useState({
    property:true,
    owner:false,
   });
  const [filterValue, setFilterValue] = useState([]);
  const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
  const userToken = userDetails.data.token;
  const userData = JSON.parse(sessionStorage.getItem("userdata"));
  const fname = userData.data.name;
  const uname = userData.data.userName;
  // const org = userData.data.organization;
  const [selectedListedStatus, setSelectedListedStatus] = useState([]);
  const [selectedFinancialStatus, setSelectedFinancialStatus] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filteredLeadsData, setFilteredLeadsData] = useState([]);
  const [targetedStatus, setTargetedStatus] = useState({});
  const [targetedLead, setTargetedLead] = useState({});
  const [dealValueAmount, setDealValueAmount] = useState('');
  const [property_id, setPropertyID] = useState();
  const [selectedOwner,setSelectedOwner]= useState([]);
  const[showdealerror,setShowDealError]=useState(false);
  const[showInterestedError,setShowInterestedError]=useState(false);
  const[showListedError,setShowListedError]=useState(false);
  const [searchData , setSearchData] = useState("");
  const [searchText , setSearchText] = useState("");
  const [showLeadStatusChangeConfirmation, setShowLeadStatusChangeConfirmation]=useState(false);
  const [CNotes, setCNotes] = useState(false);
  const [leadnotesChangeToast, setLeadNotesChangeToast] = useState(false);
 const [movedStatus,setMovedStatus] = useState([]);
 const [FinancialDetail,setFinancialDetail] = useState(null);
 const [ListedDetail,setListedDetail] = useState(null);
 const [addLeadNotes, setAddLeadNotes] = useState("");
 const [leadsnotesToast, setLeadNotesToast] = useState(false);
 const [ownerList,setOwnerList]=useState([]);
 const [propertyList,setPropertyList]=useState([]);
 const [showNoData,setShowNoData]=useState(false);
 const [InternalDropdownSelected,setInternalDropdownSelected] = useState(false);
 const [checkedListed,setCheckedListed] = useState({
  listed:true,
  notListed:false,
 });
 const [checkedMyLeads,setCheckedMyLeads] = useState(false);
 const [checkedFinancial,setCheckedFinancial] = useState({
  financial: true,
  notFinancial: false,
 });

  const [loader, setloader] = useState({
    value4: false,
    dummy: false,
    events2: false,
    market: false,
    checked: false,
  });

  const [activetimline, setActivetimeline] = useState({
    ni:false,
    l: true,
    i: false,
    os: false,
    oa: false,
    ua: false,
    c: false,
  });
  const [statusBy, setStatusBy] = useState("LEAD");
  const [checked, setChecked] = useState({
    hot: true,
    warm: true,
    cold: true,
  });
  const [probability,setProbability] = useState(null);
  const role = userData.data.role;
  const [orgDetails, setOrgDetails] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
   const user = userData.data.user;
  const userid = String(user.id);
  const Org = user.organization;
  const subs = userData.data.subs[0];
  let subId = subs.id;
  // const Org = userData.data.organization;
  // const org = role === "Super Admin" && selectedOrg !== null ? selectedOrg?.agent_id : Org;
  // const [lorg, setLOrg] = useState(org); 
  const [offset, setOffset] = useState(0);
  const [filterClear, setFilterClear] = useState(false);
  const [toggleFilterClear, setToggleFilterClear] = useState(false);
  const { handleAddAction } = useAddAction(userToken);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedMsa, setSelectedMsa] = useState([]);
  const [msaRegions, setMSARegions] = useState([]);
  const [msaDetails, setMsaDetails] = useState([]);
  const [regionDetails, setRegionDetails] = useState([]);
  const subscription = subs.sub_data;

  const navigate = useNavigate();
  const op = useRef(null);

 
  useEffect(()=>{
    if(tokenExpired === true){
      redirect()
    }
    },[tokenExpired])
const redirect =()=>{
  navigate('/');
}
  const cnotesData = (t) =>{
    setCNotes(t)
  }
 const leadnotechange = (t) =>{
  setLeadNotesChangeToast(t)
  setTimeout(function () {
    setLeadNotesChangeToast(false)
  }, 8000);
 }
  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };
    // for session time
    useEffect(() => {
      const date = moment();
  
      return () => {
        const endDate = moment();
        const item = {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Intelligent Leads",
          "start_time": date,
          "end_time": endDate,
          "session": userToken,
        };
        axios
          .post(`${process.env.REACT_APP_USER_SESSION_TIMES}`, item, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((response) => {
            console.log(response.config.data, "post success");
          })
          .catch((err) => console.log(err));
      };
    }, []);
  // useEffect(()=>{
    // getmarket();
    // getOrg();    
    // setLOrg(org);
    // getOwnerList();
  // },[])
 
  // useEffect(()=>{
  //   setLOrg(org);
  //  },[selectedOrg])
   useEffect(() => {
    getUpdatedLeads();
  }, [CNotes,selectedOwner,checkedFinancial,checkedListed,probability,statusBy,offset,filterClear,checkedMyLeads,toggleFilterClear])
  //for timeline
  useEffect(() => {
    setSelectedOwner([]);
    setSearchData("");
    setSelectedRegion([]);
    setSelectedMsa([]);
    setPropUnits([]);
    setSelectedPropUnits([minUnit*1,maxUnit*1]);
    setSelectedState([]);
    setToggleFilterClear(true);
    setStartDate();
    setEndDate();
    checkActive();
    // setCheckedMyLeads(true);
  }, [statusBy]);
 
    useEffect(() => {
      getPropbability();
    }, [checked]);
    useEffect(()=>{
      getPropDetails();
      if(selectedOrg === null){
        setSelectedOrg({"agent_id" : Org});
      }
    },[])
  let statedata = StateData;

  //  const getOrg = async () =>{
  //      await axios.get(`${process.env.REACT_APP_ORGANIZATION_LIST}`,
  //      {
  //        headers: {
  //          Authorization: `Bearer ${userToken}`,
  //        },
  //      }
  //      ).then((data) => {
  //        let dd = data.data;
  //        let ds = dd.sort(function (a, b) {
  //          if (a.agent_id < b.agent_id) {
  //            return -1;
  //           }
  //           if (a.agent_id > b.agent_id) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //        setOrgDetails(ds);
  //        setTokenExpired(false);

  //    })
  //    .catch((err) =>{
  //     // let error = err.response.data.error;
  //     // if(error.message === "This token has expired"){
  //     //  setTokenExpired(true);
  //     //  sessionStorage.setItem('logged',false)

  //     // }
  //   });
  //  };
  //  const onOrgChange = (e) => {
  //    setSelectedOrg(e.value);
  //    setShowDropdown(!showDropdown);
  //    sessionStorage.setItem(
  //      "orgdata",
  //      JSON.stringify(e.value)
  //    );
 
  //  };
//    const getOwnerList = async () =>{
//     await axios.get(`${process.env.REACT_APP_LEADS_OWNER_NAME}`,
//     {
//       headers: {
//         Authorization: `Bearer ${userToken}`,
//       },
//     }
//     ).then((data) => {
//       let dd = data.data;
//       let aa= [];
//       dd.map(d => d.owner_name !== "" ? aa.push(d) : null)
//      setTokenExpired(false);

//   }) 
//   .catch((err) =>{
//     let error = err.response.data.error;
//     if(error.message === "This token has expired"){
//      setTokenExpired(true);
//      sessionStorage.setItem('logged',false)

//     }
//   });
  
// };
const getPropertyOwnerList = async (stext) =>{
if(searchSelected.property === true){
 await axios.get(`${process.env.REACT_APP_LEADS_SEARCHBY}?option=property&search=${searchText}`,
{
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
}
).then((data) => {
  let dd = data.data;
  let aa= [];
  dd.map(d => d.nedl_property_name !== "" ? aa.push(d) : null)
  if(aa.length > 0){
    setPropertyList(aa);
    setShowNoData(false);
  }else{
    setPropertyList([]);
    setShowNoData(true);
  }
  setTokenExpired(false);
})
.catch((err) =>{
  // let error = err.response.data.error;
  // if(error.message === "This token has expired"){
  //  setTokenExpired(true);
  //  sessionStorage.setItem('logged',false)

  // }
});
}
if(searchSelected.owner === true){
await axios.get(`${process.env.REACT_APP_LEADS_SEARCHBY}?option=owner&search=${stext}`,
{
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
}
).then((data) => {
  let dd = data.data;
  let aa= [];
  dd.map(d => d.owner_name !== "" ? aa.push(d) : null)
  if(aa.length > 0){
    setOwnerList(aa);
    setShowNoData(false);
  }else{
    setOwnerList([]);
    setShowNoData(true);
  }
setTokenExpired(false)
})
.catch((err) =>{
  // let error = err.response.data.error;
  // if(error.message === "This token has expired"){
  //  setTokenExpired(true);
  //  sessionStorage.setItem('logged',false)

  // }
});
}

};
//   const getmarket = async() => {
//     await axios
//       .get(`${process.env.REACT_APP__LEADSMARKET}`, {
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//         },
//       })
//       .then((response) => {
//         let i = response.data; 
//         let m = [];
//         i.map((d => {
//          return  m.push(d.state)}          
//           ))  
//           let n = [];
//           stateData.map((d => {
//             return n.push(d.value)
//           }))     
//         setLeadsMarket(stateData);
//         setMarketData(n);
//         setSelectedCity1(n)
//         setTokenExpired(false)

        
//         console.log('market data',n)
//       })
//       .catch((err) =>{
//         let error = err.response.data.error;
//         if(error.message === "This token has expired"){
//          setTokenExpired(true);
//          sessionStorage.setItem('logged',false)

//         }
//       });
//   };
  const getPropbability = () =>{
const c =
    checked.hot === true && checked.warm === true && checked.cold === true
      ? `Hot,Warm,Cold`
      : checked.hot === true &&
        checked.warm === true &&
        checked.cold === false
      ? `Hot,Warm`
      : checked.hot === true &&
        checked.warm === false &&
        checked.cold === true
      ? `Hot,Cold`
      : checked.hot === false &&
        checked.warm === true &&
        checked.cold === true
      ? `Warm,Cold`
      : checked.hot === true &&
        checked.warm === false &&
        checked.cold === false
      ? `Hot`
      : checked.hot === false &&
        checked.warm === true &&
        checked.cold === false
      ? `Warm`
      : checked.hot === false &&
        checked.warm === false &&
        checked.cold === true
      ? `Cold`
      : `Hot,Warm,Cold`;
      setProbability(c);
  };
  const previousclick = () => {
    window.scrollTo({ top: "150px", behavior: 'smooth' });
    return offset !==0 ? setOffset(offset-102):offset
  }
  const nextclick = () => {
    window.scrollTo({ top: "150px", behavior: 'smooth' });
    return  setOffset(offset+102)
  }
  const amount = [
    { name: "K", code: "K" },
    { name: "M", code: "M" },
    { name: "B", code: "B" },
  ];
  const interestedFSstatus = [
    { name: "Financial Not Sent", code: "FNS" },
  ];
  const interestedFNSstatus = [
    { name: "Financials Sent", code: "FS" },
  ];
  const listedstatus = [
    { name: "Off Market", code: "AOM" },
  ];
  const availableoffmarketstatus = [
    { name: "On Market", code: "L" },
  ];
  const status = [
    { name: "Lead".toUpperCase(), code: "L",statusname:"Lead" },
    { name: "Interested".toUpperCase(), code: "I", statusname:"Interested" },
    { name: "Proposal".toUpperCase(), code: "OS", statusname:"Proposal" },
    { name: "Listed".toUpperCase(), code: "OA", statusname:"Listed" },
    { name: "Under Agreement".toUpperCase(), code: "UA", statusname:"Under Agreement" },
    { name: "Closed".toUpperCase(), code: "C", statusname:"Closed" },
    { name: "Not Interested".toUpperCase(), code: "NI" , statusname:"Not Interested"},
  ];
  const nistatus = [
    { name: "Interested".toUpperCase(), code: "I", statusname:"Interested" }
  ];
  const onCityChange = async (e) => {
    if (e.target.value.code === "all") {
      checkActive();
    }
    let i = e.target.value;
    let s = i.map(d=> d)
    // setSelectedS(s.join());
    // setSelectedCity1(e.target.value);
    checkActive();
//  let addaction = {
//   "username": fname,
//   "actions": {
//     "actiontype": 'On Change',
//     "component":'Market Dropdown',
//     "page": 'Intelligent Leads',
//     "filterBy":e.target.value
//   }
// }
// handleAddAction(addaction);
  };
  const checkActive = async (val) => {
    setDataLoaded(false);
    if (val === "ni") {
      setActivetimeline({ni: true,l: false,i: false,os: false,oa: false,ua: false,c: false});
      setStatusBy("Not Interested".toUpperCase());
    } else if (val === "l") {
      setActivetimeline({ni: false,l: true,i: false,os: false,oa: false,ua: false,c: false});
      setStatusBy("Lead".toUpperCase());
    } else if (val === "i") {
      setActivetimeline({ni: false,l: false,i: true,os: false,oa: false,ua: false, c: false});
      setStatusBy("Interested".toUpperCase());
      setCheckedFinancial({financial: true,notFinancial: false,})
    } else if (val === "os") {
      setActivetimeline({ni: false,l: false,i: false,os: true,oa: false,ua: false,c: false,});
      setStatusBy("Proposal".toUpperCase());
    } else if (val === "oa") {
      setActivetimeline({ni: false,l: false,i: false,os: false,oa: true,ua: false,c: false,});
      setStatusBy("Listed".toUpperCase());
      setCheckedListed({listed:true,notListed:false})
    } else if (val === "ua") {
      setActivetimeline({ni: false,l: false,i: false,os: false,oa: false,ua: true,c: false,});
      setStatusBy("Under Agreement".toUpperCase());
    } else if (val === "c") {
      setActivetimeline({ ni: false,l: false,i: false,os: false,oa: false,ua: false, c: true,});
      setStatusBy("Closed".toUpperCase());
    }
  };
  const getUpdatedLeads = async () =>{
    checkActive();
    // setDataLoaded(false);      
    let remainingurl = '';
    if(searchSelected.property === true && filterValue.length > 0){
      let m = filterValue?.map(d => d.nedl_property_name);
          let formattedProperty = m.join();
          remainingurl = `${remainingurl}&property=${formattedProperty}`
    }
   if(searchSelected.owner === true && filterValue.length > 0){
      let m = filterValue?.map(d => d.owner_name);
          let formattedOwner = m.join();
          remainingurl = `${remainingurl}&owner=${formattedOwner}`
    }
   if(checkedMyLeads === true || checkedMyLeads === false){
       let myLeads = checkedMyLeads === true ? "yes" : "no"
      remainingurl = `${remainingurl}&mylist=${myLeads}`
    }
    if(statusBy === "INTERESTED" && checkedFinancial.financial === true){
        remainingurl = `&financial_sent=true${remainingurl}`
      }
      if(statusBy === "INTERESTED" && checkedFinancial.notFinancial === true){
        remainingurl = `&financial_notsent=true${remainingurl}`
      }
     if(statusBy === "LISTED" && checkedListed.listed === true){
        remainingurl = `&listed=true${remainingurl}`
      }
     if(statusBy === "LISTED" && checkedListed.notListed === true){
        remainingurl = `&available_off_market=true${remainingurl}`
      }
      // if (selectedMarket.length > 0) {
      //   let m = selectedMarket?.map(d => d.market);
      //   let formattedMarket = m.join();
      //   remainingurl = `${remainingurl}&market=${formattedMarket}`
      // }
      // if (selectedSubmarket.length > 0) {
      //   let c = selectedSubmarket?.map(d => d.submarket);
      //   let formattedSubmarket = c.join();
      //   remainingurl = `${remainingurl}&submarket=${formattedSubmarket}`
      // }
      // if (selectedState.length > 0) {
      //   let c = selectedState?.map(d => d.state);
      //   let formattedState = c.join();
      //   remainingurl = `${remainingurl}&state=${formattedState}`
      // }
      if(propUnits.length > 0){
        remainingurl = `${remainingurl}&punits=${propUnits[0]}&punite=${propUnits[1]}`
      }
      if(startDate !== undefined && endDate !== undefined){
        let formattedStartDate = (moment(startDate).format("yyyy"))*1;
        let formattedEndDate =(moment(endDate).format("yyyy"))*1;
        remainingurl = `${remainingurl}&yearbuilds=${formattedStartDate}&yearbuilde=${formattedEndDate}`
      }
      let c = '';
     if(statusBy === "LEAD"){
        c =
        checked.hot === true && checked.warm === true && checked.cold === true
          ? `Hot,Warm,Cold`
          : checked.hot === true &&
            checked.warm === true &&
            checked.cold === false
          ? `Hot,Warm`
          : checked.hot === true &&
            checked.warm === false &&
            checked.cold === true
          ? `Hot,Cold`
          : checked.hot === false &&
            checked.warm === true &&
            checked.cold === true
          ? `Warm,Cold`
          : checked.hot === true &&
            checked.warm === false &&
            checked.cold === false
          ? `Hot`
          : checked.hot === false &&
            checked.warm === true &&
            checked.cold === false
          ? `Warm`
          : checked.hot === false &&
            checked.warm === false &&
            checked.cold === true
          ? `Cold`
          : ``;
      }else if(statusBy !== "LEAD"){
        c = `Hot,Warm,Cold`
      }
     
    axios
   .get(
     `${process.env.REACT_APP__LEADSBYSTATUS}?status=${statusBy}&probability=${c}&org=${Org}&userid=${userid}&subs_id=${subId}${remainingurl}&offset=${offset}`,
     {
       headers: {
         Authorization: `Bearer ${userToken}`,
       },
     }
   )
   .then(async (data) => {
     if (data.status === 200) {
       let d = data.data;
       setFilteredLeadsData(d);
       setFilterClear(false);
       setToggleFilterClear(false);
       setDataLoaded(true); 
       setTokenExpired(false)       
     }
     else{
      setDataLoaded(false);      
    }
   })
   .catch((err) =>{
    // let error = err.response.data.error;
    // if(error.message === "This token has expired"){
    //  setTokenExpired(true);
    //  sessionStorage.setItem('logged',false)

    // }
  });
 }
//while selecting the status from dropdown
const handleStatus = (e, id) => {
  setTargetedStatus({ stat: e.target.value.name });
  setTargetedLead({ lead: id });
  setDisplayBasic(true);
  setPropertyID(id);
  setInternalDropdownSelected(false);
};
const handleInternalStatus = (e, id) => {
  setSelectedFinancialStatus({ name: e.target.value.name });
  setTargetedLead({ lead: id });
  setDisplayBasic(true);
  setInternalDropdownSelected(true);
  setPropertyID(id);
};
const handleListedInternalStatus = (e, id) => {
  setSelectedListedStatus({ name: e.target.value.name });
  setTargetedLead({ lead: id });
  setDisplayBasic(true);
  setInternalDropdownSelected(true);
  setPropertyID(id);
};
 //status accept funtion
 const acceptFunc = (leadData, statusValue,selectedFinancialStatus,selectedListedStatus) => {
   const status = statusValue.stat;
   setMovedStatus(status);
   const values = leadData.lead;   
   if(status === "CLOSED"){
    let i = [];
    i.push(dealValueAmount);
  //   if(selectedValue.name === 'K'){
  //     i.push(dealValueAmount * 1000);
  //   }
  //   else if(selectedValue.name === 'M'){
  //     i.push(dealValueAmount * 1000000);
  //   }
  //   else if(selectedValue.name === 'B')
  //  {
  //    i.push(dealValueAmount * 1000000000);
  //  }
   let d = i.join();
    if(d === "0" || d === null || d === undefined || d === '' )
    {
      setShowDealError(true)
      setTimeout(function () {
        setShowDealError(false)
      }, 5000);
    }else{
      let statuses = {
        property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
        status: status,
        deal_value: Number(i),
       };
    axios
    .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((data) => {
      getUpdatedLeads();
      setDisplayBasic(false);
      setDealValueAmount('');
      setShowLeadStatusChangeConfirmation(true);
      setTimeout(function () {
        setShowLeadStatusChangeConfirmation(false)
      }, 5000);
    });
  
    }
   
   
   }
   if(status === "INTERESTED"){
    if(FinancialDetail === "true")
    {
      let statuses = {
        property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
        status: status,
        financial_sent: true,
      };
     axios
          .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((data) => {
            getUpdatedLeads();
            setDisplayBasic(false);
            setShowLeadStatusChangeConfirmation(true);
            setFinancialDetail(null);
            setTimeout(function () {
              setShowLeadStatusChangeConfirmation(false)
            }, 5000);
            console.log("Successfull");
          });
     }
    if(FinancialDetail === "false"){
      let statuses = {
        property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
        status: status,
        financial_notsent: true,
      };
     axios
          .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((data) => {
            getUpdatedLeads();
            setDisplayBasic(false);
            setShowLeadStatusChangeConfirmation(true);
            setFinancialDetail(null);
            setTimeout(function () {
              setShowLeadStatusChangeConfirmation(false)
            }, 5000);
            console.log("Successfull");
          });    }
    if(FinancialDetail === null) {
      setShowInterestedError(true);
      setTimeout(function () {
        setShowInterestedError(false)
      }, 5000);
    }
    
   }
   if(status === "LISTED"){
    if(ListedDetail === "true")
    {
      let statuses = {
        property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
        status: status,
        listed: true,
      };
     axios
          .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((data) => {
            getUpdatedLeads();
            setDisplayBasic(false);
            setShowLeadStatusChangeConfirmation(true);
            setListedDetail(null);
            setTimeout(function () {
              setShowLeadStatusChangeConfirmation(false)
            }, 5000);
            console.log("Successfull");
          });
    }
    if(ListedDetail === "false"){
      let statuses = {
        property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
        status: status,
        available_off_market: true,
      };
     axios
          .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((data) => {
            getUpdatedLeads();
            setDisplayBasic(false);
            setShowLeadStatusChangeConfirmation(true);
            setListedDetail(null);
            setTimeout(function () {
              setShowLeadStatusChangeConfirmation(false)
            }, 5000);
            console.log("Successfull");
          });    }
    if(ListedDetail === null) {
      setShowListedError(true);
      setTimeout(function () {
        setShowListedError(false)
      }, 5000);
    }
   
   
   }
   if(status === "NOT INTERESTED"){
    if(addLeadNotes === ""){
      setLeadNotesToast(true);
      setTimeout(function () {
        setLeadNotesToast(false)
      }, 5000);
  
    }
  
    if(addLeadNotes !== ""){
      let notes ={
       "property_id": values*1,
  "userid": userid,
  "notes": addLeadNotes,
  "org": Org,
  "subs_id": subId

      }
  
       axios
        .post(`${process.env.REACT_APP_LEADS_NOTES}/`, notes,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddLeadNotes("");
          setDisplayBasic(false);
        }
         
        });
        let statuses = {
          property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
          status: status,
        };
       axios
            .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })
            .then((data) => {
              getUpdatedLeads();
              setDisplayBasic(false);
              setShowLeadStatusChangeConfirmation(true);
              setTimeout(function () {
                setShowLeadStatusChangeConfirmation(false)
              }, 5000);
              console.log("Successfull");
            });
      }
   }
   if(selectedFinancialStatus?.name === "Financials Sent"){
    let statuses = {
      property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
      status: "INTERESTED",
      financial_sent: true,
    };
   axios
        .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((data) => {
          getUpdatedLeads();
          setDisplayBasic(false);
          setSelectedFinancialStatus(null);
          // setShowLeadStatusChangeConfirmation(true);
          // setTimeout(function () {
          //   setShowLeadStatusChangeConfirmation(false)
          // }, 5000);
        });
        let addaction = {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Intelligent Leads",
          "widget": "Financial Status",
          "actions":{    
            "actiontype": 'On Change',
            "financial_sent":"true",
          }
        }
        handleAddAction(addaction);
   }
   if(selectedFinancialStatus?.name === "Financial Not Sent"){
    let statuses = {
      property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
      status: "INTERESTED",
      financial_notsent: true,
    };
   axios
        .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((data) => {
          getUpdatedLeads();
          setDisplayBasic(false);
          setSelectedFinancialStatus(null);
          // setShowLeadStatusChangeConfirmation(true);
          // setTimeout(function () {
          //   setShowLeadStatusChangeConfirmation(false)
          // }, 5000);
        });  
        let addaction = {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Intelligent Leads",
          "widget": "Financial Status",
          "actions":{    
            "actiontype": 'On Change',
            "financial_notsent":"true",
          }
        }
        handleAddAction(addaction);
   }
   if(selectedListedStatus?.name === "On Market"){
    let statuses = {
      property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
      status: "LISTED",
      listed: true,
    };
   axios
        .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((data) => {
          getUpdatedLeads();
          setDisplayBasic(false);
          setSelectedListedStatus(null);
          // setShowLeadStatusChangeConfirmation(true);
          // setTimeout(function () {
          //   setShowLeadStatusChangeConfirmation(false)
          // }, 5000);
        });
        let addaction = {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Intelligent Leads",
          "widget": "Listed Status",
          "actions":{    
            "actiontype": 'On Change',
            "listed":"true",
          }
        } 
        handleAddAction(addaction)       
   }
   if(selectedListedStatus?.name === "Off Market"){
    let statuses = {
      property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
      status: "LISTED",
      available_off_market: true,
    };
   axios
        .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((data) => {
          getUpdatedLeads();
          setDisplayBasic(false);
          setSelectedListedStatus(null);
          // setShowLeadStatusChangeConfirmation(true);
          // setTimeout(function () {
          //   setShowLeadStatusChangeConfirmation(false)
          // }, 5000);
        }); 
        let addaction = {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Intelligent Leads",
          "widget": "Listed Status",
          "actions":{    
            "actiontype": 'On Change',
            "available_off_market":"true",
          }
        }  
        handleAddAction(addaction);
   }
   if(status !== "CLOSED" && status !== "INTERESTED" && status !== "LISTED" && status !== "NOT INTERESTED" && selectedFinancialStatus?.name !== "Financials Sent"
    && selectedFinancialStatus?.name !== "Financial Not Sent" && selectedListedStatus?.name  !== "On Market" && selectedListedStatus?.name  !== "Off Market"){
    
     let statuses = {
      property_id: values*1,
        userid: userid,
        org:Org,
        subs_id:subId,
       status: status,
     };
    axios
         .post(`${process.env.REACT_APP_STATUS}/`, statuses, {
           headers: {
             Authorization: `Bearer ${userToken}`,
           },
         })
         .then((data) => {
          getUpdatedLeads();
           setDisplayBasic(false);
           setShowLeadStatusChangeConfirmation(true);
           setTimeout(function () {
             setShowLeadStatusChangeConfirmation(false)
           }, 5000);
           console.log("Successfull");
         });

   }
   let addaction = {
    "userid": userid,
    "org": Org,
    "subs_id": subId,
    "page": "Intelligent Leads",
    "widget": "Dropdown",
    "actions":{    
      "actiontype": 'ON Change',
      "filter by": status,
    }
  } 
  handleAddAction(addaction);
};
const cancelfunc = () =>{
  setFinancialDetail(null);
  setListedDetail(null);
  setAddLeadNotes("");
  setDealValueAmount('');
  setDisplayBasic(false);
}
const searchChangeToProperty = () =>{
  setSearchSelected({property: searchSelected.property === true ? true: !searchSelected.property,owner:false});
  setFilterValue([]);
  setOwnerList([]);
  setPropertyList([]);
  setShowNoData(false);
  setSearchText("");
}
const searchChangeToOwner = () =>{
setSearchSelected({owner: searchSelected.owner === true ? true: !searchSelected.owner,property:false});
setFilterValue([]);
setOwnerList([]);
setPropertyList([]);
setShowNoData(false);
setSearchText("");
}
  //dialig box while changing the status
  const statusChangeDialogue = () => {
    return <>
      <Dialog className="statusChangeDialog" header="Confirmation" visible={displayBasic} draggable={false} onHide={() => setDisplayBasic(false)}>
        {/* <InputTextarea placeholder="Add Note" rows={2} cols={30} value={editorState} onChange={(e) => setEditorState(e.target.value)} /><br /> */}
        {targetedStatus.stat === "CLOSED" && 
        <>
        <p className="statuschangestyle">Enter the Deal Value</p>
        <div className="enterdealval">
          <label className="statuschangestyle">USD</label>
          <InputText keyfilter="int" placeholder="Deal Value" value={dealValueAmount} onChange={(e) => setDealValueAmount(e.target.value)} required/>
          {/* <Dropdown
                  value={selectedValue}
                  options={amount}
                  onChange={(e)=> setSelectedValue(e.value)}
                  optionLabel="name"
                  dropdownIcon="pi pi-sort-down"
                  placeholder="K"
                /> */}
               

        </div>
        {showdealerror ?
                <>
                <p className="error">Deal value cannot be empty</p>
                </>
                : null}
        </>}
        {targetedStatus.stat === "INTERESTED" && statusBy !== "INTERESTED" && InternalDropdownSelected === false &&
        <>
        <div className="alignnotes">
        <p className="statuschangestyle">Select Financial Status</p>
        <div className="statuscheckbox">
                              <div className="statuscheckboxbtn">
                              <RadioButton inputId="FS" name="Financials Sent" value="true" onChange={(e) => setFinancialDetail(e.value)} checked={FinancialDetail === 'true'} />
                             <label htmlFor="FS" className="ml-2">Financials Sent</label>
                            </div>
                            <div className="statuscheckboxbtn">
                              <RadioButton inputId="FNS" name="Financial Not Sent" value="false" onChange={(e) => setFinancialDetail(e.value)} checked={FinancialDetail === 'false'} />
                             <label htmlFor="FNS" className="ml-2">Financial Not Sent</label>
                            </div>
                            </div>
                            </div>
                            {showInterestedError ?
                <>
                <p className="error">Financial status cannot be empty</p>
                </>
                : null}
        </>}
        {targetedStatus.stat === "LISTED" && statusBy !== "LISTED" && InternalDropdownSelected === false &&
        <>
        <div className="alignnotes">
        <p className="statuschangestyle">Select Listed Status</p>
        <div className="statuscheckbox">
                              <div className="statuscheckboxbtn">
                              <RadioButton inputId="L" name="Listed" value="true" onChange={(e) => setListedDetail(e.value)} checked={ListedDetail === 'true'} />
                             <label htmlFor="L" className="ml-2">On Market</label>
                            </div>
                            <div className="statuscheckboxbtn">
                              <RadioButton inputId="AOM" name="Available off Market" value="false" onChange={(e) => setListedDetail(e.value)} checked={ListedDetail === 'false'} />
                             <label htmlFor="AOM" className="ml-2">Off Market</label>
                            </div>
                            </div>
                            </div>
                            {showListedError ?
                <>
                <p className="error">Listed status cannot be empty</p>
                </>
                : null}
        </>}
        {targetedStatus.stat === "NOT INTERESTED" && 
        <>
    <div className="alignnotes">
        <p className="addchangestyle">Add Notes</p>
        <div className="enternotes">
        <InputText required value={addLeadNotes} onChange={(e) => setAddLeadNotes(e.target.value)} /><br />
          </div>
        </div>
        {leadsnotesToast ?
                <>
                <p className="error">Notes cannot be empty</p>
                </>
                : null}
        </>}
        <div className="dealfooter">
        <Button className="dealbtn" onClick={() => acceptFunc(targetedLead, targetedStatus,selectedFinancialStatus,selectedListedStatus)}>Save</Button>
        <Button className="dealbtn" onClick={cancelfunc}>Cancel</Button>
        </div>
      </Dialog>
    </>
  }

const clearSearch = async() =>{
  setFilterValue([]);
  setOwnerList([]);
  setPropertyList([]);
  setShowNoData(false);
  setSearchText("");
  setFilterClear(true);
   let addaction = {
      "userid": userid,
      "org": Org,
      "subs_id": subId,
      "page": "Intelligent Leads",
      "widget": "Clear Icon for Search",
      "actions": {
      "actiontype": 'clicked'
    }
  }
  handleAddAction(addaction);
}
const searchPropOwner = async(stext) =>{
   getPropertyOwnerList(stext);
}
// const searchItems = (searchValue) => {
//   setSearchInput(searchValue)
//   if (searchInput !== "") {
//     const filteredData = filteredLeadsData.filter((item) => {
//         return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
//     })
//     setFilteredResults(filteredData)
// }
// else{
//     setFilteredResults(filteredLeadsData)
// }
// }
// const panelHeaderTemplate = (options) => {
//   return (
//     <div className={options.className}>
//       <div className="allstyle">{options.checkboxElement} All</div>

//       <div>{options.closeElement}</div>
//     </div>
//   );
// };
const searchFunction = (e) =>{
  setSearchText(e.target.value);
  searchPropOwner(e.target.value);
};
const SearchPanelHeaderTemplate = (options) => {

  return (
    <div className={options.className}>
      <div>
       <InputText
                  value={searchText}
                  onChange={(e) =>   searchFunction(e)}
                  autoFocus
                  />
                  <i onClick={(e) => setSearchText("")} style={{position:"absolute",fontSize:"0.7rem",padding:"0 5px",cursor:"pointer",top:"22px",right:"50px"}} className="pi pi-times"></i>
                  </div>
      {/* <i className="pi pi-search" style={{fontSize:"0.7rem",padding:"0 5px",cursor:"pointer"}} onClick={searchPropOwner}/> */}
      {/* <i className="pi pi-times" onClick={(e) => setSearchText("")} style={{fontSize:"0.7rem"}}></i> */}
      {/* <div style={{fontSize:"0.7rem"}}>{options.closeElement}</div> */}
    </div>
  );
};
const SearchPanelFooterTemplate = () => {
  if(showNoData === true){
   return ( 
    <div className="nodatastyle">
      No Match found on the Leads
    </div>
   );
  }

};
const SearchOwnerPanelFooterTemplate = () => {
  if(showNoData === true){
   return ( 
    <div className="nodatastyle">
      No Match found on the Leads
    </div>
   );
  }

};
const placeholderTemplate = (options) => {
  return (
    <div className="leadsarrowcontainer">
      <div style={{textTransform:"capitalize"}}>{options.toLowerCase()}</div>
      <div className="arrow"></div>
    </div>
  );
};
const financialplaceholderTemplate = (options) => {
  return (
    <div className="leadsarrowcontainer">
      <div>{options}</div>
      <div className="arrow"></div>
    </div>
  );
};
const listedplaceholderTemplate = (options) => {
  return (
    <div className="leadsarrowcontainer">
      <div >{options}</div>
      <div className="arrow"></div>
    </div>
  );
};

const getSelectedOwner = (val) =>{
  setSelectedOwner(val);
//  let addaction = {
//   "username": fname,
//   "actions": {
//     "actiontype": 'On Change',
//     "component":'Owner Dropdown',
//     "page": 'Intelligent Leads',
//     "filterBy":val
//   }

// }
// handleAddAction(addaction);
}
const getPropDetails = async () =>{
  await axios
  .get(`${process.env.REACT_APP_LEADS_MIN_MAX}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  )
  .then((data) => {
    let i = data.data;
    let minu = i[0].minunit !== null ? i[0].minunit*1 : 0 ;
    let maxu = i[0].maxunit  !== null ? i[0].maxunit*1 : 0 ;
    let miny = i[0].minyear !== null ? i[0].minyear*1 : 0 ;
    let maxy = i[0].maxyear !== null ? i[0].maxyear*1 : 0 ;
    const mindate = new Date(miny, 0, 1);
    const maxdate = new Date(maxy, 0, 1);
    setSelectedPropUnits([minu,maxu]);
    setMinUnit(minu);
    setMinYear(mindate);
    setMaxUnit(maxu);
    setMaxYear(maxdate);
    setTokenExpired(false)
  })
  .catch((err) =>{
    // let error = err.response.data.error;
    // if(error.message === "This token has expired"){
    //  setTokenExpired(true);
    //  sessionStorage.setItem('logged',false)
    // }
  });

  };
const handleFilterApply = () => {
  getUpdatedLeads()
  setVisible(false);
}
const handleFilterCancel =async () => {
  if(startDate !== undefined && endDate !== undefined || propUnits.length > 0){
    setSelectedRegion([]);
    setSelectedMsa([]);
    setPropUnits([]);
    setSelectedPropUnits([minUnit*1,maxUnit*1]);
    setSelectedState([]);
    setToggleFilterClear(true);
    setStartDate();
    setEndDate();
  }
  setVisible(false);
  let addaction = {
    "userid": userid,
    "org": Org,
    "subs_id": subId,
    "page": "Intelligent Leads",
    "widget": "Clear Icon for Filter",
    "actions": {
      "actiontype": 'clicked',
    }
  }
  handleAddAction(addaction);
}
const handleStartDateChange = (data) => {
  let formattedStartDate = (moment(data).format("yyyy"))*1;
  let formattedEndDate =(moment(endDate).format("yyyy"))*1;
  setStartDate(data);
  if(formattedStartDate > formattedEndDate){
    setEndDate(data);
  }
  if(endDate === undefined){
    setEndDate(maxYear);
  }
handleMinClose();
};
const handleEndDateChange = (data) => {
  let formattedStartDate = (moment(startDate).format("yyyy"))*1;
  let formattedEndDate =(moment(data).format("yyyy"))*1;
  setEndDate(data);
  if(formattedStartDate > formattedEndDate){
    setStartDate(data);
  }
  if(startDate === undefined){
    setStartDate(data);
  }
  handleMaxClose();
};
const classes = useStyles();
const onMinUnitChange = (val) =>{
  if(val !== null){
    setSelectedPropUnits([val,selectedPropUnits[1]]);
    setPropUnits([val,selectedPropUnits[1]]);
  }else{
    setSelectedPropUnits([minUnit*1,selectedPropUnits[1]]);
    setPropUnits([minUnit*1,selectedPropUnits[1]]);
  }
}
const onMaxUnitChange = (val) =>{
  if(val !== null){
    setSelectedPropUnits([selectedPropUnits[0],val]);
    setPropUnits([selectedPropUnits[0],val]);
  }else{
    setSelectedPropUnits([selectedPropUnits[0],maxUnit]);
    setPropUnits([selectedPropUnits[0],maxUnit]);
  }
}
const [minopen, setMinOpen] = useState(false);
const [maxopen, setMaxOpen] = useState(false);

 const handleIconMaxClick = () => {
  setMaxOpen(true);
};

const handleMaxClose = () => {
  setMaxOpen(false);
};
const handleIconMinClick = () => {
  setMinOpen(true);
};

const handleMinClose = () => {
  setMinOpen(false);
};
// const selectedRegionChange = () =>{
//   setSelectedRegion([]);
//   setSelectedMsa([]);
//   setSelectedState([]);
//   }
//   const selectedMsaChange = () =>{
//     setSelectedMsa([]);
//   setSelectedState([]);
//   }
//   const selectedStateChange = () =>{
//   setSelectedState([]);
//   }
// const createPanelHeaderTemplate = (clearFunction) => (options) => {
//   const handleClick = () => {
//     clearFunction();
//   };
//   return (
//     <div className={options.className}>
//       <div style={{width:"100%"}}>{options.filterElement}</div>
//       <i onClick={handleClick} className="pi pi-filter-slash clrfilter"></i>
//       <div>{options.closeElement}</div>
//     </div>
//   );
// };
// const onpropertyregionchange = (e) =>{
//   e.preventDefault();
//   setSelectedRegion(e.value)
//   setSelectedMsa([]);
//   setSelectedState([]);
// }
// const onpropertymsachange = (e) =>{
//   e.preventDefault();
//   setSelectedMsa(e.value)
//   setSelectedState([]);
// }
// const onpropertystatechange = (e) =>{
//   e.preventDefault();
//   setSelectedState(e.value)
// }
// useEffect(() => {
//   selectedRegionData();
// }, [selectedRegion]);
// useEffect(() => {
//   selectedMsaData();
// }, [selectedMsa]);
// useEffect(() => {
//   selectedStateData();
// }, [selectedState]); 
// const getMSARegions = async (i,type) =>{
//   await axios.get(`${process.env.REACT_APP_MSA_REGIONS}`,
//     {
//              headers: {
//                Authorization: `Bearer ${userToken}`,
//              },
//            }
//   )
//   .then((data) => {
//     let dd = data.data;
//     if(data.status === 200){
//       if(i=== undefined){
//       getData(dd);
//       }
//     }
//     setMSARegions(dd);
  
// })
// }
// const getData = (i) =>{
//   const [key, value] = Object.entries(subscription)[0];
//   const entries = Object.entries(subscription);

//   let rd = [];
//   if(entries.length === 1){
//   if(key === "ALL"){
//    i.map(a =>  rd.push({msa_name : a.msa_name, msa_code: a.msa_code, region : a.region , state : a.state}));
//    let uniqueRegionName = [...new Map(i.map(regionData => [regionData.region, regionData])).values()];
//    let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
//    setRegionDetails(sortedRegionName);
//    setMsaDetails(rd);
//   }
//   if(key === "MSA"){
//     setRegionDetails([]);
//     const msaList = value.reduce((acc, item) => {
//       const matches = i.filter(obj => obj.msa_code === item);
//       return acc.concat(matches);
//     }, []);
//     setMsaDetails(msaList);
//     rd = msaList;
//     console.log(msaList,"msalist")
//     let uniqueRegion = [...new Map(msaList.map(rData => [rData.region, rData])).values()];
//     let sortedRegionName = uniqueRegion.sort((a, b) => a.region.localeCompare(b.region));
//     setRegionDetails(sortedRegionName);
//     }
// }
//   if (entries.length > 1) {
//     const [keynew, valuenew] = Object.entries(subscription)[1];
//     if(key === "MSA" && keynew === "region"){
//       const regionList = valuenew.reduce((acc, item) => {
//         const matches = i.filter(obj => obj.region === item);
//         return acc.concat(matches);
//       }, []);
//       const msaList = value.reduce((acc, item) => {
//         const matches = i.filter(obj => obj.msa_code === item);
//         return acc.concat(matches);
//       }, []);
//       setMsaDetails(msaList);
//       rd = msaList;
//       let uniqueRegionName = [...new Map(regionList.map(regionData => [regionData.region, regionData])).values()];
//       let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
//       setRegionDetails(sortedRegionName);
//     }
//     }
 
//   let uniqueState = [...new Map(rd.map(sData => [sData.state, sData])).values()];
//   let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
//   let stateAbb = sortedState.map(d => d.state);
//  let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
//   setState(FinalStateData)
// }
// const getOnChangeData = () =>{
// const [key, value] = Object.entries(subscription)[0];
// const entries = Object.entries(subscription);

// let rd = [];
// if(entries.length === 1){
// if(key === "ALL"){
//  msaRegions.map(a =>  rd.push({msa_name : a.msa_name, msa_code: a.msa_code, region : a.region , state : a.state}));
//  let uniqueRegionName = [...new Map(msaRegions.map(regionData => [regionData.region, regionData])).values()];
//  let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
//  setRegionDetails(sortedRegionName);
//  setMsaDetails(rd);
// }
// if(key === "MSA"){
//   setRegionDetails([]);
//   const msaList = value.reduce((acc, item) => {
//     const matches = msaRegions.filter(obj => obj.msa_code === item);
//     return acc.concat(matches);
//   }, []);
//   setMsaDetails(msaList);
//   rd = msaList;
//   }
// }
// if (entries.length > 1) {
//   const [keynew, valuenew] = Object.entries(subscription)[1];
// if(key === "MSA" && keynew === "region"){
//   const regionList = valuenew.reduce((acc, item) => {
//     const matches = msaRegions.filter(obj => obj.region === item);
//     return acc.concat(matches);
//   }, []);
//   const msaList = value.reduce((acc, item) => {
//     const matches = msaRegions.filter(obj => obj.msa_code === item);
//     return acc.concat(matches);
//   }, []);
//   rd = msaList;
//   let uniqueRegionName = [...new Map(regionList.map(regionData => [regionData.region, regionData])).values()];
//   let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
//   setRegionDetails(sortedRegionName);
//   setMsaDetails(msaList);
// }
// }

// if ((selectedMsa.length === 0 || selectedState.length === 0) && selectedRegion.length > 0) {
// selectedRegionData();
// }
// if(selectedMsa.length ===  0 && selectedRegion.length === 0){
// let uniqueState = [...new Map(rd.map(sData => [sData.state, sData])).values()];
//   let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
//   let stateAbb = sortedState.map(d => d.state);
//  let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
//  setState(FinalStateData);
// }
// if(selectedState.length === 0 && selectedRegion.length === 0 && selectedMsa.length > 0 ||selectedState.length === 0 && selectedRegion.length > 0 && selectedMsa.length > 0)
// {
// selectedMsaData();
// }
// }
// const selectedRegionData = () => {
// if(selectedRegion.length > 0){ 
//   const allMatches = selectedRegion.reduce((acc, m) => {
//     const matches = msaRegions.filter((item) => item.region === m.region);
//     return acc.concat(matches);
//   }, []);
//   let uniqueMSA = [...new Map(allMatches.map(mData => [mData.msa_name, mData])).values()];
//   let sortedMSA = uniqueMSA.sort((a, b) => a.msa_name.localeCompare(b.msa_name));
//   let uniqueState = [...new Map(allMatches.map(sData => [sData.state, sData])).values()];
//   let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
//   let stateAbb = sortedState.map(d => d.state);
//  let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
//  setState(FinalStateData);
//   setMsaDetails(sortedMSA); 
// }
// else{
// getMSARegions();
// }

// };
// const selectedMsaData = () => {
// if(selectedMsa.length > 0){ 
//   const allMatches = selectedMsa.reduce((acc, m) => {
//     const matches = msaRegions.filter((item) => item.msa_name === m.msa_name);
//     return acc.concat(matches);
//   }, []);
//     let uniqueState = [...new Map(allMatches.map(sData => [sData.state, sData])).values()];
//     let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
//     let stateAbb = sortedState.map(d => d.state);
//    let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
//     setState(FinalStateData);   
// }
// else{
// getOnChangeData();
// }
// };
// const selectedStateData = () => {
// if(selectedState.length > 0){ 
//   const allMatches = selectedState.reduce((acc, m) => {
//     const matches = msaRegions.filter((item) => item.state === m.name);
//     return acc.concat(matches);
//   }, []);  
// }
// else{
// getOnChangeData();
// }
// };
 
  return (
    <>
   <div className="pos">
    <div className="timelinet ">
    {loader.events2 !== null ? (
     <>
      <ul className="timeline">
        <li className={activetimline.ni ? "active-tl" : "nitimeline"} 
        onClick={() => checkActive("ni")}>Not Interested</li>
        <li className={activetimline.l ? "active-tl" : ""}
        onClick={() => checkActive("l")}>Lead</li>
        <li className={activetimline.i ? "active-tl" : ""}
        onClick={() => checkActive("i")}>Interested</li>
        <li className={activetimline.os ? "active-tl" : ""}
        onClick={() => checkActive("os")}>Proposal</li>
        <li className={activetimline.oa ? "active-tl" : ""}
        onClick={() => checkActive("oa")}>Listed</li>
        <li className={activetimline.ua ? "active-tl" : ""}
        onClick={() => checkActive("ua")}>Under Agreement</li>
        <li className={activetimline.c ? "active-tl" : ""}
        onClick={() => checkActive("c")}>Closed</li>
      </ul>
    </>
     ) : (
       <Skeleton height="6rem"></Skeleton>
          )}
        </div>
        <div className="flex100 pading10 fixed">
          <div className="fifty">
            {loader.checked !== null ? (
              <>
               <div className="advfilterimgdiv" onClick={() => setVisible(true)} ><span className="advfiltertooltiptext">Advanced Filter</span><i className="pi pi-angle-double-left obimg"></i></div>
                {statusBy === "LEAD" ?
                <>
                <div className="field-checkbox marginlr ">
                  <Checkbox
                    inputId="binary"
                    checked={checked.hot}
                    onChange={(e) =>
                      setChecked({
                        hot: !checked.hot,
                        warm: checked.warm,
                        cold: checked.cold,
                      })
                    }
                  />
                  <label className="marginl" htmlFor="binary">Hot</label>
                </div>
                <div className="field-checkbox marginlr  ">
                  <Checkbox
                    inputId="binary"
                    checked={checked.warm}
                    onChange={(e) =>
                      setChecked({
                        warm: !checked.warm,
                        hot: checked.hot,
                        cold: checked.cold,
                      })
                    }
                  />
                  <label className="marginl" htmlFor="binary">Warm</label>
                </div>
                <div className="field-checkbox marginlr ">
                  <Checkbox
                    inputId="binary"
                    checked={checked.cold}
                    onChange={(e) =>
                      setChecked({
                        cold: !checked.cold,
                        hot: checked.hot,
                        warm: checked.warm,
                      })
                    }
                  />
                  <label className="marginl" htmlFor="binary">Cold</label>
                </div>
                </> 
                : null}
                  {statusBy === "INTERESTED" ? 
             <>
             <div className="field-checkbox marginlr ">
               <Checkbox
                 inputId="binary"
                 checked={checkedFinancial.financial}
                 onChange={(e) => setCheckedFinancial({financial: checkedFinancial.financial === true ? true: !checkedFinancial.financial,
                   notFinancial:false})}
               />
               <label className="marginl" htmlFor="binary">Financials Sent</label>
             </div>
             <div className="field-checkbox marginlr ">
               <Checkbox
                 inputId="binary"
                 checked={checkedFinancial.notFinancial}
                 onChange={(e) => setCheckedFinancial({
                   financial:false,
                   notFinancial: checkedFinancial.notFinancial === true ? true :!checkedFinancial.notFinancial})}
               />
               <label className="marginl" htmlFor="binary">Financial Not Sent</label>
             </div>
             </>
                :null}
                {statusBy === "LISTED" ? 
                <>
                <div className="field-checkbox marginlr ">
                  <Checkbox
                    inputId="binary"
                    checked={checkedListed.listed}
                    onChange={(e) => setCheckedListed({listed: checkedListed.listed === true ? true: !checkedListed.listed,
                      notListed:false})}
                  />
                  <label className="marginl" htmlFor="binary">On Market</label>
                </div>
                <div className="field-checkbox marginlr ">
                  <Checkbox
                    inputId="binary"
                    checked={checkedListed.notListed}
                    onChange={(e) => setCheckedListed({
                      listed:false,
                      notListed: checkedListed.notListed === true ? true :!checkedListed.notListed})}
                  />
                  <label className="marginl" htmlFor="binary">Off Market</label>
                </div>
                </>
                :null}
                 <div className="field-checkbox marginlr ">
                  <Checkbox
                    inputId="myleads"
                    checked={checkedMyLeads}
                    onChange={(e) => {setCheckedMyLeads(!checkedMyLeads);setDataLoaded(false)}}
                  />
                  <label className="marginl" htmlFor="myleads">My Leads</label>
                </div> 
              </>
            ) : (
              <>
                <div className="titleloader">
                  <Skeleton width="24rem" height="2rem"></Skeleton>
                </div>
              </>
            )}
          </div>
        
          <div className="fiftysearch justify-right">
            <div className="filterradiobtncontainer">
          <div className="filterradiobtn">
                              <RadioButton inputId="PN" name="Property Name" 
                              onChange={(e) => searchChangeToProperty()} checked={searchSelected.property} />
                             <label htmlFor="PN" className="ml-2">Property Name</label>
                            </div>
                            <div className="filterradiobtn">
                              <RadioButton inputId="ON" name="Owner Name"
                              onChange={(e) => searchChangeToOwner()} checked={searchSelected.owner} />
                             <label htmlFor="ON" className="ml-2">Owner Name</label>
                            </div>
                            </div>
                            <div className="propownerfilter">
                            {searchSelected.owner === true ? 
          <MultiSelect
          value={filterValue}
          options={ownerList}
          onChange={(e) => setFilterValue(e.value)}
          optionLabel="owner_name"
          display="chip"
          filter
          className="leadsmultiselect"
          panelHeaderTemplate={SearchPanelHeaderTemplate}
          placeholder="Search"
          selectionLimit={5}
          maxSelectedLabels={1}
          panelFooterTemplate={SearchOwnerPanelFooterTemplate}
                />
                :
                <MultiSelect
                value={filterValue}
                options={propertyList}
                onChange={(e) => setFilterValue(e.value)}
                optionLabel="nedl_property_name"
                className="leadsmultiselect"
                display="chip"
                filter
                panelHeaderTemplate={SearchPanelHeaderTemplate}
                placeholder="Search"
                selectionLimit={5}
                maxSelectedLabels={1}
                panelFooterTemplate={SearchPanelFooterTemplate}
              />
              }
          {/* <span className="p-input-icon-right"> */}
          {/* <i className="pi pi-search" /> */}
          {/* <Chips value={filterValue} placeholder="Search By" onChange={(e) => setFilterValue(e.value)} separator="," /> */}
          {/* </span> */}
          <div className="iconfilter">
           <i className="pi pi-times" onClick={clearSearch} style={{fontSize:"0.7rem",cursor:"pointer",position:"absolute",marginTop:"1px",right:"50px"}}/>
          <i className="pi pi-filter" onClick={getUpdatedLeads} style={{fontSize:"0.7rem",cursor:"pointer",color:"white"}} />
          </div>
          </div>
          </div>
        </div>
        </div>
        <div className="leadsProperty flex100 flexwrapleads">
        {showLeadStatusChangeConfirmation === true ?
        <ToastComponent severity="success" detail={`Moved to ${movedStatus}`} />:null}
         {statusBy === 'LEAD' && checkedMyLeads === false && leadnotesChangeToast === true && dataLoaded === true? 
           <ToastComponent severity="success" detail={`Moved to My Leads`} />:null}
          {typeof filteredLeadsData !== "string"? (
             filteredLeadsData?.length !== 0 && dataLoaded === true ? (
             <> { 
                 filteredLeadsData && filteredLeadsData?.map((d, v) => {
                  let h = "/images/hot-new.svg";
                  let c = "/images/cold-new.svg";
                  let w = "/images/warm-new.svg";
                  return (
                  <>
                  <LeadsCards
            // search ={searchData}
            onChildChange={cnotesData}
            onChildLeadNoteChange={leadnotechange}
            org={Org}
            subs_id={subId}
            userid={userid}
            id={d?.nedl_property_id_pk}
            fdata={filteredLeadsData}
            progress={d.notes_count}
            checkedMyLeads={checkedMyLeads}
            checkdataLoaded={dataLoaded}
            status={statusBy}
            statusDate={d.insert_date}
            // statusDate={d.statusdate}
            key={v.nedl_property_id_pk}
            date={d?.inserted_on}
            // leadsLatestUpdatedDate={d.insert_date}
            header={d?.nedl_property_name}
            licon="\images\Location.svg"
            address={`${d?.city}, ${d?.state}, ${d?.zip}`}
            state={d.state}
            lastSale={moment(d.last_sale_date).format("MM/DD/YYYY")}
            ownerName={d.owner_name}
            img={d.lead_type === "Hot" ? h : d.lead_type === "Warm" ? w : c}
            viewDetails="View Details"
            leadNotes="Notes"
            dropdown={
              <Dropdown
                key={v.nedl_property_id_pk}
                className="leads-dropdown"
                id={d?.nedl_property_id_pk}
                options={status.slice(status.findIndex((item) => item.name.toUpperCase() === statusBy) + 1)}
                onChange={(e) => {handleStatus(e, d?.nedl_property_id_pk)}}
                // onChange={(e) => onStatusChange(e,d?.nedl_property_id_pk)}
                optionLabel="statusname"
                placeholder={placeholderTemplate(statusBy)}
              />
            }
            nidropdown={
              <Dropdown
                key={v.nedl_property_id_pk}
                className="leads-dropdown"
                id={d?.nedl_property_id_pk}
                options={nistatus}
                onChange={(e) => {handleStatus(e, d?.nedl_property_id_pk)}}
                // onChange={(e) => onStatusChange(e,d?.nedl_property_id_pk)}
                optionLabel="statusname"
                placeholder={placeholderTemplate(statusBy)}
              />
            }
            internaldropdown={
              <Dropdown
                key={v.nedl_property_id_pk}
                className="leads-dropdown"
                id={d?.nedl_property_id_pk}
                options={checkedFinancial.financial === true ? interestedFSstatus : interestedFNSstatus}
                onChange={(e) => {handleInternalStatus(e, d?.nedl_property_id_pk)}}
                // onChange={(e) => onStatusChange(e,d?.nedl_property_id_pk)}
                optionLabel="name"
                placeholder={financialplaceholderTemplate(checkedFinancial.financial === true ? "Financials Sent" : "Financial Not Sent")}
              />
            }
            listedinternaldropdown={
              <Dropdown
                key={v.nedl_property_id_pk}
                className="leads-dropdown"
                id={d?.nedl_property_id_pk}
                options={checkedListed.listed === true ? listedstatus : availableoffmarketstatus}
                onChange={(e) => {handleListedInternalStatus(e, d?.nedl_property_id_pk)}}
                // onChange={(e) => onStatusChange(e,d?.nedl_property_id_pk)}
                optionLabel="name"
                placeholder={listedplaceholderTemplate(checkedListed.listed === true ? "On Market" : "Off Market")}
              />
            }
            rbbutton="See Recommended Buyers"
          /> 
     </>
        );
      }) 
      } 
      {statusChangeDialogue()}   
  </>
) : (
  <><Loader /></>
  )
) : (
  'No data found'
 )}   
        </div>   
      <div className="paginationLead">
      <Button onClick={previousclick} disabled={offset>= 102 ? false : true}>Previous</Button><Button onClick={nextclick} disabled={filteredLeadsData.length > 101 ? false : true}>Next</Button>
     </div>     
      <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className="leads-sidebar" >
                <label style={{color:'#ED7D31',fontFamily:"Calibri",fontWeight:"600",fontSize:"13px"}} >Advanced Filter</label>
                <div className="overlayfilter" style={{height:"100%"}}>
                  {startDate !== undefined && endDate !== undefined ?
                  <div className="overlaydropdownContent advfilterselected" style={{maxHeight:"10%",overflowY:"auto"}}>
                   {/* {`Start Date : ${(moment(startDate).format("yyyy"))}  End Date : ${(moment(endDate).format("yyyy"))}`} */}
                   <span>{`Start Date: ${(moment(startDate).format("yyyy"))}`}</span><span>{`End Date: ${(moment(endDate).format("yyyy"))}`}</span>
                   </div>
                 : null}
                {propUnits?.length > 0 ?
                 <div className="overlaydropdownContent advfilterselected" style={{maxHeight:"10%",overflowY:"auto"}}>
                  <span>{`Min Property Units: ${propUnits[0]}`}</span><span>{`Max Property Units: ${propUnits[1]}`}</span>
                  {/* {`Min Property Units: ${propUnits[0]}    Max Property Units: ${propUnits[1]}`} */}
                  </div>
                : null}
      
      
                  {/* // {selectedMarket.length > 0 ?
                  // <div className="overlaydropdownContent" style={{maxHeight:"10%",overflowY:"auto"}}>
                  //   <Chips value={selectedMarket?.map(d => d.market)} onChange={(e) => handleSelectedMarketChange(e.value)} separator="," />
                  // </div>
                  // :null} */}
                            {/* <div className="overlaydropdownContent">
                              <label className="labelstyle">Region</label>
                              <MultiSelect
                                value={selectedRegion}
                                options={regionDetails}
                                onChange={onpropertyregionchange}
                                optionLabel="region"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(selectedRegionChange)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                maxSelectedLabels={1}
                                selectionLimit={10}
                              />
                            </div>
                            <div className="overlaydropdownContent">
                              <label className="labelstyle">MSA</label>
                              <MultiSelect
                                value={selectedMsa}
                                options={msaDetails}
                                onChange={onpropertymsachange}
                                optionLabel="msa_name"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(selectedMsaChange)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                maxSelectedLabels={1}
                                selectionLimit={10}
                              />
                            </div>
                            <div className="overlaydropdownContent">
                              <label className="labelstyle">State</label>
                              <MultiSelect
                                value={selectedState}
                                options={state.sort((a, b) =>
                                  a.name.localeCompare(b.name))}
                                onChange={onpropertystatechange}
                                optionLabel="name"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(selectedStateChange)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                selectionLimit={10}
                              />
                            </div> */}
                            <div className="overlaydropdownContent">
                              <label className="labelstyle">Year Built</label>
                              <div className="filterCalenderBox overlayfiltercalendar">
                                <div className="overlaydatestyle">
                                <label className="labelstyle">Min</label>
                                <div className="overlaydatediv">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                variant="inline"
                openTo="year"
                views={["year"]}
                value={startDate || null} 
                minDate={minYear}
                maxDate={maxYear}
                minDateMessage={false}
                maxDateMessage={false}
                format="yyyy"
                disableFuture
                onChange={(e) =>  handleStartDateChange(e._d)
                }
                InputProps={{ classes }}
                placeholder="Start Date"
                renderinput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Start Date"
                    fullWidth 
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: params.InputProps.endAdornment
                    }}
                  />
                )}
                open={minopen}
            onClose={handleMinClose}
              />
           <IconButton style={{fontSize:"10px",padding:"0"}}
          onClick={handleIconMinClick}
        >
           <i
              className="pi pi-calendar-times"
              style={{ fontSize:"13px",color: "#D7D8DA" }}
            />
        </IconButton>
            </MuiPickersUtilsProvider>
            </div>
</div>
<div className="overlaydatestyle">
  <label className="labelstyle">Max</label>
  <div className="overlaydatediv">
<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                variant="inline"
                openTo="year"
                views={["year"]}
                value={endDate || null} 
                minDate={startDate}
                maxDate={maxYear}
                disableFuture
                minDateMessage={false}
                maxDateMessage={false}
                placeholder="End Date"
                format="yyyy"
                onChange={(e) => handleEndDateChange(e._d)
                } InputProps={{ classes }}
                renderinput={(params) => (
                  <TextField
                    {...params}
                    placeholder="End Date"
                    fullWidth 
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: params.InputProps.endAdornment
                    }}
                  />
                )}
                open={maxopen}
            onClose={handleMaxClose}
              />
          
            </MuiPickersUtilsProvider>
            <IconButton style={{fontSize:"10px",padding:"0"}}
          onClick={handleIconMaxClick}
        >
           <i
              className="pi pi-calendar-times"
              style={{ fontSize:"13px",color: "#D7D8DA" }}
            />
        </IconButton>
            {/* <i onClick={handleIconMaxClick}
              className="pi pi-calendar-times"
              style={{ fontSize:"13px",color: "#D7D8DA" }}
            /> */}
            </div>
</div>
           
          </div>
                            </div>

                            <div className="overlaydropdownContent propunitInput">
                              <label className="labelstyle">Property Units</label>
                              <Tooltip className="slidertooltip" target=".slider>.p-slider-handle-start" content={`${selectedPropUnits[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".slider>.p-slider-handle-end" content={`${selectedPropUnits[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinUnitChange(e.value)}  />
                              <Slider min={minUnit*1} max={maxUnit*1} step={50} value={selectedPropUnits} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedPropUnits(e.value);setPropUnits(e.value)}} className="slider w-14rem" range />
                               <InputNumber placeholder="Max" onValueChange={(e) => onMaxUnitChange(e.value)} 
                               />
                            </div>
                            </div>
                            <div className="overlaydropdownContent">
                              <div className="overlayfooter">
                             <button className="overlaybtn" onClick={handleFilterApply}>Apply</button>
                             <button className="overlaybtn" onClick={handleFilterCancel}>Clear</button>
                              </div>
                            </div>
                  </div>
          </Sidebar>
     {/* <Chat /> */}
     </>
  );
};

export default IntelligentLeads;