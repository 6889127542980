import React, { useState, useEffect } from "react";
import "./rbac.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from 'primereact/radiobutton';    
// import { ConfirmDialog } from 'primereact/confirmdialog';
// import { confirmDialog } from 'primereact/confirmdialog';          
import axios from "axios";
import generator from "generate-password";
import { ListBox } from 'primereact/listbox';
import { Chips } from 'primereact/chips';
import ToastComponent from "../../components/AAANEWCOMPONENT/toast";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from 'primereact/multiselect';
import { Button } from "primereact/button";
import ReusableTable from "../../components/Table/Table";
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";
import stateData from "../../Data/stateData.json"

const Rbac = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState(null);
  const [organization, setOrganization] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [userAdded, setUserAdded] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [emailspaceerror,setemailSpaceError]= useState(false);
  const [emailerror,setemailError]= useState(false);
  const [orgerror,setOrgError]= useState(false);
  const [roleerror,setroleError]= useState(false);
  const [rolestateerror,setroleStateError]= useState(false);
  const [stateerror,setstateError]= useState(false);
  const[toast,setToast] = useState(false);
  const [addUserError,setAddUserError]=useState([]);
const [addUserErrorToast,setAddUserErrorToast]=useState(false);
  const[updatedUsertoast,setUpdatedUserToast] = useState(false);
  const [subName, setSubName] = useState("");
  const [userLimit, setUserLimit] = useState(0);
  const [ selectedSubscriptionType,setSelectedSubscriptionType] = useState(null);
  const [subAdded, setSubAdded] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedRegion, setCheckedRegion] = useState([]);
  const [orgDetails, setOrgDetails] = useState([]);
  const [customValue, setCustomValue] = useState('');
  const [roleDetails, setRoleDetails] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [msaRegions, setMSARegions] = useState([]);
  const [msas, setMsas] = useState([]);
  const [selectedMsas, setSelectedMsas] = useState([]);
  const [selectPlan, setSelectPlan] = useState(null);
  const [filter, setFilter] = useState('');
  const [addedUserData,setAddedUserData] = useState([]);
  const [userNameList, setUserNameList] = useState([]);
  const [userNoSubscriptionList, setUserNoSubscriptionList] = useState([]);
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [displayAddUserForm, setDisplayAddUserForm] = useState(false);
  const [availableUserData, setAvailableUserData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [orgDescription, setOrgDescription] = useState('');
  const [availableOrgData, setAvailableOrgData] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [availableRoleData, setAvailableRoleData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [editOrgData, setEditOrgData] = useState([]);
  const [editSubData, setEditSubData] = useState([]);
  const [editRoleData, setEditRoleData] = useState([]);
  const [userOrgAdded, setUserOrgAdded] = useState(false);
  const [orgAddedToast, setOrgAddedToast] = useState(false);
  const[subaddtoast,setSubAddToast] = useState(false);
  const [roleAddedToast, setRoleAddedToast] = useState(false);
  const [updatedOrgToast, setUpdatedOrgToast] = useState(false);
  const [orgAdded, setOrgAdded] = useState(false);
  const [roleAdded, setRoleAdded] = useState(false);
  const [updatedRoleToast, setUpdatedRoleToast] = useState(false);
  const [displayDeleteConfirm,setDisplayDeleteConfirm] = useState(false);
  const [deleteDetails,setDeleteDetails] = useState([]);
  const [deletedUserToast, setDeletedUserToast] = useState(false);
  const [deleteSuccessToast, setDeleteSuccessToast] = useState(false);
  const [userListDataLoad,setUserListDataLoad] = useState(false);
  const [orgListDataLoad,setOrgListDataLoad] = useState(false);
  const [userSubscriptionListLoad,setUserSubscriptionListLoad] = useState(false);
  const [roleListDataLoad,setRoleListDataLoad] = useState(false);
  const [availableSubData, setAvailableSubData] = useState([]);
  const [updatedSubToast, setUpdatedSubToast] = useState(false);
  const [editSubUserList, setEditUserSubscriptionList] = useState([]);
const [previousSubTypeId,setpreviousSubTypeId] = useState(null);
const [customOrgDescription,setCustomOrgDescription]=useState('');
const [orgOthersError,setOrgOthersError]=useState(false);
const [clearEditData, setClearEditData] = useState(false);
const [selectedOrg, setSelectedOrg] = useState(null);
const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
const userData = JSON.parse(sessionStorage.getItem("userdata"));
const user = userData.data.user;
const userid = String(user.id);
const userToken = userDetails.data.token;
const Org = user.organization;
const org = selectedOrg !== null ? selectedOrg?.id : Org;
const [lorg, setLOrg] = useState(org);
let subauth = userData.data;


  useEffect(()=>{
    getUser();
    getOrg();
    getRoles();
    setLOrg(org);
  },[])
  useEffect(()=>{
  getUser();
  },[lorg,userAdded,userDeleted,addedUserData])
  useEffect(()=>{
    if(selectedSubscriptionType !== null){
      setSelectedMsas([]);
      setFilter('');
      getdata();
    }
    if(selectedSubscriptionType !== null && editSubData.length === 0){
      setSelectPlan('');
    }
   },[selectedSubscriptionType,editSubData])  
   useEffect(()=>{
    if(activeIndex === 1){
      getOrg();
    }
    if(activeIndex === 2){
      getSubscriptionTypes();
      getMSARegions();
      getUserSubscriptionList();
      getUserNoSubscriptionList();
    }
    if(activeIndex === 3){
      getRoles();
    }
    setEditSubData([]);
    setEditData([]);
    setEditOrgData([]);
    setEditRoleData([]);
    setSelectedSubscriptionType(null);
  setSelectedMsas([]);
  setSubName('');
  setUserLimit(0);
  setSelectedUsers([]);
  setFilter('');
  setSelectPlan([]);
  setRoleName("");
  setRoleDescription("");
  setOrgName("");
  setOrgDescription("");
  setFirstName("");
  setLastName("");
  setEmail("");
  setSelectedRole(null);
  setSelectedOrganization(null);
  setState(null);
   },[activeIndex,subAdded,deleted,orgAdded,roleAdded,clearEditData,lorg])
   useEffect(() => {
    setUserNameList(userNameList)
  }, [userNameList])
  useEffect(()=>{
    setLOrg(org);
   },[selectedOrg])

  const getUser = async () => {
    axios
      .get(`${process.env.REACT_APP_USER_ORG}/${lorg}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((data) => {
        setUserListDataLoad(true);
        let i = data.data;
        let u = i.map(d =>
          d.firstName?.charAt(0).toUpperCase() + d.firstName?.slice(1).toLowerCase());
        setUserNameList(i);
        if(data.status === 200){
          if(i.length > 0){
            setUserListDataLoad(false);
          }
        }
        // setTokenExpired(false);
      })
      .catch((err) =>{
        console.log(err);
        // let error = err.response.data.error;
        // if(error.message === "This token has expired"){
        //  setTokenExpired(true);
        //  sessionStorage.setItem('logged',false)
        // }
      });
  }
  const getUserSubscriptionList = async () =>{
    axios
    .get(`${process.env.REACT_APP_USER_SUBSCRIPTION_LIST}/${lorg}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((data) => {
      setUserSubscriptionListLoad(true);
      let i = data.data;
      setUserSubscriptionList(i);
      if(data.status === 200){
        if(i.length > 0){
          setUserSubscriptionListLoad(false);
        }
      }
      // setTokenExpired(false);
    })
    .catch((err) =>{
      console.log(err);
      // let error = err.response.data.error;
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)
      // }
    });
  }
  const getUserNoSubscriptionList = async () =>{
    axios
    .get(`${process.env.REACT_APP_USER_NOSUBSCRIPTION_LIST}/${lorg}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((data) => {
      let i = data.data;
      if(data.status === 200){
        setUserNoSubscriptionList(i);
      }
      // setTokenExpired(false);
    })
    .catch((err) =>{
      console.log(err);
      // let error = err.response.data.error;
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)
      // }
    });
  }
  const StateData = stateData;
  const UserImageTemplate = (data) => {
 return <i className="pi pi-user"></i>
  }
  const RoleTemplate = (data) => {
    let role = roleDetails.filter(item => item.id === data.role);
    return <div>{role.map(a=> a.name)}</div>;
  }
  const OrgTemplate = (data) => {  
    let org = orgDetails.filter(item => item.id === data.org);
    return <div>{org.map(a=> a.name)}</div>;
  }
  const ActiveTemplate = (data) => {
    if(data.enabled === true){
      return <div className="activediv"><div className="activestatus"></div></div>
    }
  }
  const ActionsTemplate = (data) => {
    return <div  className="actionstyle"><i onClick={() => handleEditData(data)}className="pi pi-user-edit editicon"><span className="tooltiptext">Edit User</span></i><i onClick={() => handleDeleteAction(data)} className="pi pi-trash deleteicon"><span className="tooltiptext">Delete User</span></i></div>
  }
  const OrgActionsTemplate = (data) => {
    return <div  className="actionstyle"><i onClick={() => handleEditOrgData(data)}className="pi pi-user-edit editicon"><span className="tooltiptext">Edit Org</span></i><i onClick={() => handleDeleteAction(data)} className="pi pi-trash deleteicon"><span className="tooltiptext">Delete Org</span></i></div>
  }
  const SubActionsTemplate = (data) => {
    return <div  className="actionstyle"><i onClick={()=> handleEditSubData(data)} className="pi pi-user-edit editicon"><span className="tooltiptext">Edit</span></i><i onClick={() => handleDeleteAction(data)} className="pi pi-trash deleteicon"><span className="tooltiptext">Delete</span></i></div>
  }
  const RoleActionsTemplate = (data) => {

    return <div  className="actionstyle"><i onClick={() => handleEditRoleData(data)}className="pi pi-user-edit editicon"><span className="tooltiptext">Edit Role</span></i>
    {/* <i onClick={() => handleDeleteAction(data)} className="pi pi-trash deleteicon"><span class="tooltiptext">Delete Role</span></i> */}
    </div>
  }
  const getRoles = async () =>{
    await axios.get(`${process.env.REACT_APP_ROLE}`,
      {
               headers: {
                 Authorization: `Bearer ${userToken}`,
               },
             }
    )
    .then((data) => {
      setRoleListDataLoad(true);
      let dd = data.data;
      let ds = dd.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
         }
         if (a.name > b.name) {
           return 1;
         }
         return 0;
       });
      setRoleDetails(ds);
      if(data.status === 200){
        
        if(ds.length > 0){
          setRoleListDataLoad(false);
        }
      }
  })
 }
const getSubscriptionTypes = async () =>{
  await axios.get(`${process.env.REACT_APP_SUBSCRIPTION_TYPES}`,
    {
             headers: {
               Authorization: `Bearer ${userToken}`,
             },
           }
  )
  .then((data) => {
    let dd = data.data;
    setSubscriptionTypes(dd);
})
}
const getMSARegions = async () =>{
await axios.get(`${process.env.REACT_APP_MSA_REGIONS}`,
  {
           headers: {
             Authorization: `Bearer ${userToken}`,
           },
         }
)
.then((data) => {
  let dd = data.data;
  setMSARegions(dd); 
})
}
 const getOrg = async () =>{
       await axios.get(`${process.env.REACT_APP_ORGANIZATION}`,
        {
                 headers: {
                   Authorization: `Bearer ${userToken}`,
                 },
               }
       )
       .then((data) => {
        setOrgListDataLoad(true);
         let dd = data.data;
         let ds = dd.sort(function (a, b) {
           if (a.name < b.name) {
             return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
         setOrgDetails(ds);
         let selectedOrganization = dd?.filter(item => item.id*1 === Org);
         let FinalSelectedOrg = selectedOrg === null ? selectedOrganization[0] : selectedOrg;
         let checkOrg = ds?.find(item => item.id*1 === FinalSelectedOrg.id);
         let FinalOrg = checkOrg !== undefined ? FinalSelectedOrg : selectedOrganization[0];
         setSelectedOrg(FinalOrg);
         if(data.status === 200){
          if(ds.length > 0){
            setOrgListDataLoad(false);
          }
        }

     })
    }
    const OTHERS_OPTION = { name: 'Others', value: 'others' };
  
    const handleDeleteUser = (data) =>{
      let uid = data.id;
      let deleteUserUpdate = {
        enabled : false
      }
      axios
        .patch(`${process.env.REACT_APP_USER_UPDATE}/${uid}`, deleteUserUpdate, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          setDeletedUserToast(true);
          setUserDeleted(true);
          setTimeout(function () {
            setDeletedUserToast(false)
         }, 3000); 
        
        });
    }
    const handleDeleteAction = (data) =>{
      setDisplayDeleteConfirm(true);
      setDeleteDetails(data);
    }
    const handleDelete = (data) =>{
      setDeleted(false);
      let id = data.id;
      let name = data.name;
      let url = activeIndex === 1 ? process.env.REACT_APP_ORGANIZATION : activeIndex === 2 ? process.env.REACT_APP_SUBSCRIPTION_DATA : process.env.REACT_APP_ROLE 

      axios
        .delete(`${url}/${id}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          setDeleted(true);
          setDeleteSuccessToast(true);
          setTimeout(function () {
            setDeleteSuccessToast(false)
         }, 3000);
          setDisplayDeleteConfirm(false);
          setDeleteSuccessToast(true);
          setTimeout(function () {
            setDeleteSuccessToast(false)
         }, 3000);
        
        
        });
    }
    const handleDropdownChange = (e) => {
      setSelectedOrganization(e.value)
      if (e.value !== 'others') {
          setCustomValue('');
          setCustomOrgDescription('');
      }
  };

  const handleCustomValueChange = (e) => {
      setCustomValue(e.target.value);
  };
  const handleAddOption = () => {
      const newOption = { name: customValue, value: customValue };
      setOrgDetails(prevOptions => [...prevOptions, newOption]);
      setUserOrgAdded(false);
      let newOrgItem = {
        name: customValue,
        description:customOrgDescription,
        addedby: userid
      }
      if(customValue !== ""){
      axios
      .post(`${process.env.REACT_APP_ORGANIZATION}`, newOrgItem, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if(res.status === 200){
          let i = res.data;
          setUserOrgAdded(true);
          setTimeout(function () {
            setUserOrgAdded(false)
          }, 3000); 
          setSelectedOrganization(i);
          setCustomValue('');
          setCustomOrgDescription('');
          getOrg();
      }
      });
    }else{
      setOrgError(true);
      setTimeout(function () {
       setOrgError(false)
      }, 3000); 
    }
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    setAddUserErrorToast(false);
    if(selectedOrganization !== null && selectedOrganization !== "others" && !emailerror && !emailspaceerror && selectedRole !== null && state !== null)
     {
       const rand = generator.generate({
        length: 4,
        numbers: true
      })
        const newItem = {
        email: email,
        password: generator.generate({
          length: 10,
          numbers: true
        }),
        firstName: `${firstName.charAt(0).toUpperCase() +
          firstName.slice(1).toLowerCase()
          }`,
        lastName: `${lastName.charAt(0).toUpperCase() +
          lastName.slice(1).toLowerCase()
          }`,
        username: `${(firstName + lastName + organization + rand).toLowerCase()}`,
        org: selectedOrganization.id*1,
        addedBy:userid,
        role: selectedRole.id*1,
        state: state.abbreviation,
      };
      axios
        .post(`${process.env.REACT_APP_USER_SIGNUP}`, newItem, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          if(res.status === 200){
            let i = res.data;
          let addedData = [];
          addedData.push(i);
          setAddedUserData(addedData);
          setUserAdded(true);
          setToast(true);
          setTimeout(function () {
           setToast(false)
         }, 3000); 
         setFirstName("");
         setLastName("");
         setEmail("");
         setSelectedOrganization(null);
         setSelectedRole(null);
         setState(null);
         setDisplayAddUserForm(false);
        }
        })
         .catch((err) =>{
        let error = err.response.data.error;
        console.log(err.response.data,"error check")
        setAddUserErrorToast(true);
        setTimeout(function () {
          setAddUserErrorToast(false);
        }, 3000); 
        setAddUserError(error.message);
          // if(error.message === "This token has expired"){
          //  setTokenExpired(true);
          //  sessionStorage.setItem('logged',false)
          // }
        });    
    }
    if(state === null && selectedRole === null){
      setroleStateError(true);
        setTimeout(function () {
         setroleStateError(false)
         }, 3000); 
        }
    if(state === null && selectedRole !== null){
                setstateError(true);
          setTimeout(function () {
           setstateError(false)
         }, 3000); 
    }
     if(state !== null && selectedRole === null){
                setroleError(true);
          setTimeout(function () {
           setroleError(false)
         }, 3000); 
    }   
    if(state !== null && selectedRole !== null){
     if(selectedOrganization === null){
      setOrgError(true);
setTimeout(function () {
 setOrgError(false)
}, 3000); 
     }
     if(selectedOrganization === "others"){
      setOrgOthersError(true);
setTimeout(function () {
 setOrgOthersError(false)
}, 3000); 
     }

}
  };
  const handleUserUpdate = (e) => {
    e.preventDefault();
    setUserAdded(false);
    console.log(editData)
    let id = editData.map(a => a.id);
    let uid = id.toString();
    if(selectedOrganization !== null && selectedOrganization !== "others" && !emailerror && !emailspaceerror && selectedRole !== null && state !== null)
     {
      const originalData = availableUserData;
      const updatedData = {
        email: email,
        firstName: `${firstName.charAt(0).toUpperCase() +
          firstName.slice(1).toLowerCase()
          }`,
        lastName: `${lastName.charAt(0).toUpperCase() +
          lastName.slice(1).toLowerCase()
          }`,
        org: selectedOrganization.id*1,
        role: selectedRole.id*1,
        state: state.abbreviation,
        addedBy:userid
      };
      const finalUpdatedValues = {};
      for(const key in originalData){
        if(originalData[key] !== updatedData[key]){
          finalUpdatedValues[key] = updatedData[key];
        }
      }    
      axios
        .patch(`${process.env.REACT_APP_USER_UPDATE}/${uid}`, finalUpdatedValues, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          setUserAdded(true);
            let i = res.data;
          setUpdatedUserToast(true);
          setDisplayAddUserForm(false)
          setTimeout(function () {
            setUpdatedUserToast(false)
         }, 3000); 
        
         setFirstName("");
         setLastName("");
         setEmail("");
         setSelectedOrganization(null);
         setSelectedRole(null);
         setState(null);
        });
         
    }
    if(state === null && selectedRole === null){
      setroleStateError(true);
        setTimeout(function () {
         setroleStateError(false)
         }, 3000); 
        }
    if(state === null && selectedRole !== null){
                setstateError(true);
          setTimeout(function () {
           setstateError(false)
         }, 3000); 
    }
     if(state !== null && selectedRole === null){
                setroleError(true);
          setTimeout(function () {
           setroleError(false)
         }, 3000); 
    }   
    if(state !== null && selectedRole !== null){
      if(selectedOrganization === null){
       setOrgError(true);
 setTimeout(function () {
  setOrgError(false)
 }, 3000); 
      }
      if(selectedOrganization === "others"){
       setOrgOthersError(true);
 setTimeout(function () {
  setOrgOthersError(false)
 }, 3000); 
      }
 
 }
  };
  const handleEditData = (data) =>{
    let d = [];
    d.push(data)
  setEditData(d);
  let availUserDetails = {
    "email":data.email,
    "firstName":data.firstname,
    "lastName":data.lastname,
    "org":data.org,
    "role":data.role,
    "state":data.state,
    "addedby":data.addedby,

  }
  setAvailableUserData(availUserDetails);
  setFirstName(data.firstname)
  setLastName(data.lastname);
  setEmail(data.email);
  setDisplayAddUserForm(true);
  let state = StateData.filter(item => item.abbreviation === data.state);
  let role = roleDetails.filter(item => item.id === data.role);
  let org = orgDetails.filter(item => item.id === data.org);
  setSelectedOrganization(org[0]);
  setSelectedRole(role[0]);
  setState(state[0]);
  }
  const handleOrgSubmit = (e) => {
    e.preventDefault();
    setOrgAdded(false);
    let newOrgItem = {
      name: orgName,
      description: orgDescription ,
      addedby: userid
    }
    axios
    .post(`${process.env.REACT_APP_ORGANIZATION}`, newOrgItem, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((res) => {
      if(res.status === 200){
        setOrgAdded(true);
        let i = res.data;
        setOrgAddedToast(true);
        setTimeout(function () {
        setOrgAddedToast(false)
        }, 3000);       
         setOrgName("");
        setOrgDescription("");
    }
  })
  };
  const handleOrgUpdate = (e) => {
    e.preventDefault();
    setOrgAdded(false);
    let id = editOrgData.map(a => a.id);
    let finalid = id*1;
   if(orgName !== undefined && orgDescription !== undefined){
    const originalData = availableOrgData;
    const updatedData = {
      name: orgName,
      description: orgDescription,
      addedby:userid,
    };
    const finalUpdatedValues = {};
    for(const key in originalData){
      if(originalData[key] !== updatedData[key]){
        finalUpdatedValues[key] = updatedData[key];
      }
    } 
    axios
    .patch(`${process.env.REACT_APP_ORGANIZATION}/${finalid}`, finalUpdatedValues, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((res) => {
      setEditOrgData([]);
      setOrgAdded(true);
        setUpdatedOrgToast(true);
        setTimeout(function () {
          setUpdatedOrgToast(false)
       }, 3000); 
       setOrgName("");
       setOrgDescription("");
    
    });
  
   }
    
  };
  const handleEditOrgData = (data) =>{
    let d = [];
    d.push(data)
    setEditOrgData(d);
  let availOrgDetails = {
    "name": data.name,
    "description": data.description,
    "addedby":data.addedby,
  }
  setAvailableOrgData(availOrgDetails);
  setOrgName(data.name)
  setOrgDescription(data.description);
  }
  // const handleRoleSubmit = (e) => {
  //   e.preventDefault();
  //   setRoleAdded(false);
  //   let newOrgItem = {
  //     name: roleName,
  //     description: roleDescription ,
  //   }
  //   axios
  //   .post(`${process.env.REACT_APP_ROLE}`, newOrgItem, {
  //     headers: {
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //   })
  //   .then((res) => {
  //     if(res.status === 200){
  //       setRoleAdded(true);
  //       let i = res.data;
  //       setRoleAddedToast(true);
  //       setTimeout(function () {
  //       setRoleAddedToast(false)
  //       }, 3000);       
  //        setRoleName("");
  //       setRoleDescription("");
  //   }
  // })
  // };
  const handleRoleUpdate = (e) => {
    e.preventDefault();
    setRoleAdded(false);
    let id = editRoleData.map(a => a.id);
    let finalid = id*1;
   if(roleName !== undefined && roleDescription !== undefined){
    const originalData = availableRoleData;
    const updatedData = {
      name: roleName,
      description: roleDescription,
    };
    const finalUpdatedValues = {};
    for(const key in originalData){
      if(originalData[key] !== updatedData[key]){
        finalUpdatedValues[key] = updatedData[key];
      }
    } 
    axios
    .patch(`${process.env.REACT_APP_ROLE}/${finalid}`, finalUpdatedValues, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((res) => {
      setEditRoleData([]);
      setRoleAdded(true);
        setUpdatedRoleToast(true);
        setTimeout(function () {
          setUpdatedRoleToast(false)
       }, 3000); 
       setRoleName("");
       setRoleDescription("");
    
    });
  
   }
    
  };
  const handleEditRoleData = (data) =>{
    let d = [];
    d.push(data)
    setEditRoleData(d);
  let availOrgDetails = {
    "name": data.name,
    "description": data.description,
    "addedby":data.addedby,
  }
  setAvailableRoleData(availOrgDetails);
  setRoleName(data.name)
  setRoleDescription(data.description);
  }
  const handleEditSubData = (data) =>{
    let d = [];
    d.push(data)
    setEditSubData(d);
  let availSubDetails = {
      org:data.org,
      typeId: data.typeid,
      sub_data: data.sub_data,
      addedBy: data.addedby,
      subscriptionName: data.subscriptionname,
      userLimit:data.userlimit,
      users:data.users,
      endDate: data.endDate,
      subFor:data.subfor
  }
  setAvailableSubData(availSubDetails);
  setSubName(data.subscriptionname);
  setUserLimit(data.userlimit);

  let users = data.users.users;
  let combinedUsers = users.reduce((acc,current) => acc.concat(current),[]);
  let uniqueUsers = [...new Set(combinedUsers)];
  let u = userNoSubscriptionList;
  u.sort((a,b)=>{
   let aIndex = uniqueUsers.includes(a.id) ? uniqueUsers.indexOf(a.id) : uniqueUsers.length;
   let bIndex = uniqueUsers.includes(b.id) ? uniqueUsers.indexOf(b.id) : uniqueUsers.length;
   return aIndex-bIndex;

  });
  let selectedUserList = userNameList.filter(obj => uniqueUsers.includes(obj.id));
  selectedUserList.sort((a,b)=> uniqueUsers.indexOf(a.id)-uniqueUsers.indexOf(b.id));
  let combinedObj = [...selectedUserList,...u];
  setEditUserSubscriptionList(combinedObj)
  let selectedSubType = subscriptionTypes.filter(item => item.id === data.typeid )
  setSelectedUsers(selectedUserList);
  setSelectedSubscriptionType(selectedSubType[0]);
  setpreviousSubTypeId(selectedSubType[0]);
  let planid = data.subfor === "Monthly" ? "1" : data.subfor === "Quarterly" ? "2" : "3";
  setSelectPlan(planid);
 
  }
  const handleSubscriptionType = (value) =>{
    if(value.name === "MSA"){
      setCheckedRegion([]);
    }
    if(value.name === "Region"){
      setChecked([])
    }
    if(value.name === "All"){
      setChecked([])
      setCheckedRegion([])
    }
    setSelectedSubscriptionType(value);
  }
  const getdata = () => {
    let msaData = [];
    let regionData = [];
    // Extract sub_data from editSubData
    const subData = editSubData.length > 0 && selectedSubscriptionType.id === previousSubTypeId.id ? editSubData[0].sub_data : { MSA: [], region: [] };

    if (selectedSubscriptionType.id === 1) {
        msaRegions?.forEach((a) =>
            msaData.push({
                id: a.msa_code,
                region: a.region,
                name: a.msa_name,
                selected: editSubData.length > 0 ? subData.MSA.includes(a.msa_code) : false // Preselect based on subData MSA if editSubData is not empty
            })
        );
        setMsas(msaData);
        console.log(editSubData,"now")
        setSelectedMsas(msaData.filter(msa => msa.selected)); // Initialize selected MSAs
    }

    if (selectedSubscriptionType.id === 2) {
        msaRegions?.forEach((a) =>
            regionData.push({
                id: a.msa_code,
                name: a.region,
                msa_name: a.msa_name,
                selected: editSubData.length > 0 ? subData.region.includes(a.region) : false // Preselect based on subData region if editSubData is not empty
            })
        );

        let uniqueRegionName = [...new Map(regionData.map(regionData => [regionData.name, regionData])).values()];
        let sortedRegionName = uniqueRegionName.sort((a, b) => a.name.localeCompare(b.name));

        setMsas(sortedRegionName);
        setSelectedMsas(sortedRegionName.filter(region => region.selected)); // Initialize selected regions
    }
    setpreviousSubTypeId(selectedSubscriptionType)
   
};
const handleSelectionChange = (msa) => {
    if (msa.selected || selectedMsas.length < filteredMsas.length) {
        const updatedMsas = msas.map(item =>
            item.name === msa.name ? { ...item, selected: !item.selected } : item
        );

        if (msa.selected) {
            setSelectedMsas(selectedMsas.filter(item => item.name !== msa.name));
        } else {
            setSelectedMsas([...selectedMsas, { ...msa, selected: true }]);
        }
        setMsas(updatedMsas);
    }
};
const handleChipRemove = (removedMsa) => {
    const updatedMsas = msas.map(item => item.name === removedMsa.name ? { ...item, selected: false } : item);
    setMsas(updatedMsas);
    setSelectedMsas(selectedMsas.filter(item => item.name !== removedMsa.name));
};
const filteredMsas = msas.filter(msa => msa.name.toLowerCase().includes(filter.toLowerCase()));
const getNextMonthDate = (currentDate) => {
  let date = new Date(currentDate);
  let nextMonth = date.getMonth() + 1;
  if (nextMonth > 11) {
    nextMonth = 0; // Reset to January
    date.setFullYear(date.getFullYear() + 1); // Increment year
  }
  date.setMonth(nextMonth);
  if (date.getDate() !== currentDate.getDate()) {
    date.setDate(0); // Set to the last day of the previous month
  }
  return date.toISOString()
};
const getNextQuarterDate = (currentDate) => {
  let date = new Date(currentDate);
  let currentMonth = date.getMonth();
  let nextQuarterMonth = currentMonth + 3;
  if (nextQuarterMonth > 11) {
    nextQuarterMonth -= 12; // Reset to the beginning of the year
    date.setFullYear(date.getFullYear() + 1); // Increment year
  }
  date.setMonth(nextQuarterMonth);
  if (date.getDate() !== new Date(currentDate.getFullYear(), nextQuarterMonth, currentDate.getDate()).getDate()) {
    date.setDate(0); // Set to the last day of the previous month
  }
  return date.toISOString()
};
const getNextYearDate = (currentDate) => {
  let date = new Date(currentDate);
  date.setFullYear(date.getFullYear() + 1);
  return date.toISOString()
};
 const handleProceed = (e) =>{
  e.preventDefault();
  setSubAdded(false);
  let currentDate = new Date();
  let subFor = ''
  let endDate = ''
  if(selectPlan === "1"){
   endDate = getNextMonthDate(currentDate)
   subFor = "Monthly"
  }
  if(selectPlan === "2"){
   endDate = getNextQuarterDate(currentDate)
   subFor = "Quarterly"
  }
   if(selectPlan === "3"){
  endDate = getNextYearDate(currentDate)
  subFor = "Yearly"
  }
  let u = ''
  if(selectedUsers.length > 0){
    let userid = selectedUsers.map(a => a.id);
    u = {"users": userid}
  }
  let subData = ''
  if(selectedSubscriptionType.id === 1){
    let msacode = selectedMsas.map((a)=> a.id);
    subData = {"MSA": msacode}
    }
  if(selectedSubscriptionType.id === 2){
    let regionname = selectedMsas.map((a) => a.name);
    const filtered = msaRegions.filter(d => regionname.includes(d.region));
  
    let msacode = filtered.map((a)=> a.msa_code);
    subData = {"region":regionname,"MSA":msacode}
    }
  if(selectedSubscriptionType.id === 3){
  subData = {"ALL":"ALL"}
  }
  let subdataItem;
  // if(selectedSubscriptionType.id === 3){
  //   subdataItem = {
  //     org:lorg,
  //     typeId: selectedSubscriptionType.id*1,
  //     sub_data: subData,
  //     addedBy: userid,
  //     subscriptionName: subName,
  //     userLimit:userLimit*1,
  //     users:u,
  // }
  // }else{
    subdataItem = {
      org:lorg,
      typeId: selectedSubscriptionType.id*1,
      sub_data: subData,
      addedBy: userid,
      subscriptionName: subName,
      userLimit:userLimit*1,
      users:u,
      endDate: endDate,
      subFor:subFor
  }
// }
  axios
  .post(`${process.env.REACT_APP_SUBSCRIPTION_DATA}`,subdataItem, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  })
  .then((res) => {
    if(res.status === 200){
    let i = res.data;
    setSelectedSubscriptionType(null);
    setSelectedMsas([]);
    setSubName('');
    setUserLimit(0);
    setSelectedUsers([]);
    setFilter('');
    setSelectPlan([]);
    setSubAddToast(true);
    setSubAdded(true);
    setTimeout(function () {
     setSubAddToast(false);
   }, 3000);

  }
  });
}
  const handleSubUpdate = (e) => {
    e.preventDefault();
    setSubAdded(false);
    let id = editSubData.map(a => a.id);
    let finalid = id*1;
    let currentDate = new Date();
    let subFor = ''
    let endDate = ''
    if(selectPlan === "1"){
     endDate = getNextMonthDate(currentDate)
     subFor = "Monthly"
    }
    if(selectPlan === "2"){
     endDate = getNextQuarterDate(currentDate)
     subFor = "Quarterly"
    }
     if(selectPlan === "3"){
    endDate = getNextYearDate(currentDate)
    subFor = "Yearly"
    }
    let u = ''
    if(selectedUsers.length > 0){
      let userid = selectedUsers.map(a => a.id);
      u = {"users": userid}
    }
    let subData = ''
    if(selectedSubscriptionType.id === 1){
      let msacode = selectedMsas.map((a)=> a.id);
      subData = {"MSA": msacode}
      }
    if(selectedSubscriptionType.id === 2){
      let regionname = selectedMsas.map((a) => a.name);
      const filtered = msaRegions.filter(d => regionname.includes(d.region));
    
      let msacode = filtered.map((a)=> a.msa_code);
      subData = {"region":regionname,"MSA":msacode}
      }
    if(selectedSubscriptionType.id === 3){
    subData = {"ALL":"ALL"}
    }
   if(selectPlan !== undefined){
    const originalData = availableSubData;
    let updatedData = {
      org:lorg,
      typeId: selectedSubscriptionType.id*1,
      sub_data: subData,
      addedBy: userid,
      subscriptionName: subName,
      userLimit:userLimit*1,
      users:u,
      endDate: endDate,
      subFor:subFor
  }
   
    const finalUpdatedValues = {};
    for(const key in originalData){
      if(originalData[key] !== updatedData[key]){
        finalUpdatedValues[key] = updatedData[key];
      }
    } 
    axios
    .patch(`${process.env.REACT_APP_SUBSCRIPTION_DATA}/${finalid}`, finalUpdatedValues, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((res) => {
      setEditSubData([]);
      setSelectedSubscriptionType(null);
    setSelectedMsas([]);
    setSubName('');
    setUserLimit(0);
    setSelectedUsers([]);
    setFilter('');
    setSelectPlan([]);
      setSubAdded(true);
        setUpdatedSubToast(true);
        setTimeout(function () {
          setUpdatedSubToast(false)
       }, 3000);
    });
   }};
  const handleEmail = (e) => {
    const { value } = e.target;
    const hasSpaces = /\s/.test(value);
    const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const invalidEmail = !emailValidator.test(value);
    setemailError(invalidEmail);
    setEmail(value.toLowerCase());
    setemailSpaceError(hasSpaces);
  };
  const landingPageTemplate = (option) => {
    return (
      <div className="landing_page-item">
        <div>{option.name}</div>
      </div>
    );
  };
  const userlistpanelHeaderTemplate = (options) => {
    return (
      <div className={options.className}>
        <div>{options.filterElement}</div>
        <i onClick={(e)=>setSelectedUsers([])} className="pi pi-filter-slash clrfilter"></i>
        <div>{options.closeElement}</div>
      </div>
    );
  };
 const addUserDialog = () => {
    return <>
      <Dialog header="Add User" visible={displayAddUserForm} className="addUserDilog" draggable={false} onHide={() => setDisplayAddUserForm(false)}>
      <div className="addorsubscribeform">
      <form onSubmit={editData.length > 0 ? handleUserUpdate : handleUserSubmit} style={{paddingTop:"10px"}}>
      {userOrgAdded === true ? <ToastComponent severity="success" detail="Organization added successfully" /> : null}
      {roleerror === true ? <ToastComponent severity="error" detail="Role cannot be empty" /> : null }
      {orgerror === true ? <ToastComponent severity="error" detail="Organization cannot be empty" /> : null }
      {orgOthersError === true ? <ToastComponent severity="error" detail="Please add organization" /> : null }
      {stateerror === true ? <ToastComponent severity="error" detail="State cannot be empty" /> : null }
      {rolestateerror === true ? <ToastComponent severity="error" detail="Select Role and State" /> : null }
      {addUserErrorToast === true ?  <ToastComponent severity="info" detail={addUserError} /> : null }
                      <div className="dropdownPropertyBasic">
                        <div className="dropdownContent">
                          <label className="labelstyle">First Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={firstName} onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Enter First Name" keyfilter="alpha" required />
                        </div>

                        <div className="dropdownContent">
                          <label className="labelstyle">Last Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                       <InputText value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Enter Last Name" keyfilter="alpha" required />
                        </div>
                      </div>
                      <div className="dropdownPropertyBasic">
                        <div className="dropdownContent">
                          <label className="labelstyle">Organization<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                            <Dropdown
                           value={selectedOrganization}
                           options={[...orgDetails, OTHERS_OPTION]}
                           onChange={handleDropdownChange}
                           optionLabel="name"
                           placeholder="Select Organization"
                           itemTemplate={landingPageTemplate}
                         />
                          {selectedOrganization === 'others' && (
                <div style={{width:"100%" ,display:'flex',gap:"5px"}}>
                    <InputText
                        value={customValue}
                        onChange={handleCustomValueChange}
                        placeholder="Enter Organization" required
                        style={{ width: '80%',borderBottomRightRadius:'0px',borderTopRightRadius:'0px' }}
                    />
                     <InputText
                        value={customOrgDescription}
                        onChange={(e) => setCustomOrgDescription(e.target.value)}
                        placeholder="Enter Description"
                        style={{ width: '80%',borderBottomRightRadius:'0px',borderTopRightRadius:'0px' }}
                    />
                    <button className="addorgbtn" onClick={handleAddOption}>Add</button>
                </div>
            )}
                        </div>
                        <div className="dropdownContentRoleState">
                          <div className="dprs">
                            <label className="labelstyle">Role<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                           <Dropdown
                           value={selectedRole}
                           options={roleDetails}
                           onChange={(e) => {setSelectedRole(e.value);console.log(e.value)}}
                           optionLabel="name"
                           placeholder="Select Role"
                           itemTemplate={landingPageTemplate}
                           />     
                          </div>
                          <div className="dprs">
                            <label className="labelstyle">State<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                            <Dropdown
                              value={state}
                              options={StateData}
                              optionLabel="name"
                              onChange={(e) =>setState(e.target.value)} 
                              itemTemplate={landingPageTemplate}
                              placeholder="Select State"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="dropdownPropertyBasic">
                        <div className="dropdownContent">
                          <label className="labelstyle">Email ID<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={email} onChange={handleEmail} placeholder="Enter Email ID" required />
                        </div>
                      </div>
                      <div className="search btndiv">
                        <button type="submit" className="addsubbtn">{editData.length > 0 ? "Update" : "Add"}</button>
                      </div>
                    </form>
                    </div>
      </Dialog>
    </>
  };
 const handleDeleteConfirmDialogue = () => {
  let detail = activeIndex === 2 ? deleteDetails.subscriptionname : activeIndex === 0 ? deleteDetails.email : deleteDetails.name
  return <>
    <Dialog className="ConfirmationDialog" header="Confirmation" visible={displayDeleteConfirm} draggable={false} onHide={() => setDisplayDeleteConfirm(false)}>
     <>
     <div className="alignnotes">
      <p className="addchangestyle">{`Are you sure you want to delete ${detail}?`}</p>
      </div>
     </>
      <div className="dealfooter">
      <Button className="dealbtn" onClick={() =>{activeIndex !== 0 ?  handleDelete(deleteDetails) : handleDeleteUser(deleteDetails)}}>Yes</Button>
      <Button className="dealbtn" onClick={() => setDisplayDeleteConfirm(false)}>Cancel</Button>
      </div>
    </Dialog>
  </>
}
  const onOrgChange = (e) => {
    setSelectedOrg(e.value);
  };
  const RoleColumns = [
    { field: 'name', header: 'Name' ,sortable:false,style:{ maxWidth: '20%'}},
    { field: 'description', header: 'Description' ,sortable:false,style:{ maxWidth: '60%'},body: (rowData) => <div className="ellipsis-text">{rowData.description}</div>},
    { header: 'Actions',body: RoleActionsTemplate,sortable: false,style:{ width: '100px'}},
  ];
  const OrgColumns = [
    { field: 'name', header: 'Name' ,sortable:false,style:{ maxWidth: '20%'}},
    { field: 'description', header: 'Description' ,sortable:false,style:{ maxWidth: '80%'},body: (rowData) => <div className="ellipsis-text">{rowData.description}</div>},
    { header: 'Actions',body: OrgActionsTemplate,sortable: false,style:{ width: '100px'}},
  ];
  const SubColumns = [
    { field: 'subscriptionname', header: 'Subscription Name' ,sortable:false,style:{ maxWidth: '300px'},body: (rowData) => <div className="ellipsis-text">{rowData.subscriptionname}</div>},
    { field: 'userlimit', header: 'User Limit' ,sortable:false,style:{ maxWidth: "300px" }},
    { header: 'Actions',body: SubActionsTemplate,sortable: false,style:{ width: '100px'}},
  ];
  const UserColumns = [
    { header: 'User', body:UserImageTemplate ,sortable:false,style:{ width:'50px'}},
    { field: 'firstname', header: 'First Name' ,sortable:false,style:{ minWidth:'100px'}},
    { field: 'lastname', header: 'Last Name' ,sortable:false,style:{ minWidth:'100px'}},
    { field: 'email', header: 'Email' ,sortable:false,style:{ width: '20%'}, body: (rowData) => <div className="ellipsis-text">{rowData.email}</div>},
    { header: 'Active',body: ActiveTemplate,sortable: false,style:{ width: '50px',textAlign:'center'}},
    { header: 'Role',body: RoleTemplate,sortable: false,style:{ minWidth:'100px'}},
    { header: 'Organization',body: OrgTemplate,sortable: false,style:{ minWidth:'100px'}},
    { header: 'Actions',body: ActionsTemplate,sortable: false,style:{ width: '100px'}},
  ];
  
  return(
    <div className="Rbac rbprop AccessControl">
         <div className="dropdown orgicon">
          <Dropdown style={{width:"100%"}} value={selectedOrg} placeholder="Select Org" onChange={onOrgChange}  options={orgDetails} optionLabel="name" />
       </div>
    <TabView  activeIndex={activeIndex} className="gridtab"
     onTabChange={(e) =>  {setActiveIndex(e.index);setDeleteSuccessToast(false);setToast(false);
      setUpdatedUserToast(false);setOrgAddedToast(false);setUpdatedOrgToast(false);}} >
     <TabPanel header="Users">  
     <div className="panelContent users">
     {toast === true ? <ToastComponent severity="success" detail="User Added Successfully" /> : null }
      {deletedUserToast === true ? <ToastComponent severity="success" detail="User Deleted Successfully" /> : null }
      {updatedUsertoast === true ? <ToastComponent severity="success" detail="User Updated Successfully" /> : null }
      <div className="adduserbtndiv">
      <button onClick={() => {setDisplayAddUserForm(true);setClearEditData(!clearEditData)}} className="addsubbtn">Add User</button>
      </div>
     <div className="list-container">
     {userNameList?.length > 0 && userListDataLoad === false  && roleDetails.length > 0 &&  orgDetails.length > 0 ?
     <ReusableTable className="userlisttable" bodyClassName="userlisttablestyle" rows={10} data={userNameList} columns={UserColumns} paginated={false} />
      : userListDataLoad === true && userNameList.length === 0 ? <label className="nodataavailable">No Data Available</label> : <Loader />}
     </div>
     </div>
     {addUserDialog()}
     </TabPanel>
     <TabPanel header="Organization">
     <div className="panelContent">
     {deleteSuccessToast === true ? <ToastComponent severity="success" detail="Organization Deleted Successfully"/> : null }
     {orgAddedToast === true ? <ToastComponent severity="success" detail="Organization Added Successfully" /> : null }
     {updatedOrgToast === true ? <ToastComponent severity="success" detail="Organization Updated Successfully" /> : null }
      <div className="panelDiv">
      <div className="rbactableleft">
        <div className="list-container">
        {orgDetails.length > 0 && orgListDataLoad === false ? <ReusableTable className="userlisttable" bodyClassName="userlisttablestyle" rows={10}  data={orgDetails} columns={OrgColumns} paginated={false} />: orgListDataLoad === true && orgDetails.length === 0 ?  <label className="nodataavailable">No Data Available</label> : <Loader />}
        </div>
      </div>
      <div className="rbacaddright">
      <label style={{fontSize:"12px",fontWeight:"600"}} className="labelstyle">{editOrgData.length > 0 ?  
        <label>Update Organization<button className="backtoaddbtn" onClick={() => setClearEditData(!clearEditData)}>Back</button></label>
        :"Add Organization"}</label>
      <div className="addorsubscribeform">
      <form onSubmit={editOrgData.length > 0 ? handleOrgUpdate : handleOrgSubmit} style={{paddingTop:"10px"}}>
                      <div className="dropdownPropertyBasic">
                        <div className="dropdownContent">
                          <label className="labelstyle">Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={orgName} onChange={(e) => setOrgName(e.target.value)} 
                            placeholder="Enter Organization Name" keyfilter="alphanum" required />
                        </div>

                        <div className="dropdownContent">
                          <label className="labelstyle">Description<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={orgDescription} onChange={(e) => setOrgDescription(e.target.value)}
                            placeholder="Enter Description" keyfilter={/^[^<>*!]+$/} required />
                        </div>
                      </div>
                      <div className="search btndiv">
                        <button type="submit" className="addsubbtn">{editOrgData.length > 0 ? "Update" : "Add"}</button>
                      </div>
                    </form>
                    </div>
      </div>
      </div>
     </div>
    </TabPanel>
     <TabPanel header="Subscription">
      <div className="panelContent">
    {deleteSuccessToast === true ? <ToastComponent severity="success" detail="Subscription Deleted Successfully" /> : null }
    {subaddtoast === true ? <ToastComponent severity="success" detail="Subscription Added Successfully" /> : null }
    {updatedSubToast === true ? <ToastComponent severity="success" detail="Subscription Updated Successfully" /> : null}
      <div className="panelDiv">
      <div className="rbactableleft">
      <div className="list-container">
      {userSubscriptionList.length > 0 && userSubscriptionListLoad === false ? <ReusableTable className="userlisttable" bodyClassName="userlisttablestyle" rows={10}  data={userSubscriptionList} columns={SubColumns} paginated={false} /> : userSubscriptionListLoad === true && userSubscriptionList.length === 0 ? <label className="nodataavailable">No Data Available</label>: <Loader />}
       </div>
      </div>
      <div className="rbacaddright">
        <label style={{fontSize:"12px",fontWeight:"600"}} className="labelstyle">{editSubData.length > 0 ?
          <label>Update Subscription<button className="backtoaddbtn" onClick={() => setClearEditData(!clearEditData)}>Back</button></label> : "Add Subscription"}</label>
        <form onSubmit={editSubData.length > 0 ? handleSubUpdate : handleProceed} style={{paddingTop:"10px"}}>
        <div className="addorsubscribeform">
        <div className="dropdownPropertyBasic subfirstdiv" style={{display:"flex",flexDirection:"row"}}>
          <div className="dropdownContent">
          <label className="labelstyle">Subscription Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
          <InputText value={subName} onChange={(e) => setSubName(e.target.value)}
           placeholder="Enter Subscription Name" keyfilter="alpha" required />
        </div>
        <div className="dropdownContent">
                          <label className="labelstyle">Limit<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={userLimit}
                           onChange={(e) => setUserLimit(e.target.value)} placeholder="Enter User Limit" keyfilter="int" required />
                        </div>
                        {subName !== undefined && subName.length > 0 && userLimit > 0 ?
                        <div className="dropdownContent">
                          <label className="labelstyle">User List<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <MultiSelect 
                          value={selectedUsers} 
                          options={editSubData.length > 0 ? editSubUserList :userNoSubscriptionList}
                           onChange={(e) => setSelectedUsers(e.value)} 
                           optionLabel="email"
                           placeholder="Select Users"
                           panelHeaderTemplate={userlistpanelHeaderTemplate}
                           filter
                           resetFilterOnHide
                           maxSelectedLabels={1}
                           selectionLimit={userLimit}
                            />
                        </div>
                        : null}
        </div>
        </div>
        {subName !== undefined && subName.length > 0 && userLimit > 0  && selectedUsers.length > 0 ?
      <div className="addorsubscribeform">
                    <div className="subscriptionForm">
                      <div className="sfleft">
                        <div className="dropdownPropertyBasic">
                    <div className="dropdownContent" style={{width:"60%"}}>
                    <label className="labelstyle">Type<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                         <Dropdown
                         value={selectedSubscriptionType}
                         options={subscriptionTypes}
                         key="st"
                         onChange={(e) => handleSubscriptionType(e.value)}
                         optionLabel="name"
                         placeholder="Select Subcription Type"
                         itemTemplate={landingPageTemplate}
                       />
                       </div>
                      </div>
                      {subName !== undefined && subName.length > 0 && userLimit > 0 && selectedUsers.length > 0 && selectedSubscriptionType !== null && selectedMsas.length > 0 || subName !== undefined && subName.length > 0 && userLimit > 0 && selectedUsers.length > 0 && selectedSubscriptionType !== null && selectedSubscriptionType.name === "All" ?
                      <div className="dropdownPropertyBasic">
                      <div className="dropdownContent" style={{width:"80%"}}>
                    <label className="labelstyle">Select Your Plan<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                    <div className="p-field-radiobutton">
                    <RadioButton inputId="monthly" name="monthly" value="1" onChange={(e) => setSelectPlan(e.value)} checked={selectPlan === '1'} />
                    <label htmlFor="monthly">Monthly</label>
                     </div>
                     <div className="p-field-radiobutton">
                    <RadioButton inputId="quarterly" name="quarterly" value="2" onChange={(e) => setSelectPlan(e.value)} checked={selectPlan === '2'} />
                    <label htmlFor="quarter">Quarterly</label>
                     </div>
                     <div className="p-field-radiobutton">
                    <RadioButton inputId="annually" name="annually" value="3" onChange={(e) => setSelectPlan(e.value)} checked={selectPlan === '3'} />
                    <label htmlFor="annually">Annually</label>
                     </div>
                      </div>
                      </div>
                     : null }
                      </div>
                      <div className="sfright">
                      {selectedSubscriptionType !== null && selectedSubscriptionType.name !== "All" ?
                      <>
                     <div className='mainContainer'>
        <div className='selectionContainer'>
      <div className='chipContainer'>
        <Chips
        value={selectedMsas} 
        onChange={(e) => setSelectedMsas(e.value)} 
        itemTemplate={(item) => (<span className="p-chips-token">{item.name}</span>)}
        removeIcon="pi pi-minus"
        onRemove={(e) => handleChipRemove(e.value)}
        className="custom-chips"
        allowDuplicate={false}
    />
    </div>
        <div className="msa-selector">
            <h3>{selectedSubscriptionType.id === 1 ? "Select MSA" : "Select Region"}</h3>
            <InputText value={filter} onChange={(e) => setFilter(e.target.value)} 
             placeholder={selectedSubscriptionType.id === 1 ? "Search MSA" : "Search Region"} className="p-inputtext" />
            <ListBox
                value={null}
                options={filteredMsas}
                onChange={(e) => handleSelectionChange(e.value)}
                optionLabel="name"
                listStyle={{ maxHeight: '200px' }}
                itemTemplate={(option) => (
                    <div className="p-d-flex p-jc-between">
                        <span>{option.name}</span>
                        {option.selected ? (
                            <i className="pi pi-minus" onClick={() => handleSelectionChange(option)}></i>
                        ) : (
                            selectedMsas.length < filteredMsas.length && <i className="pi pi-plus" onClick={() => handleSelectionChange(option)}></i>
                        )}
                    </div>
                )}
            />
        </div>
        </div>
        </div>
      </> : null}
                    </div>
                   </div>
                  {subName !== undefined && selectedUsers.length > 0 && userLimit > 0 && subName.length > 0 && selectedSubscriptionType !== null && selectedMsas.length > 0 && selectPlan || checked.length > 0 || checkedRegion.length > 0 || subName !== undefined && selectedUsers.length > 0 && userLimit > 0 && subName.length > 0 && selectedSubscriptionType !== null && selectedSubscriptionType.name === "All" && selectPlan ?
                   <div className="dropdownPropertyBasic">
                    <div className="proceeddiv">
                    <button className="proceedbtn">{editSubData.length > 0 ? "Update" : "Proceed"}<i className="pi pi-arrow-right parrow"></i></button>
                    </div>
                   </div> : null}
                  </div>: null}
                  </form>
                  </div>
                  </div>
      </div>
     </TabPanel>
     <TabPanel header="Role">
      <div className="panelContent">
    {deleteSuccessToast === true ? <ToastComponent severity="success" detail="Role Deleted Successfully" /> : null }
     {roleAddedToast === true ? <ToastComponent severity="success" detail="Role Added Successfully" /> : null }
     {updatedRoleToast === true ?<ToastComponent severity="success" detail="Role Updated Successfully" /> : null }
      <div className="panelDiv">
      <div className="rbactableleft">
        <div className="list-container">
          {roleDetails.length > 0 && roleListDataLoad === false ? <ReusableTable className="userlisttable" bodyClassName="userlisttablestyle" rows={10}  data={roleDetails} columns={RoleColumns} paginated={false} /> : roleListDataLoad === true && roleDetails.length === 0 ? <label className="nodataavailable">No Data Available</label> : <Loader />}
         </div>
      </div>
      {editRoleData.length > 0 ?
      <div className="rbacaddright">
      <label style={{fontSize:"12px",fontWeight:"600"}} className="labelstyle">
       <label>Update Role<button className="backtoaddbtn" onClick={() => setClearEditData(!clearEditData)}>Back</button></label> 
      </label>
      <div className="addorsubscribeform">
      <form onSubmit={handleRoleUpdate} style={{paddingTop:"10px"}}>
                      <div className="dropdownPropertyBasic">
                        <div className="dropdownContent">
                          <label className="labelstyle">Name<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={roleName} onChange={(e) => setRoleName(e.target.value)}
                            placeholder="Enter Role Name" keyfilter="alphanum" required />
                        </div>

                        <div className="dropdownContent">
                          <label className="labelstyle">Description<span style={{fontSize:"18px",fontWeight:"bolder"}}>*</span></label>
                          <InputText value={roleDescription} onChange={(e) => setRoleDescription(e.target.value)}
                            placeholder="Enter Description" keyfilter={/^[^<>*!]+$/} required />
                        </div>
                      </div>
                     
                      <div className="search btndiv">
                        <button type="submit" className="addsubbtn">Update
                          {/* {editRoleData.length > 0 ? "Update" : "Add"} */}
                        </button>
                      </div>
                    </form>
              
                    </div>
      </div>
      :null}
      </div>
      </div>
     </TabPanel>
       </TabView>
     {handleDeleteConfirmDialogue()}     
   </div> 
    );
}

export default Rbac;