import React from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import "./mioverview.css";
import 'primeicons/primeicons.css';
        

const MIOverview = ({ img, alt,chart,header, subHeader, loadingBuyer,tooltip}) => {
    // const handleMouseOver = () =>{
    //     <i className="pi pi-info-circle info" style={{ fontSize: '10px',fontWeight:"600"}}>
    //     <span class="tooltiptext">{tooltip}</span>
    //     </i>
    // }
    return (
        <>
        <div className="mioverviewMain">
        <div className="mioverviewAnalysis">
        <div className="mioverviewImageDiv info">
        <img  className="mioverviewImage" src={img} alt={alt} />
        {/* <span class="tooltiptext">{tooltip}</span> */}
        </div>

                        
            <div className="mioverviewContent" >
           <div className="headercontent">
            {/* {header === "Under Contract" || header === "Expired Contracts" ?
            <i className="pi pi-info-circle info" style={{ fontSize: '10px',fontWeight:"600"}}>
            <span class="tooltiptext">{tooltip}</span>
            </i>
          : null} */}
           
            <p className="mioverviewHeader">{header}</p>
           </div>

            {/* <i className="pi pi-info-circle info"><p className="mioverviewHeader" tooltip="Save">{header}</p><span class="tooltiptext">{tooltip}</span></i> */}
                {
                    loadingBuyer ?
                        (
                            <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="5" />
                        )
                        :
                        (
                            <p className="mioverviewSubHeader">{subHeader}</p>
                        )
                }
            </div>
        </div>
            <div className="mioverviewChart" >{chart}</div>
            </div>
</>
    )
}

export default MIOverview;