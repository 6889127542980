import React from 'react';
import './download.css';

const GenericPdfDownloader = ({ stylebtn, rootElementId, downloadFileName }) => {

    const downloadPdfDocument = () => {
        const input = document.getElementById(rootElementId);
        if (!input) {
            console.error(`Element with id ${rootElementId} not found`);
            return;
        }

        // Add the printable class to make the element fullscreen
        input.classList.add('printable');

        // Trigger print
        window.print();

        // Remove the printable class after printing
        input.classList.remove('printable');
    };

    return (
        <button className={stylebtn} onClick={downloadPdfDocument}>
            <i style={{ fontSize: '12px', marginRight: '10px' }} className="pi pi-download" />
            Download
        </button>
    );
};

export default GenericPdfDownloader;
