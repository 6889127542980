import React, { useState, useEffect ,useRef } from "react";
import { MultiSelect } from "primereact/multiselect";
import "./datahub.css";
import moment from "moment";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
// import Chat from "./chat";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Slider } from "primereact/slider";
import { Tooltip } from 'primereact/tooltip';
// import { ListBox } from 'primereact/listbox';
import { CSVDownload, CSVLink } from "react-csv";
import ToastComponent from "../../components/AAANEWCOMPONENT/toast";
import _ from "lodash";
// import { Dropdown } from 'primereact/dropdown';
import { Button } from "@material-ui/core"; 
import { RadioButton } from 'primereact/radiobutton';              
import { InputNumber } from 'primereact/inputnumber';
import 'primeicons/primeicons.css';
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useNavigate } from 'react-router-dom';
import StateData from "../../Data/stateData.json";
import ViewDetailsDatahub from "./viewDetailsDatahub";
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";
import useAddAction from "../../components/AAANEWCOMPONENT/ActionAndSession/addAction";

const Datahub = () => {
  const [searchOwnerText , setSearchOwnerText] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);
  const [mapURL,setMapURL]=useState("");
  const [nearbymapURL,setNearbyMapURL]=useState("");
  const [advancedPropDataLoad, setAdvancedPropDataLoad] = useState(false);
  const [propAddressDataLoad, setPropAddressDataLoad] = useState(false);
  const [paNoDataShow, setPANoDataShow] = useState(false);
  const [propertyAddressLength, setPropertyAddressLength] = useState([]);
  const [propertyAddressData, setPropertyAddressData] = useState([]);
  const [pashow, setPAShow] = useState(false);
  const [enterAnyPropToast, setEnterAnyPropToast] = useState(false);
  const [searchvalue, setSearchValue] = useState([]);
  const [PropertySearchDetail,setPropertySearchDetail] = useState("true");
  const [showNoData,setShowNoData]=useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [orgDetails, setOrgDetails] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [OwnerData, setOwnerData] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [owner, setOwner] = useState([]);
  const [selectedYearsToMature, setSelectedYearsToMature] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [propUnits, setPropUnits] = useState([]);
  const [occupancy, setOccupancy] = useState([]);
  const [rentalRate, setRentalRate] = useState([]);
  const [yearBuilt, setYearBuilt] = useState([]);
  const [buildingSqft, setBuildingSqft] = useState([]);
  const [lastSaleAmount, setLastSaleAmount] = useState([]);
  const [loanAmount, setLoanAmount] = useState([]);
  const [loanTerm, setLoanTerm] = useState([]);
  const [householdCount, setHouseholdCount] = useState([]);
  const [householdFYFCount, setHouseholdFYFCount] = useState([]);
  const [avgHouseholdIncome, setAvgHouseholdIncome] = useState([]);
  const [medianHouseholdIncome, setMedianHouseholdIncome] = useState([]);
  const [selectedPropUnits, setSelectedPropUnits] = useState([]);
  const [selectedOccupancy, setSelectedOccupancy] = useState([]);
  const [selectedRentalRate, setSelectedRentalRate] = useState([]);
  const [selectedBuildingSqft, setSelectedBuildingSqft] = useState([]);
  const [selectedYearBuilt, setSelectedYearBuilt] = useState([]);
  const [selectedLastSaleAmount, setSelectedLastSaleAmount] = useState([]);
  const [selectedLoanAmount, setSelectedLoanAmount] = useState([]);
  const [selectedLoanTerm, setSelectedLoanTerm] = useState([]);
  const [selectedHouseholdCount, setSelectedHouseholdCount] = useState([]);
  const [selectedHouseholdFYFCount, setSelectedHouseholdFYFCount] = useState([]);
  const [selectedAvgHouseholdIncome, setSelectedAvgHouseholdIncome] = useState([]);
  const [selectedMedianHouseholdIncome, setSelectedMedianHouseholdIncome] = useState([]);
  // const [selectedInterestRate, setSelectedInterestRate] = useState(null);
  const [selectedLoanAssociated, setSelectedLoanAssociated] = useState([]);
  // const [selectedLoantoValue, setSelectedLoantoValue] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [selectedOwnerSegment, setSelectedOwnerSegment] = useState([]);
  const [propdownloadtoast, setPropDownloadToast] = useState(false);
  const [exportPtoast, setExportPToast] = useState(false);
  const [idtoast, setIDToast] = useState(false);
  const [propertyLength, setPropertyLength] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [ExportPropertyData, setExportPropertyData] = useState([]);
  const [pshow, setPShow] = useState(false);
  const [selectAnyFilterToast, setSelectAnyFilterToast] = useState(false);
  const [pNoDataShow, setPNoDataShow] = useState(false);
  const csvPropertyDownloadRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [propoffset, setPropOffset] = useState(0);
  const [propertydownloadready, setPropertyDownloadReady] = useState(false);
  const [maxRent, setMaxRent] = useState([]);
  const [minRent, setMinRent] = useState([]);
  const [maxOccu, setMaxOccu] = useState([]);
  const [minOccu, setMinOccu] = useState([]);
  const [maxUnit, setMaxUnit] = useState([]);
  const [minUnit, setMinUnit] = useState([]);
  const [maxSqft, setMaxSqft] = useState([]);
  const [minSqft, setMinSqft] = useState([]);
  const [maxYearBuilt, setMaxYearBuilt] = useState([]);
  const [minYearBuilt, setMinYearBuilt] = useState([]);
  const [maxLastSaleAmt, setMaxLastSaleAmt] = useState([]);
  const [minLastSaleAmt, setMinLastSaleAmt] = useState([]);
  const [maxLoanAmt, setMaxLoanAmt] = useState([]);
  const [minLoanAmt, setMinLoanAmt] = useState([]);
  const [maxTerm, setMaxTerm] = useState([]);
  const [minTerm, setMinTerm] = useState([]);
  const [maxHCount, setMaxHCount] = useState([]);
  const [minHCount, setMinHCount] = useState([]);
  const [maxHFCount, setMaxHFCount] = useState([]);
  const [minHFCount, setMinHFCount] = useState([]);
  const [maxAvgHI, setMaxAvgHI] = useState([]);
  const [minAvgHI, setMinAvgHI] = useState([]);
  const [maxMedHI, setMaxMedHI] = useState([]);
  const [minMedHI, setMinMedHI] = useState([]);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [targetedLead, setTargetedLead] = useState([]);
  const [targetedId, setTargetedId] = useState([]);
  const [addNotes, setAddNotes] = useState("");
  const [notesToast, setNotesToast] = useState(false);
  const [addLeadSuccessToast, setAddLeadSuccessToast] = useState(false);
  const [addLeadFailedToast, setAddLeadFailedToast] = useState(false);
  const [leadToast, setLeadToast] = useState(false);
  const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
  const userData = JSON.parse(sessionStorage.getItem("userdata"));
  const user = userData.data.user;
  const subs = userData.data.subs[0];
  const subscription = subs.sub_data;
  const userToken = userDetails.data.token;
  const userid = String(user.id);
  const Org = user.organization;
  let subId = subs.id;
  const [msaRegions, setMSARegions] = useState([]);
  const [msaDetails, setMsaDetails] = useState([]);
  const [regionDetails, setRegionDetails] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedMsa, setSelectedMsa] = useState([]);
  const [minmaxData, setMinMaxData] = useState([]);
 const [leadsInfo, setLeadsInfo] = useState({
    nedl_prop_id:"",
    property_name:"",
    prop_address: "",
    prop_city: "",
    prop_state:"",
    prop_county:"",
    prop_zip:"",
    prop_in_area:"",
    tot_unit_in_area:"",
    tot_sqft_in_area:"",
    current_rental_rate:"",
    current_avg_rental_per_sqft:"",
    current_occupancy:"",
    bed_count:"",
    prev_quarter_occ_rate:"",
    total_population_count:"",
    population_projection_in_5_years:"",
    median_age_5_year_forecast:"",
    household_count:"",
    household_average_size:"",
    median_household_income:"",
    prop_id: "",
    employed_population:"",
    loan_amount:"",
    loan_to_value:"",
    term:"",
    interest_rate:"",
    owner_name:"",
    owner_phone_number:"",
    owner_email_address:"",
    owner_address:"",
    owner_city:"",
    owner_state:"",
    owner_contact_name:"",
  });
  const { handleAddAction } = useAddAction(userToken);

  const navigate = useNavigate();  
  const isInitialMount = useRef(true);
   // for session time
   useEffect(() => {
    const date = moment();
    
    return()=>{
      const endDate = moment();
      const item = 
        {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Datahub",
          "start_time": date,
          "end_time": endDate,
          "session":userToken
        }     
      axios
          .post(`${process.env.REACT_APP_USER_SESSION_TIMES}`, item,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
          )
           .then(response => {
              console.log(response.config.data,"post success")
            })
            .catch((err) => console.log(err))
    }
}, [])
  useEffect(() => {
    if (visibleLeft) {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden'; // Also set for <html>
    } else {
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
    }

    // Cleanup on component unmount
    return () => {
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
    };
}, [visibleLeft]);

  useEffect(() => {
    if(activeIndex === 1){
    if (isInitialMount.current) {
      isInitialMount.current = false;
  } else {
    getProperty();
  }
}
  }, [offset]);
  useEffect(() => {
    if (isInitialMount.current) {
        isInitialMount.current = false;
    } else {
      getPropAddress();
    }
}, [propoffset]);
  useEffect(() => {
    getPropDetails();
  }, []);
  useEffect(() => {
    selectedRegionData();
  }, [selectedRegion]);
  useEffect(() => {
    selectedMsaData();
  }, [selectedMsa]);
  useEffect(() => {
    selectedStateData();
  }, [selectedState]); 
  useEffect(() => {
    if (csvPropertyDownloadRef && csvPropertyDownloadRef.current && propertydownloadready) {
        csvPropertyDownloadRef.current.link.click();
        setPropertyDownloadReady(false);
    }
  }, [propertydownloadready]);
  useEffect(()=>{
    if(tokenExpired === true){
      redirect()
    }
    },[tokenExpired])
const redirect =()=>{
  navigate('/');
}
let statedata = StateData;
const yearsToMature = [
  { name: '0-5 Years', code: 'five',value: [0,5] },
  { name: '6-10 Years', code: 'fivetoten', value:[6,10] },
  { name: '10+ Years', code: 'aboveten', value:[11,999] },
];
const previousPropertyclick = () => {
  if(offset !== 0){
    setOffset(offset-100);
  }
}
const nextPropertyclick = () => {
  setOffset(offset+100);
}
const previousPropertyAddressclick = () => {
    if(propoffset !== 0){
      setPropOffset(propoffset-100);
    }
  }
  const nextPropertyAddressclick = () => {
    setPropOffset(propoffset+100);
  }
  // useEffect(() => {
  //   if(propoffset >= 0){
  //     getPropAddress();
  //   }
  // }, [propoffset]);
  const getPropDetails = async () =>{
    await axios
    .get(`${process.env.REACT_APP_DATAHUB_MIN_MAX}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    .then((data) => {
      let u = data.data;
      let i = u.all;
      setMinMaxData(i);
      let minu = i[0].min_unit*1 !== null ? i[0].min_unit*1 : 0 ;
      let maxu = i[0].max_unit*1  !== null ? i[0].max_unit*1 : 0 ;
      let minocc = i[0].min_occupancy  !== null ? i[0].min_occupancy : 0 ;
      let maxocc = i[0].max_occupancy  !== null ? i[0].max_occupancy : 0 ;
      let minrent = i[0].min_rent !== null ? i[0].min_rent : 0 ;
      let maxrent = i[0].max_rent !== null ? i[0].max_rent : 0 ;
      let minbs = i[0].min_building*1 !== null ? i[0].min_building*1 : 0 ;
      let maxbs = i[0].max_building*1  !== null ? i[0].max_building*1 : 0 ;
      let minyb = i[0].min_built  !== null ? i[0].min_built : 0 ;
      let maxyb = i[0].max_built  !== null ? i[0].max_built : 0 ;
      let minlsa = i[0].min_sale_amount !== null ? i[0].min_sale_amount : 0 ;
      let maxlsa = i[0].max_sale_amount !== null ? i[0].max_sale_amount : 0 ;
      let minla = i[0].min_amount !== null ? i[0].min_amount : 0 ;
      let maxla = i[0].max_amount  !== null ? i[0].max_amount  : 0 ;
      let minlt = i[0].min_term  !== null ? i[0].min_term : 0 ;
      let maxlt = i[0].max_term  !== null ? i[0].max_term : 0 ;
      let minhc = i[0].min_householdcount !== null ? i[0].min_householdcount : 0 ;
      let maxhc = i[0].max_householdcount !== null ? i[0].max_householdcount : 0 ;
      let minhfc = i[0].min_house_forcast !== null ? i[0].min_house_forcast : 0 ;
      let maxhfc = i[0].max_house_forcast  !== null ? i[0].max_house_forcast : 0 ;
      let minahi = i[0].min_average  !== null ? i[0].min_average : 0 ;
      let maxahi = i[0].max_average  !== null ? i[0].max_average : 0 ;
      let minmhi = i[0].min_household_income !== null ? i[0].min_household_income : 0 ;
      let maxmhi = i[0].max_household_income !== null ? i[0].max_household_income : 0 ;
      setSelectedPropUnits([minu,maxu]);
      setSelectedOccupancy([minocc,maxocc]);
      setSelectedRentalRate([minrent,maxrent]);
      setSelectedBuildingSqft([minbs,maxbs]);
      setSelectedYearBuilt([minyb,maxyb]);
      setSelectedLastSaleAmount([minlsa,maxlsa]);
      setSelectedLoanAmount([minla,maxla]);
      setSelectedLoanTerm([minlt,maxlt]);
      setSelectedHouseholdCount([minhc,maxhc]);
      setSelectedHouseholdFYFCount([minhfc,maxhfc]);
      setSelectedAvgHouseholdIncome([minahi,maxahi]);
      setSelectedMedianHouseholdIncome([minmhi,maxmhi]);
      setMinRent(minrent);setMaxRent(maxrent);
      setMinOccu(minocc);setMaxOccu(maxocc);
      setMinUnit(minu);setMaxUnit(maxu);
      setMinSqft(minbs);setMaxSqft(maxbs);
      setMinYearBuilt(minyb);setMaxYearBuilt(maxyb);
      setMinLastSaleAmt(minlsa);setMaxLastSaleAmt(maxlsa);
      setMinLoanAmt(minla);setMaxLoanAmt(maxla);
      setMinTerm(minlt);setMaxTerm(maxlt);
      setMinHCount(minhc);setMaxHCount(maxhc);
      setMinHFCount(minhfc);setMaxHFCount(maxhfc);
      setMinAvgHI(minahi);setMaxAvgHI(maxahi);
      setMinMedHI(minmhi);setMaxMedHI(maxmhi);
      setTokenExpired(false)
    })
    .catch((err) =>{
      // let error = err.response.data.error;
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)
      // }
    });
    };
const getPropAddress = async () =>{
  setPANoDataShow(false);
  let remainingurl = '';
  if(searchvalue?.length === 0 ){
    setPAShow(false);
      setEnterAnyPropToast(true);
          setTimeout(function () {
            setEnterAnyPropToast(false)
          }, 5000);
          setPANoDataShow(false);
  }else{
    setEnterAnyPropToast(false);
    if (PropertySearchDetail === "true" && searchvalue.length > 0) {
      remainingurl = `&propertyName=${searchvalue}`
    }
    if (PropertySearchDetail === "false" && searchvalue.length > 0) {
      remainingurl = `&address=${searchvalue}`
    }
    setPropAddressDataLoad(true);
    await axios.get(`${process.env.REACT_APP_DATAHUB_BYPROPERTY}?offset=${propoffset}${remainingurl}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
    .then((data) => {
      if (data?.status === 200) {   
          let i = data.data;
     setPropAddressDataLoad(false);
      setTokenExpired(false);
      // if(i === "Please ADD Subscription to access Data"){
      //   setSubscriptionError(true);
      //  }
console.log(i,"data")
if(i !== "No Data Available"){
  let d = i.all;
  let proplength = i.count;
  let finalPropertyLength = proplength.map(r => r.count);
  setPropertyAddressLength(finalPropertyLength);
  getMSARegions(d,"propertyAddress");    
  setPAShow(true);
}
else{
  setPAShow(false);
  setPANoDataShow(true);
}
let addaction = {
  "userid": userid,
  "org": Org,
  "subs_id": subId,
  "page": "Datahub",
  "widget": "Property Search",
  "actions":{    
    "actiontype": 'clicked',
  }
}
handleAddAction(addaction);           
}})
    .catch((err) =>{
      console.log(err)
      // let error = err.response.data.error;
    
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)
      // }
    });  
  }
}
const getProperty = async (exportclicked) => {
  setPNoDataShow(false);
  let remainingurl = '';
  if (selectedRegion.length === 0  &&  selectedState.length === 0 && selectedMsa.length === 0 && selectedCities.length === 0
     && propUnits.length === 0 && occupancy.length === 0 && rentalRate.length === 0 && yearBuilt.length === 0 && buildingSqft.length === 0
     && lastSaleAmount.length === 0 && loanAmount.length === 0 && loanTerm.length === 0 && householdCount.length === 0 
     && householdFYFCount.length === 0 && avgHouseholdIncome.length === 0 && medianHouseholdIncome.length === 0
     && selectedLoanAssociated.length === 0 && selectedYearsToMature.length === 0
      && selectedOwner.length === 0) {
    setPShow(false);
      setSelectAnyFilterToast(true);
      setTimeout(function () {
        setSelectAnyFilterToast(false)
      }, 3000);

  }     
  else {
  if (selectedRegion.length > 0 && selectedMsa.length === 0) {
    const allMatches = selectedRegion.reduce((acc, m) => {
      const matches = msaRegions.filter((item) => item.region === m.region);
      return acc.concat(matches);
    }, []);
    let uniqueMSA = [...new Map(allMatches.map(mData => [mData.msa_code, mData])).values()];
    let r = uniqueMSA?.map(d => d.msa_code);
    let formattedMsa = r.join();
    remainingurl = `&msa=${formattedMsa}`
  }
  if (selectedMsa.length > 0) {
    let c = selectedMsa?.map(d => d.msa_code);
    let formattedSubmarket = c.join();
    remainingurl = `${remainingurl}&msa=${formattedSubmarket}`
  }
  if (selectedState.length > 0) {
    let c = selectedState?.map(d => d.name);
    let formattedState = c.join();
    remainingurl = `${remainingurl}&state=${formattedState}`
  }
  if (selectedCities.length > 0) {
    let c = selectedCities?.map(d => d.city);
    let formattedCity = c.join();
    remainingurl = `${remainingurl}&city=${formattedCity}`
  }
//   if (selectedPropertyName.length > 0) {
//   let c = selectedPropertyName?.map(d => d.property_name);
//   let formattedPropName = c.join();
//   remainingurl = `${remainingurl}&propertyName=${formattedPropName}`
// }
  if (propUnits.length > 0) {
    remainingurl = `${remainingurl}&punits=${propUnits[0]}&punite=${propUnits[1]}`
  }
  if (occupancy.length > 0) {
    remainingurl = `${remainingurl}&ocs=${occupancy[0]}&oce=${occupancy[1]}`
  }
  if (rentalRate.length > 0) {
    remainingurl = `${remainingurl}&rrs=${rentalRate[0]}&rre=${rentalRate[1]}`
  }
  if (buildingSqft.length > 0) {
    remainingurl = `${remainingurl}&bas=${buildingSqft[0]}&bae=${buildingSqft[1]}`
  }
  if (yearBuilt.length > 0) {
    remainingurl = `${remainingurl}&ybs=${yearBuilt[0]}&ybe=${yearBuilt[1]}`
  }
  if (lastSaleAmount.length > 0) {
    remainingurl = `${remainingurl}&lsas=${lastSaleAmount[0]}&lsae=${lastSaleAmount[1]}`
  }
  if (selectedLoanAssociated.length > 0) {
    remainingurl = `${remainingurl}&la=${selectedLoanAssociated}`
  }
  if (selectedYearsToMature.length > 0) {
    let s = selectedYearsToMature[0];
    remainingurl = `${remainingurl}&ytms=${s[0]}&ytme=${s[1]}`
  }
  if (loanAmount.length > 0) {
    remainingurl = `${remainingurl}&las=${loanAmount[0]}&lae=${loanAmount[1]}`
  }
  if (loanTerm.length > 0) {
    remainingurl = `${remainingurl}&ts=${loanTerm[0]}&te=${loanTerm[1]}`
  }
  // if (selectedLoantoValue.length > 0) {
  //   remainingurl = `${remainingurl}&latv=${selectedLoantoValue}`
  // }
  // if (selectedInterestRate !== null) {
  //   remainingurl = `${remainingurl}&ir=${selectedInterestRate}`
  // }
  if (selectedOwner.length > 0) {
    let c = selectedOwner?.map(d => d.owner_name);
    let formattedOwner = c.join();
    remainingurl = `${remainingurl}&owner=${formattedOwner}`
  }
  if (householdCount.length > 0) {
    remainingurl = `${remainingurl}&hcs=${householdCount[0]}&hce=${householdCount[1]}`
  }
  if (householdFYFCount.length > 0) {
    remainingurl = `${remainingurl}&hyfs=${householdFYFCount[0]}&hyfe=${householdFYFCount[1]}`
  }
  if (avgHouseholdIncome.length > 0) {
    remainingurl = `${remainingurl}&ahis=${avgHouseholdIncome[0]}&ahie=${avgHouseholdIncome[1]}`
  }
  if (medianHouseholdIncome.length > 0) {
    remainingurl = `${remainingurl}&mhis=${medianHouseholdIncome[0]}&mhie=${medianHouseholdIncome[1]}`
  }
  // if (selectedOwnerSegment.length > 0) {
  //   let c = selectedOwnerSegment?.map(d => d.segment);
  //   let formattedOwnerSegment = c.join();
  //   remainingurl = `${remainingurl}&segement=${formattedOwnerSegment}`
  // }
  if(exportclicked !== "ExportClicked"){
    setAdvancedPropDataLoad(true);
  }
    if(exportclicked === "ExportClicked"){
    setPropDownloadToast(true);
    }
  
  await axios.get(`${process.env.REACT_APP_DATAHUB_BYPROPERTY}?offset=${offset}${remainingurl}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }).then((data) => {
      if (data?.status === 200) { 
        if(exportclicked !== "ExportClicked"){
            
        setAdvancedPropDataLoad(false);
        setTokenExpired(false);
let i = data.data;
if(i !== "No Data Available"){
  let d = i.all;
  let proplength = i.count;
  let finalPropertyLength = proplength.map(r => r.count);
  setPropertyLength(finalPropertyLength);
  getMSARegions(d,"advanced");    
  setPShow(true);
}
else{
  setPShow(false);
  setPNoDataShow(true);
}    
let addaction = {
  "userid": userid,
  "org": Org,
  "subs_id": subId,
  "page": "Datahub",
  "widget": "Advanced Search",
  "actions":{    
    "actiontype": 'clicked',
  }
}
handleAddAction(addaction); 




      }
      else{
        let p = data.data;
        let finalData = p.all;
        setExportPropertyData(finalData);
        setPropertyDownloadReady(true);
        setPropDownloadToast(false);
        setExportPToast(true);
        setTokenExpired(false);
        setTimeout(function () {
          setExportPToast(false)
        }, 5000);
        let addaction = {
          "userid": userid,
          "org": Org,
          "subs_id": subId,
          "page": "Datahub",
          "widget": "Export For Property",
          "actions":{    
            "actiontype": 'clicked',
          }
        }
        handleAddAction(addaction);
      }
}  
    })
    .catch((err) =>{
      // let error = err.response.data.error;
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)
      // }
    });
  
  }
}
const getExportPropertyData = async () =>{
  getProperty("ExportClicked");
  } 

const onpropertyregionchange = (e) =>{
  e.preventDefault();
  setSelectedRegion(e.value)
  setSelectedMsa([]);
  setSelectedState([]);
  // setSelectedCounty([]);
  // setSelectedCities([]);
}
const onpropertymsachange = (e) =>{
  e.preventDefault();
  setSelectedMsa(e.value)
  setSelectedState([]);
  // setSelectedCounty([]);
  // setSelectedCities([]);
}
const onpropertystatechange = (e) =>{
  e.preventDefault();
  setSelectedState(e.value)
  // setSelectedCounty([]);
  // setSelectedCities([]);
}
const getmapurl = (newData) =>{ 
  let prop = newData?.map(d => d.property_name !== null ? d.property_name : null);
 let address = newData.map(d=> d.address !== null ? d.address : null);
 let lat = newData?.map(d => d.latitude !== null ? d.latitude : null);
 let longg = newData?.map(d=> d.longitude !== null ? d.longitude : null);
  let mapurl = '';
  let nearbymapurl = '';
  if (prop !== null && prop !== undefined) {
    let p = prop.toString();
    let formattedProp = p.replace(/\s/g, '+');
    nearbymapurl = `${formattedProp}`
  }
  if (address !== null && address !== undefined && prop === null) {
    let a = address.toString();
    let formattedAddress = a.replace(/\s/g, '+') ;
    nearbymapurl = `${formattedAddress}`
  }
  if (address !== null && address !== undefined && prop !== null) {
    let a = address.toString();
    let formattedAddress = a.replace(/\s/g, '+') ;
    nearbymapurl = `${nearbymapurl}+${formattedAddress}`
  }
  if(lat!== null && lat !== undefined && longg !== null && longg !== undefined )
  {
    mapurl = `&center=${lat},${longg}`
    nearbymapurl = `&center=${lat},${longg}`
  }
  setMapURL(mapurl);
  setNearbyMapURL(nearbymapurl);
  console.log(nearbymapurl)

}
const viewProperty = (e,rowData) => {
  console.log(rowData,"checkdatahub owner data")
  let newData = [];
  newData.push(rowData);
  setVisibleLeft(true);
  getmapurl(newData);
  newData?.map((d)=>{
    setLeadsInfo({
      nedl_prop_id:d.nedl_property_id,
      property_name:d.property_name,
      prop_address: d.address,
      prop_city: d.city,
      prop_state:d.state,
      prop_zip:d.zip,
      prop_county:d.document_recording_county,
      prop_in_area:d.Properties_in_Area,
      tot_unit_in_area:d.Total_Units_in_Area,
      tot_sqft_in_area:d.Total_SqFt_in_Area,
      current_rental_rate:d.latest_monthly_rent,
      current_avg_rental_per_sqft:d.average_rent_persqft,
      current_occupancy:d.latest_occupancy_rate,
      bed_count:d.bed_count,
      prev_quarter_occ_rate:d.prev_quarter_occ_rate,
      total_population_count:d.total_population_count,
      population_projection_in_5_years:d.Population_Projection_in_5_years,
      median_age_5_year_forecast:d.median_age_5_year_forecast,
      household_count:d.household_count,
      household_average_size:d.household_average_size,
      median_household_income:d.median_household_income,
      prop_id: d.tax_assessor_id,
      employed_population:d.employed_population,
      loan_amount:d.amount,
      loan_to_value:d.transfer_purchase_loan_to_value,
      term:d.term,
      interest_rate:d.term,
      owner_name:d.owner_name,
      owner_phone_number:d.owner_phone_number,
      owner_email_address:d.owner_email_address,
      owner_address:d.owner_address,
      owner_city:d.owner_city,
      owner_state:d.owner_state,
      owner_contact_name:d.owner_contact_name
  })
  });
  let addaction = {
  "userid": userid,
  "org": Org,
  "subs_id": subId,
  "page": "Datahub",
  "widget": "View Single Pager",
  "actions":{    
    "actiontype": 'clicked',
  }
}
handleAddAction(addaction); 
}
// const addProperty = (e,rowData) => {
//   e.preventDefault();
//   setTargetedLead(rowData);
//   setTargetedId(rowData.nedl_property_id);
//   if(rowData.nedl_property_id !== ""){
//     getLeads(nedl_property_id);
//   }else{
//     setIDToast(true);
//     setTimeout(function () {
//       setIDToast(false)
//     }, 5000);
//   }
// let addaction = {
//   "userid": userid,
//   "org": Org,
//   "subs_id": subId,
//   "page": "Datahub",
//   "widget": "Add To Leads",
//   "actions":{    
//     "actiontype": 'clicked',
//   }
// }
// handleAddAction(addaction); 
// }
// const getLeads = async (propid) =>{
//   await axios
//   .get(`${process.env.REACT_APP_ADD_LEADS}?id=${propid}&org=${lorg}`,
//   {
//       headers: {
//         Authorization: `Bearer ${userToken}`,
//       },
//     }
//   )
//   .then((data) => {
//     let i = data.data;
//     if(data.status === 200){
//      if(i?.length > 0){
//       setLeadToast(true);
//       setTimeout(function () {
//         setLeadToast(false)
//       }, 5000);
//       }else{
//      setDisplayBasic(true);
//      }
//      setTokenExpired(false)
//     }
//   })
//   .catch((err) =>{
//     let error = err.response.data.error;
//     if(error.message === "This token has expired"){
//      setTokenExpired(true);
//      sessionStorage.setItem('logged',false)
//     }
//   });
// }
// const acceptFunc = async (targetedLead,targetedId) => {
//   if(addNotes === ""){
//     setNotesToast(true);
//     setTimeout(function () {
//       setNotesToast(false)
//     }, 5000);
//   }
//   else{
//     const currentdate = moment().format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]");
//   let fs ={
//     "nedl_property_id": targetedId,
//     "probability": "Hot",
//     "created_date": currentdate,
//     "user_added": true,
//     "username": fname,
//     "organization": org
//   }
//   if(targetedLead.msa_name !== null && targetedLead.msa_name !== ""){
//       fs = {...fs,"msa_name": targetedLead.msa_name}
//   }   
//   if(targetedLead.msa_code !== null && targetedLead.msa_code !== ""){
//     fs = {...fs,"msa_code": ((targetedLead.msa_code)*1)}
// }  
// if(targetedLead.address !== null && targetedLead.address !== ""){
//   fs = {...fs,"address": targetedLead.address}
// }  
// if(targetedLead.city !== null && targetedLead.city !== ""){
//   fs = {...fs,"city": targetedLead.city}
// } 
// if(targetedLead.state !== null && targetedLead.state !== ""){
//   fs = {...fs,"state": targetedLead.state}
// }   
// if(targetedLead.zip !== null && targetedLead.zip !== ""){
// fs = {...fs,"zip": ((targetedLead.zip)*1)}
// }  
// if(targetedLead.zip_4 !== null && targetedLead.zip_4 !== ""){
// fs = {...fs,"zip_4": ((targetedLead.zip_4)*1)}
// }  
// if(targetedLead.latitude !== null && targetedLead.latitude !== ""){
// fs = {...fs,"latitude": ((targetedLead.latitude)*1)}
// }  
// if(targetedLead.longitude !== null && targetedLead.longitude !== ""){
//   fs = {...fs,"longitude": ((targetedLead.longitude)*1)}
// }   
// if(targetedLead.is_owner_occupied !== null && targetedLead.is_owner_occupied !== ""){
// fs = {...fs,"is_owner_occupied": targetedLead.is_owner_occupied}
// }  
// if(targetedLead.assessed_tax_year !== null && targetedLead.assessed_tax_year !== ""){
// fs = {...fs,"assessed_tax_year": ((targetedLead.assessed_tax_year)*1)}
// }  
// if(targetedLead.year_built !== null && targetedLead.year_built !== ""){
// fs = {...fs,"year_built": ((targetedLead.year_built)*1)}
// }  
// if(targetedLead.tax_assessor_last_sale_date !== null && targetedLead.tax_assessor_last_sale_date !== ""){
//   fs = {...fs,"tax_assessor_last_sale_date": targetedLead.tax_assessor_last_sale_date}
// }   
// if(targetedLead.last_sale_amount !== null && targetedLead.last_sale_amount !== ""){
// fs = {...fs,"last_sale_amount": ((targetedLead.last_sale_amount)*1)}
// }  
// if(targetedLead.building_sq_ft !== null && targetedLead.building_sq_ft !== ""){
// fs = {...fs,"building_sq_ft": ((targetedLead.building_sq_ft)*1)}
// }  
// if(targetedLead.lot_size_acre !== null && targetedLead.lot_size_acre !== ""){
// fs = {...fs,"lot_size_acre": ((targetedLead.lot_size_acre)*1)}
// }  
// if(targetedLead.lot_size_sq_ft !== null && targetedLead.lot_size_sq_ft !== ""){
//   fs = {...fs,"lot_size_sq_ft": ((targetedLead.lot_size_sq_ft)*1)}
// }   
// if(targetedLead.units_count !== null && targetedLead.units_count !== ""){
// fs = {...fs,"units_count": ((targetedLead.units_count)*1)}
// }  
// if(targetedLead.owner_name !== null && targetedLead.owner_name !== ""){
// fs = {...fs,"owner_name": targetedLead.owner_name}
// }  
// if(targetedLead.loan_amount !== null && targetedLead.loan_amount !== ""){
// fs = {...fs,"loan_amount": ((targetedLead.loan_amount)*1)}
// }  
// if(targetedLead.loan_maturity_date !== null && targetedLead.loan_maturity_date !== ""){
//   fs = {...fs,"loan_maturity_date": moment(targetedLead.loan_maturity_date).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}
// }   
// if(targetedLead.loan_origination_date !== null && targetedLead.loan_origination_date !== ""){
// fs = {...fs,"loan_origination_date": moment(targetedLead.loan_origination_date).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]")}
// }  
// if(targetedLead.years_to_mature !== null && targetedLead.years_to_mature !== ""){
// fs = {...fs,"years_to_mature": ((targetedLead.years_to_mature)*1)}
// }  
// if(targetedLead.last_sale_year !== null && targetedLead.last_sale_year !== ""){
// fs = {...fs,"last_sale_years": ((targetedLead.last_sale_year)*1)}
// }  
// if(targetedLead.owner_contact_name !== null && targetedLead.owner_contact_name !== ""){
//   fs = {...fs,"owner_contact_name": targetedLead.owner_contact_name}
// }   
// if(targetedLead.owner_email !== null && targetedLead.owner_email !== ""){
// fs = {...fs,"owner_email": targetedLead.owner_email}
// }  
// if(targetedLead.owner_address !== null && targetedLead.owner_address !== ""){
// fs = {...fs,"owner_address": targetedLead.owner_address}
// }  
// if(targetedLead.owner_city !== null && targetedLead.owner_city !== ""){
// fs = {...fs,"owner_city": targetedLead.owner_city}
// }  
// if(targetedLead.owner_state !== null && targetedLead.owner_state !== ""){
//   fs = {...fs,"owner_state": targetedLead.owner_state}
// }   
// if(targetedLead.owner_zip !== null && targetedLead.owner_zip !== ""){
// fs = {...fs,"owner_zip": targetedLead.owner_zip}
// }  
// if(targetedLead.owner_phone_number !== null && targetedLead.owner_phone_number !== ""){
// fs = {...fs,"owner_phone_number": targetedLead.owner_phone_number}
// }  
// if(targetedLead.latest_monthly_rent !== null && targetedLead.latest_monthly_rent !== ""){
// fs = {...fs,"latest_monthly_rent": ((targetedLead.latest_monthly_rent)*1)}
// }  
// if(targetedLead.latest_occupancy_rate !== null && targetedLead.latest_occupancy_rate !== ""){
//   fs = {...fs,"latest_occupancy_rate": ((targetedLead.latest_occupancy_rate)*1)}
// }   
// if(targetedLead.property_name !== null && targetedLead.property_name !== ""){
// fs = {...fs,"property_name": targetedLead.property_name}
// }  
// if(targetedLead.market !== null && targetedLead.market !== ""){
// fs = {...fs,"market": targetedLead.market}
// }  
// if(targetedLead.sub_market !== null && targetedLead.sub_market !== ""){
// fs = {...fs,"sub_market": targetedLead.sub_market}
// }  
// if(targetedLead.county !== null && targetedLead.county !== ""){
//   fs = {...fs,"county": targetedLead.county}
// }   
// if(targetedLead.owner !== null && targetedLead.owner !== ""){
// fs = {...fs,"owner": targetedLead.owner}
// }  
 
// console.log(fs,"new lead")
//     await axios
//       .post(`${process.env.REACT_APP_ADD_LEADS}/`, fs,
//       {
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//         },
//       }
//       )
//       .then((data) => {
      
//       if (data.status === 200){
//         console.log("Successfull", data.data);
//         let notes ={
//           "property_id":targetedId,
//           "userName":fname,
//           "notes":addNotes,
//           "org":org
//         }
    
//          axios
//           .post(`${process.env.REACT_APP_LEADS_NOTES}/`, notes,
//           {
//             headers: {
//               Authorization: `Bearer ${userToken}`,
//             },
//           }
//           )
//           .then((data) => {
          
//           if (data.status === 200){
//             setAddNotes("");
//             setDisplayBasic(false);
//           }
           
//           });
//         setAddLeadSuccessToast(true);
//         setTimeout(function () {
//           setAddLeadSuccessToast(false)
//         }, 5000);
//       } 
//       else{
//         setAddLeadFailedToast(true);
//         setTimeout(function () {
//           setAddLeadFailedToast(false)
//         }, 5000);
//     }
       
//       });
//     }
  
   
// };
const addTemplate = (rowData) => {
  return (
    <div className="market-item">
      <div className="addviewlead">
      {/* <i className="pi pi-plus-circle addicon"
       onClick={(e)=> addProperty(e,rowData)}
       ><span class="tooltiptext">Add To Leads</span>
</i> */}
      <i className="pi pi-eye viewicon" onClick={(e)=> viewProperty(e,rowData)}><span className="tooltiptext">View Details</span></i>
                          </div>
                            </div>
  );
};
 const formatCurrency = (value) => {
    return (value*1)?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
  };
  const  numberWithCommas = (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const dateTemplate = (option) => {
  return (
    <div className="market-item">
      {option.mortgage_due_date !== null ? 
      <div>{moment(option.mortgage_due_date).format("MM/DD/YYYY")}</div>
      :null}
    </div>
  );
};
const loanOriginationTemplate = (option) => {
  return (
    <div className="market-item">
      {option.document_recorded_date !== null ?
      <div>{moment(option.document_recorded_date).format("MM/DD/YYYY")}</div>
      :null}
    </div>
  );
};
const OccuTemplate = (option) => {
  return (
    <div className="market-item">
      {option.latest_occupancy_rate !== null ?
      <div>{`${option.latest_occupancy_rate}%`}</div>
      :
      null
      }
    </div>
  );
};
const RentTemplate = (option) => {
  return (
    <div className="market-item">
      {option.latest_monthly_rent !== null ?
<div style={{fontFamily:"Calibri"}} >{formatCurrency(option.latest_monthly_rent)}</div>
:null}
    </div>
  );
};
const LoanAmountTemplate = (option) => {
  return (
    <div className="market-item">
      {option.amount !== null ? 
      <div style={{fontFamily:"Calibri"}} >{formatCurrency(option.amount)}</div>
      : null
      }
    </div>
  );
};
const LastSaleDateTemplate = (option) => {
  return (
    <div className="market-item">
      {option.last_sale_date !== null ?
      <div>{moment(option.last_sale_date).format("MM/DD/YYYY")}</div>
      :null}
    </div>
  );
};
const LastSaleValueTemplate = (option) => {
  return (
    <div className="market-item">
      {option.last_sale_amount !== null ?
      <div style={{fontFamily:"Calibri"}} >{formatCurrency(option.last_sale_amount)}</div>
      :null}
    </div>
  );
};
const createPropertyTemplate = (propertyName) => (option) => {
  return (
    <div className="market-item">
      {option[propertyName] !== null ?
        <div className="ellipsis-text">{option[propertyName]}</div>
        : null
      }
    </div>
  );
};
const PropNameTemplate = createPropertyTemplate('property_name');
const MSANameTemplate = createPropertyTemplate('msa_name');
const RegionTemplate = createPropertyTemplate('region');
const AddressTemplate = createPropertyTemplate('address');
const CityTemplate = createPropertyTemplate('city');
const StateTemplate = createPropertyTemplate('state');
const ZipTemplate = createPropertyTemplate('zip');
const UnitsCountTemplate = createPropertyTemplate('units_count');
const OwnerNameTemplate = createPropertyTemplate('owner_name');

const sliderMinMaxOnChange = (setType, getState, setState, setMainState, minVal, maxVal) => (val) => {
  setState([
    setType === 'min' ? (val !== null && val > minVal ? val : minVal) : getState()[0],
    setType === 'max' ? (val !== null && val < maxVal ? val : maxVal) : getState()[1]
  ]);
  setMainState([
    setType === 'min' ? (val !== null && val > minVal ? val : minVal) : getState()[0],
    setType === 'max' ? (val !== null && val < maxVal ? val : maxVal) : getState()[1]
  ]);
};
const onMinUnitChange = sliderMinMaxOnChange('min', () => selectedPropUnits, setSelectedPropUnits, setPropUnits, minUnit, maxUnit);
const onMaxUnitChange = sliderMinMaxOnChange('max', () => selectedPropUnits, setSelectedPropUnits, setPropUnits, minUnit, maxUnit);
const onMinOccuChange = sliderMinMaxOnChange('min', () => selectedOccupancy, setSelectedOccupancy, setOccupancy, minOccu, maxOccu);
const onMaxOccuChange = sliderMinMaxOnChange('max', () => selectedOccupancy, setSelectedOccupancy, setOccupancy, minOccu, maxOccu);
const onMinRentChange = sliderMinMaxOnChange('min', () => selectedRentalRate, setSelectedRentalRate, setRentalRate, minRent, maxRent);
const onMaxRChange = sliderMinMaxOnChange('max', () => selectedRentalRate, setSelectedRentalRate, setRentalRate, minRent, maxRent);
const onMinSqftChange = sliderMinMaxOnChange('min', () => selectedBuildingSqft, setSelectedBuildingSqft, setBuildingSqft, minSqft, maxSqft);
const onMaxSqftChange = sliderMinMaxOnChange('max', () => selectedBuildingSqft, setSelectedBuildingSqft, setBuildingSqft, minSqft, maxSqft);
const onMinYearBuiltChange = sliderMinMaxOnChange('min', () => selectedYearBuilt, setSelectedYearBuilt, setYearBuilt, minYearBuilt, maxYearBuilt);
const onMaxYearBuiltChange = sliderMinMaxOnChange('max', () => selectedYearBuilt, setSelectedYearBuilt, setYearBuilt, minYearBuilt, maxYearBuilt);
const onMinLSAChange = sliderMinMaxOnChange('min', () => selectedLastSaleAmount, setSelectedLastSaleAmount, setLastSaleAmount, minLastSaleAmt, maxLastSaleAmt);
const onMaxLSAChange = sliderMinMaxOnChange('max', () => selectedLastSaleAmount, setSelectedLastSaleAmount, setLastSaleAmount, minLastSaleAmt, maxLastSaleAmt);
const onMinLoanAmtChange = sliderMinMaxOnChange('min', () => selectedLoanAmount, setSelectedLoanAmount, setLoanAmount, minLoanAmt, maxLoanAmt);
const onMaxLoanAmtChange= sliderMinMaxOnChange('max', () => selectedLoanAmount, setSelectedLoanAmount, setLoanAmount, minLoanAmt, maxLoanAmt);
const onMinLoanTermChange= sliderMinMaxOnChange('min', () => selectedLoanTerm, setSelectedLoanTerm, setLoanTerm, minTerm, maxTerm);
const onMaxLoanTermChange = sliderMinMaxOnChange('max', () => selectedLoanTerm, setSelectedLoanTerm, setLoanTerm, minTerm, maxTerm);
const onMinHCountChange = sliderMinMaxOnChange('min', () => selectedHouseholdCount, setSelectedHouseholdCount, setHouseholdCount, minHCount, maxHCount);
const onMaxHCountChange = sliderMinMaxOnChange('max', () => selectedHouseholdCount, setSelectedHouseholdCount, setHouseholdCount, minHCount, maxHCount);
const onMinHFCountChange = sliderMinMaxOnChange('min', () => selectedHouseholdFYFCount, setSelectedHouseholdFYFCount, setHouseholdFYFCount, minHFCount, maxHFCount);
const onMaxHFCountChange = sliderMinMaxOnChange('max', () => selectedHouseholdFYFCount, setSelectedHouseholdFYFCount, setHouseholdFYFCount, minHFCount, maxHFCount);
const onMinAvgHIChange = sliderMinMaxOnChange('min', () => selectedAvgHouseholdIncome, setSelectedAvgHouseholdIncome,setAvgHouseholdIncome, minAvgHI, maxAvgHI);
const onMaxAvgHIChange = sliderMinMaxOnChange('max', () => selectedAvgHouseholdIncome, setSelectedAvgHouseholdIncome,setAvgHouseholdIncome, minAvgHI, maxAvgHI);
const onMinMedHIChange = sliderMinMaxOnChange('min', () => selectedMedianHouseholdIncome, setSelectedMedianHouseholdIncome,setMedianHouseholdIncome, minMedHI, maxMedHI);
const onMaxMedHIChange = sliderMinMaxOnChange('max', () => selectedMedianHouseholdIncome, setSelectedMedianHouseholdIncome,setMedianHouseholdIncome, minMedHI, maxMedHI);
const addNotesDialogue = () => {
  return <>
    <Dialog header="Confirmation" visible={displayBasic} draggable={false} style={{ marginLeft: '70px', width: '30vw' }} onHide={() => setDisplayBasic(false)}>
     <>
     <div className="alignnotes">
      <p className="addchangestyle">Add Notes</p>
      <div className="enternotes">
      <InputText required value={addNotes} onChange={(e) => setAddNotes(e.target.value)} /><br />
        </div>
      </div>
      {notesToast ?
              <>
              <p className="error">Notes cannot be empty</p>
              </>
              : null}
     </>
      <div className="dealfooter">
      <Button className="dealbtn"
      //  onClick={() => acceptFunc(targetedLead,targetedId)}
       >Save</Button>
      <Button className="dealbtn" onClick={() => setDisplayBasic(false)}>Cancel</Button>
      </div>
    </Dialog>
  </>
}
const selectedRegionChange = () =>{
setSelectedRegion([]);
setSelectedMsa([]);
setSelectedState([]);
}
const selectedMsaChange = () =>{
  setSelectedMsa([]);
setSelectedState([]);
}
const selectedStateChange = () =>{
setSelectedState([]);
}
const createPanelHeaderTemplate = (clearFunction) => (options) => {
  const handleClick = () => {
    clearFunction();
  };
  return (
    <div className={options.className}>
      <div>{options.filterElement}</div>
      <i onClick={handleClick} className="pi pi-filter-slash clrfilter"></i>
      <div>{options.closeElement}</div>
    </div>
  );
};
const SearchOwnerPanelFooterTemplate = () => {
  if(showNoData === true){
   return ( 
    <div className="nodatastyle">
      No Match Found
    </div>
   );
  }
};
const getMSARegions = async (i,type) =>{
    await axios.get(`${process.env.REACT_APP_MSA_REGIONS}`,
      {
               headers: {
                 Authorization: `Bearer ${userToken}`,
               },
             }
    )
    .then((data) => {
      let dd = data.data;
      if(data.status === 200){
        if(i === undefined){
        getData(dd);
        }
      }
      if(i !== undefined){
        const nameToRegionMap = new Map();
        dd.forEach(item => {
          nameToRegionMap.set(item.msa_code, item.region); 
        });
        
        const updatedArray1 = i.map(item => ({
          ...item,
          region: nameToRegionMap.get(item.msa_code) || null
        }));
        if(type === "propertyAddress"){
          setPropertyAddressData(updatedArray1)
        }
        if(type === "advanced"){
          setPropertyData(updatedArray1)
        }
      }
  })
  }
  const getOwnerSegment = async () =>{
    await axios
    .get(`${process.env.REACT_APP_DATAHUB_OWNER}?owner_name=${searchOwnerText}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    .then((data) => {
      let u = data.data;
      let i = u.all;
      if(data.status === 200){
        let ownername = [];
        let name = i.map(a => a.owner_name)
        console.log(name,"ownernamecheck")
        i?.map(a => a.owner_name !== "" ? ownername.push(a) : null);
        const sortedList = ownername.sort((a, b) =>
        a.owner_name.localeCompare(b.owner_name));
        if(ownername.length > 0){
          setOwnerData(sortedList);
          setShowNoData(false);
        }else{
          setOwnerData([]);
          setShowNoData(true);
        }
        setTokenExpired(false);
        // getOwnerData(i[0]);
        // setOwnerData(i);
      }
    })
    .catch((err) =>{
      // let error = err.response.data.error;
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)
      // }
    });
  
    };
  const SearchOwnerPanelHeaderTemplate = (options) => {
    return (
      <div className={options.className}>
        <div className="rbmultiselectheader">
         <InputText className="rbheaderinput"
                    value={searchOwnerText}
                    onChange={(e) =>   setSearchOwnerText(e.target.value)}
                    />
                    <i onClick={(e) => setSearchOwnerText("")} className="pi pi-times rbheadertimes"></i>
                    </div>
                    <i onClick={(e)=>setSelectedOwner([])} className="pi pi-filter-slash clrfilter"></i>
        <i className="pi pi-search" style={{fontSize:"0.7rem",padding:"0 5px",cursor:"pointer",width:"5%"}} onClick={getOwnerSegment}/>
      </div>
    );
  };
  const getData = (i) =>{
    const [key, value] = Object.entries(subscription)[0];
    const entries = Object.entries(subscription);
    let datamsaregion = [];
    let rd = [];
    if(entries.length === 1){
      if(key === "ALL"){
        datamsaregion.push(i);
      }
      if(key === "MSA"){
        const msaList = value.reduce((acc, item) => {
          const matches = i.filter(obj => obj.msa_code === item);
          return acc.concat(matches);
        }, []);
        datamsaregion.push(msaList);
      }
    }
    if (entries.length > 1) {
      const [keynew, valuenew] = Object.entries(subscription)[1];
      if(key === "MSA" && keynew === "region"){
  const msaList = value.reduce((acc, item) => {
          const matches = i.filter(obj => obj.msa_code === item);
          return acc.concat(matches);
        }, []);
        datamsaregion.push(msaList);
      }
    }
    setMSARegions(datamsaregion[0]);

    if(entries.length === 1){
    if(key === "ALL"){
     i.map(a =>  rd.push({msa_name : a.msa_name, msa_code: a.msa_code, region : a.region , state : a.state}));
     let uniqueRegionName = [...new Map(i.map(regionData => [regionData.region, regionData])).values()];
     let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
     setRegionDetails(sortedRegionName);
     setMsaDetails(rd);
    }
    if(key === "MSA"){
      // setRegionDetails([]);
      const msaList = value.reduce((acc, item) => {
        const matches = i.filter(obj => obj.msa_code === item);
        return acc.concat(matches);
      }, []);
      setMsaDetails(msaList);
      rd = msaList;
      console.log(msaList,"msalist")
      let uniqueRegion = [...new Map(msaList.map(rData => [rData.region, rData])).values()];
      let sortedRegionName = uniqueRegion.sort((a, b) => a.region.localeCompare(b.region));
      setRegionDetails(sortedRegionName);

      }
  }
    if (entries.length > 1) {
      const [keynew, valuenew] = Object.entries(subscription)[1];
      if(key === "MSA" && keynew === "region"){
        const regionList = valuenew.reduce((acc, item) => {
          const matches = i.filter(obj => obj.region === item);
          return acc.concat(matches);
        }, []);
        const msaList = value.reduce((acc, item) => {
          const matches = i.filter(obj => obj.msa_code === item);
          return acc.concat(matches);
        }, []);
        setMsaDetails(msaList);
        rd = msaList;
        let uniqueRegionName = [...new Map(regionList.map(regionData => [regionData.region, regionData])).values()];
        let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
        setRegionDetails(sortedRegionName);

      }
      }
   
    let uniqueState = [...new Map(rd.map(sData => [sData.state, sData])).values()];
    let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
    let stateAbb = sortedState.map(d => d.state);
   let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
    setState(FinalStateData)
    // let uniqueCity = [...new Map(rd.map(cData => [cData.city, cData])).values()];
    // let sortedCity = uniqueCity.sort((a, b) => a.city.localeCompare(b.city));
    // setCity(sortedCity)
}
const getOnChangeData = () =>{
  const [key, value] = Object.entries(subscription)[0];
  const entries = Object.entries(subscription);

  let rd = [];
  if(entries.length === 1){
  if(key === "ALL"){
   msaRegions.map(a =>  rd.push({msa_name : a.msa_name, msa_code: a.msa_code, region : a.region , state : a.state}));
   let uniqueRegionName = [...new Map(msaRegions.map(regionData => [regionData.region, regionData])).values()];
   let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
   setRegionDetails(sortedRegionName);

   setMsaDetails(rd);
  }
  if(key === "MSA"){
    // setRegionDetails([]);
    const msaList = value.reduce((acc, item) => {
      const matches = msaRegions.filter(obj => obj.msa_code === item);
      return acc.concat(matches);
    }, []);
    setMsaDetails(msaList);
    rd = msaList;
    let uniqueRegion = [...new Map(msaList.map(rData => [rData.region, rData])).values()];
    let sortedRegionName = uniqueRegion.sort((a, b) => a.region.localeCompare(b.region));
    setRegionDetails(sortedRegionName);
    }
}
  if (entries.length > 1) {
    const [keynew, valuenew] = Object.entries(subscription)[1];
  if(key === "MSA" && keynew === "region"){
    const regionList = valuenew.reduce((acc, item) => {
      const matches = msaRegions.filter(obj => obj.region === item);
      return acc.concat(matches);
    }, []);
    const msaList = value.reduce((acc, item) => {
      const matches = msaRegions.filter(obj => obj.msa_code === item);
      return acc.concat(matches);
    }, []);
    rd = msaList;
    let uniqueRegionName = [...new Map(regionList.map(regionData => [regionData.region, regionData])).values()];
    let sortedRegionName = uniqueRegionName.sort((a, b) => a.region.localeCompare(b.region));
    setRegionDetails(sortedRegionName);
    console.log(sortedRegionName,"onchange for msa and region")

    setMsaDetails(msaList);
  }
}

if ((selectedMsa.length === 0 || selectedState.length === 0 || selectedCities.length === 0) && selectedRegion.length > 0) {
  selectedRegionData();
}
if(selectedMsa.length ===  0 && selectedRegion.length === 0){
  let uniqueState = [...new Map(rd.map(sData => [sData.state, sData])).values()];
    let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
    let stateAbb = sortedState.map(d => d.state);
   let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
   setState(FinalStateData);
  // let uniqueCity = [...new Map(rd.map(cData => [cData.city, cData])).values()];
  // let sortedCity = uniqueCity.sort((a, b) => a.city.localeCompare(b.city));
  // setCity(sortedCity)
}
if(selectedCities.length === 0 && selectedState.length === 0 && selectedRegion.length === 0 && selectedMsa.length > 0 || selectedCities.length === 0 && selectedState.length === 0 && selectedRegion.length > 0 && selectedMsa.length > 0)
{
  selectedMsaData();
}
}
const selectedRegionData = () => {
  if(selectedRegion.length > 0){ 
    const allMatches = selectedRegion.reduce((acc, m) => {
      const matches = msaRegions.filter((item) => item.region === m.region);
      return acc.concat(matches);
    }, []);
    let uniqueMSA = [...new Map(allMatches.map(mData => [mData.msa_name, mData])).values()];
    let sortedMSA = uniqueMSA.sort((a, b) => a.msa_name.localeCompare(b.msa_name));
    let uniqueState = [...new Map(allMatches.map(sData => [sData.state, sData])).values()];
    let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
    let stateAbb = sortedState.map(d => d.state);
   let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
   setState(FinalStateData);
  //  let uniqueCity = [...new Map(allMatches.map(cData => [cData.city, cData])).values()];
  //  let sortedCity = uniqueCity.sort((a, b) => a.city.localeCompare(b.city));
  //  setCity(sortedCity); 
    setMsaDetails(sortedMSA); 
}
else{
  getMSARegions();
}

};
const selectedMsaData = () => {
  if(selectedMsa.length > 0){ 
    const allMatches = selectedMsa.reduce((acc, m) => {
      const matches = msaRegions.filter((item) => item.msa_name === m.msa_name);
      return acc.concat(matches);
    }, []);
      let uniqueState = [...new Map(allMatches.map(sData => [sData.state, sData])).values()];
      let sortedState = uniqueState.sort((a, b) => a.state.localeCompare(b.state));
      let stateAbb = sortedState.map(d => d.state);
     let FinalStateData = statedata.filter(d => stateAbb.includes(d.abbreviation));
    //  let uniqueCity = [...new Map(allMatches.map(cData => [cData.city, cData])).values()];
    //  let sortedCity = uniqueCity.sort((a, b) => a.city.localeCompare(b.city));
    //  setCity(sortedCity); 
      setState(FinalStateData);   
  }
else{
  getOnChangeData();
}
};
const selectedStateData = () => {
  if(selectedState.length > 0){ 
    const allMatches = selectedState.reduce((acc, m) => {
      const matches = msaRegions.filter((item) => item.state === m.name);
      return acc.concat(matches);
    }, []);
      // let uniqueCity = [...new Map(allMatches.map(cData => [cData.city, cData])).values()];
      // let sortedCity = uniqueCity.sort((a, b) => a.city.localeCompare(b.city));
      // setCity(sortedCity);   
  }
else{
  getOnChangeData();
}
};

  return (
          <div className="Rbac rbprop">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) =>  {
              setActiveIndex(e.index);
              setVisibleLeft(false);
              setIDToast(false);
              setEnterAnyPropToast(false);
              setLeadToast(false);
              setAddLeadSuccessToast(false);
              setAddLeadFailedToast(false);
              setPropDownloadToast(false);
              setExportPToast(false);
              setSelectAnyFilterToast(false);
              setSearchValue([]);
              setPAShow(false);
              setPShow(false);
              setPropertySearchDetail("true");
              setSelectedState([]);
              setSelectedCities([]);
              setSelectedLoanAssociated([]);
              // setSelectedLoantoValue([]);
              setSelectedOwner([]);
              setOwnerData([]);
              setSelectedYearsToMature([]);
              setSelectedPropUnits([minUnit,maxUnit]);
              setSelectedOccupancy([minOccu,maxOccu]);
              setSelectedRentalRate([minRent,maxRent]);
              setSelectedBuildingSqft([minSqft,maxSqft]);
              setSelectedYearBuilt([minYearBuilt,maxYearBuilt]);
              setSelectedLastSaleAmount([minLastSaleAmt,maxLastSaleAmt]);
              setSelectedLoanAmount([minLoanAmt,maxLoanAmt]);
              setSelectedLoanTerm([minTerm,maxTerm]);
              setSelectedHouseholdCount([minHCount,maxHCount]);
              setSelectedHouseholdFYFCount([minHFCount,maxHFCount]);
              setSelectedAvgHouseholdIncome([minAvgHI,maxAvgHI]);
              setSelectedMedianHouseholdIncome([minMedHI,maxMedHI]);
              setPropUnits([]);
              setOccupancy([]);
              setRentalRate([]);
              setBuildingSqft([]);
              setYearBuilt([]);
              setLastSaleAmount([]);
              setLoanAmount([]);
              setLoanTerm([]);
              setHouseholdCount([]);
              setHouseholdFYFCount([]);
              setAvgHouseholdIncome([]);
              setMedianHouseholdIncome([]);
              // setSelectedInterestRate(null);
            }}
            className="gridtab">
              <TabPanel header="Property Search">  
               <div className="panelContent">
               <div className="propsearchcontainer">
                <div className="propsearchswitch">
                <div className={PropertySearchDetail === "true" ? "propsearchswitchstylechecked" :  "propsearchswitchstyle" }>
                    <label htmlFor="prop">Search by Property Name</label>
                <RadioButton inputId="prop" name="Search Property" value="true"
                     onChange={(e) => {setPropertySearchDetail(e.value);setSearchValue([]);setEnterAnyPropToast(false);setPAShow(false)}} checked={PropertySearchDetail === 'true'} />
                </div>
                <div className={PropertySearchDetail === "false" ? "propsearchswitchstylechecked":"propsearchswitchstyle"}>
                    <label htmlFor="address">Search by Address</label>
                <RadioButton inputId="address" name="Search Address" value="false"
                 onChange={(e) => {setPropertySearchDetail(e.value);setSearchValue([]);setEnterAnyPropToast(false);setPAShow(false)}} checked={PropertySearchDetail === 'false'} />
                </div>
                </div>
                <div className="propsearchinput">
                        <div className="p-inputgroup">
                            <InputText placeholder={PropertySearchDetail === "false" ? "Enter address" :"Enter property name" } value={searchvalue} onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <button onClick={() => getPropAddress()} className="searchbtn">SEARCH</button>
                    </div>
                </div>
               </div>
               {enterAnyPropToast === true && PropertySearchDetail === "true" ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Please enter property name" />
                            </div>
                         : enterAnyPropToast === true && PropertySearchDetail === "false" ?
                         <div style={{display:"flex",justifyContent:"flex-end"}}>
                         <ToastComponent severity="info" detail="Please enter address" />
                        </div>
                        : null}
                          {idtoast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Property ID Not Available" />
                          </div>
                         : null}
                          {leadToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Property already available as a Lead" />
                          </div>
                         : null}
                          {addLeadSuccessToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="success" detail="Property added as a Lead" />
                          </div>
                         : null}
                          {addLeadFailedToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="error" detail="Issue adding property as a Lead" />
                          </div>
                         : null}
               {pashow === true && propertyAddressData?.length > 0 && propAddressDataLoad === false ?
                          <>
              <div className="exportcount">
                         <label className="totalrecord">Total Records Found :<label className="totalrecordcount">{propertyAddressLength}</label></label>
                          </div>
              <div className="rbproptable">
                <div className="report-container">
                    <>
                     <DataTable scrollable scrollHeight="350px" className="reportlisttable" value={propertyAddressData}  >
                      <Column bodyClassName="reportlisttablestyle"   style={{ width: '50px',textAlign:"center"}} body={addTemplate}></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '200px' }} field="property_name" body={PropNameTemplate} header="Property Name"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '300px' }} field="msa_name" body={MSANameTemplate} header="MSA"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '100px' }} field="region" body={RegionTemplate} header="Region"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '200px' }} field="address"  body={AddressTemplate} header="Address"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '100px' }}  field="city"  body={CityTemplate} header="City"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ width: '100px' }} field="state"  body={StateTemplate} header="State"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ width: '100px' }} field="zip"  body={ZipTemplate} header="Zip"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ minWidth: '100px' }} field="latest_occupancy_rate"  body={OccuTemplate} header="Occupancy"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '100px'}}  field="latest_monthly_rent"  body={RentTemplate} header="Rental Rate"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ width: '100px' }} field="units_count"  body={UnitsCountTemplate} header="Units"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ minWidth: '200px' }}  field="amount"  body={LoanAmountTemplate}  header="Loan Value"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '200px' }} field="document_recorded_date"  body={loanOriginationTemplate} header="Loan Origination Date"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ minWidth: '200px' }} field="mortgage_due_date"  body={dateTemplate} header="Loan Maturity Date"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable  style={{ minWidth: '200px' }} field="last_sale_amount"  body={LastSaleValueTemplate}  header="Last Sale Amount"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable  style={{ minWidth: '200px' }} field="owner_name"  body={OwnerNameTemplate} header="Owner Name"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable  style={{ minWidth: '200px' }} field="last_sale_date"  body={LastSaleDateTemplate} header="Last Sale Date"></Column>
                      </DataTable>
                    <div className="paginationLead">
                    <Button  onClick={previousPropertyAddressclick} disabled={propoffset>= 100 ? false : true}>Previous</Button><Button onClick={nextPropertyAddressclick} disabled={propertyAddressData.length > 99 ? false : true}>Next</Button>
                    </div>
                    </>
                </div>
              </div>
              </> : 
              paNoDataShow === true ? <div className="nodata">No Data Available</div> 
              :propAddressDataLoad === true ?
              <Loader /> :null}
                </div>     
           {/* <Sidebar id="testId" visible={visibleLeft} fullScreen onHide={() => setVisibleLeft(false)} baseZIndex={1001} className="leads-sidebar" >
           <ViewDetailsDatahub leadsInfo={leadsInfo} mapURL={mapURL} formatCurrency={formatCurrency} nearbymapURL={nearbymapURL} userToken={userToken} numberWithCommas={numberWithCommas} />
          </Sidebar> */}
          {/* {addNotesDialogue()} */}
            </TabPanel>
            <TabPanel header="Advanced Search">
              {msaRegions?.length > 0 && msaDetails.length > 0 && state.length > 0 && minmaxData.length > 0 ?
              <>
                <div  className="rbpropcardContainer">
                <div className="rbpropcard">
                <div className="propertyContainerOne">
                          <h5>Demographics</h5>
                          <div className="dropdownPropertyBasic">
                            <div className="pbdropdownContent">
                              <label className="labelstyle">Region</label>
                              <MultiSelect
                                value={selectedRegion}
                                options={regionDetails}
                                onChange={onpropertyregionchange}
                                optionLabel="region"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(selectedRegionChange)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                maxSelectedLabels={1}
                                selectionLimit={10}
                              />
                            </div>
                            <div className="pbdropdownContent">
                              <label className="labelstyle">MSA</label>
                              <MultiSelect
                                value={selectedMsa}
                                options={msaDetails}
                                onChange={onpropertymsachange}
                                optionLabel="msa_name"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(selectedMsaChange)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                maxSelectedLabels={1}
                                selectionLimit={10}
                              />
                            </div>
                            <div className="pbdropdownContent">
                              <label className="labelstyle">State</label>
                              <MultiSelect
                                value={selectedState}
                                options={state.sort((a, b) =>
                                  a.name.localeCompare(b.name))}
                                onChange={onpropertystatechange}
                                optionLabel="name"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(selectedStateChange)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                selectionLimit={10}
                              />
                            </div>
                            {/* <div className="pbdropdownContent">
                              <label className="labelstyle">City</label>
                              <MultiSelect
                                value={selectedCities}
                                options={city}
                                onChange={(e) => setSelectedCities(e.value)}  
                                optionLabel="city"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(setSelectedCities)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                selectionLimit={10}
                              />

                            </div> */}
                          </div>
                        </div>
                </div>
                <div className="rbpropcard">
                <div className="propertyContainerOne">
                          <h5>Property Characteristics</h5>
                          <div className="dropdownPropertyBasic">
                            <div className="pbdropdownContent rbslider sliderinput">
                              <label className="labelstyle">Property Units</label>
                              <Tooltip className="slidertooltip" target=".slider>.p-slider-handle-start" content={`${selectedPropUnits[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".slider>.p-slider-handle-end" content={`${selectedPropUnits[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinUnitChange(e.value)}  />
                              <Slider min={minUnit} max={maxUnit} step={1} value={selectedPropUnits} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedPropUnits(e.value);setPropUnits(e.value)}} className="slider w-14rem" range />
                               <InputNumber placeholder="Max" onValueChange={(e) => onMaxUnitChange(e.value)} 
                               />
                              </div>
                            </div>
                            <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Occupancy</label>
                              <Tooltip className="slidertooltip" target=".occslider>.p-slider-handle-start" content={`${selectedOccupancy[0]}%`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".occslider>.p-slider-handle-end" content={`${selectedOccupancy[1]}%`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinOccuChange(e.value)}  />
                              <Slider min={minOccu} max={maxOccu} step={1} value={selectedOccupancy} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedOccupancy(e.value);setOccupancy(e.value)}} className="occslider w-14rem" range />
                             <InputNumber placeholder="Max" onValueChange={(e) => onMaxOccuChange(e.value)} />
                           </div>
                            </div>
                            <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Rental Rates</label>
                              <Tooltip className="slidertooltip" target=".rslider>.p-slider-handle-start" content={`$${selectedRentalRate[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".rslider>.p-slider-handle-end" content={`$${selectedRentalRate[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinRentChange(e.value)}  />
                              <Slider min={minRent} max={maxRent} step={1} value={selectedRentalRate} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedRentalRate(e.value);setRentalRate(e.value)}} className="rslider w-14rem" range />
                              <InputNumber placeholder="Max" onValueChange={(e)=> onMaxRChange(e.value)}/>
                           </div>      
                            </div>
                            <div className="pbdropdownContent rbslider sliderinput">
                              <label className="labelstyle">Building Area (sq. ft.)</label>
                              <Tooltip className="slidertooltip" target=".bsqftslider>.p-slider-handle-start" content={`${selectedBuildingSqft[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".bsqftslider>.p-slider-handle-end" content={`${selectedBuildingSqft[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinSqftChange(e.value)}  />
                              <Slider min={minSqft} max={maxSqft} step={1} value={selectedBuildingSqft} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedBuildingSqft(e.value);setBuildingSqft(e.value)}} className="bsqftslider w-14rem" range />
                               <InputNumber placeholder="Max" onValueChange={(e) => onMaxSqftChange(e.value)} 
                               />
                              </div>
                            </div>
                            <div className="pbdropdownContent rbslider sliderinput">
                              <label className="labelstyle">Year Built</label>
                              <Tooltip className="slidertooltip" target=".ybslider>.p-slider-handle-start" content={`${selectedYearBuilt[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".ybslider>.p-slider-handle-end" content={`${selectedYearBuilt[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinYearBuiltChange(e.value)}  />
                              <Slider min={minYearBuilt} max={maxYearBuilt} step={1} value={selectedYearBuilt} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedYearBuilt(e.value);setYearBuilt(e.value)}} className="ybslider w-14rem" range />
                               <InputNumber placeholder="Max" onValueChange={(e) => onMaxYearBuiltChange(e.value)} 
                               />
                              </div>
                            </div>
                            <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Last Sale Amount</label>
                              <Tooltip className="slidertooltip" target=".lsaslider>.p-slider-handle-start" content={`$${selectedLastSaleAmount[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".lsaslider>.p-slider-handle-end" content={`$${selectedLastSaleAmount[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinLSAChange(e.value)}  />
                              <Slider min={minLastSaleAmt} max={maxLastSaleAmt} step={1} value={selectedLastSaleAmount} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedLastSaleAmount(e.value);setLastSaleAmount(e.value)}} className="lsaslider w-14rem" range />
                              <InputNumber placeholder="Max" onValueChange={(e)=> onMaxLSAChange(e.value)}/>
                           </div>      
                            </div>
                          </div>
                        </div>
                </div>
                <div className="rbpropcard">
                <div className="propertyContainerOne">
                          <h5>Loan Characteristics</h5>
                          <div className="dropdownPropertyBasic">
                            <div className="pbdropdownContent">
                              <label className="labelstyle">Loan Associated</label>
                              <div className="loancheckbox">
                              <div className="loanbtn">
                              <RadioButton inputId="Yes" name="Yes" value="Yes" onChange={(e) => setSelectedLoanAssociated(e.value)} checked={selectedLoanAssociated === 'Yes'} />
                             <label htmlFor="Yes" className="rd-2">Yes</label>
                            </div>
                            <div className="loanbtn">
                              <RadioButton inputId="No" name="No" value="No" onChange={(e) => setSelectedLoanAssociated(e.value)} checked={selectedLoanAssociated === 'No'} />
                             <label htmlFor="No" className="rd-2">No</label>
                            </div>
                            </div>
                            </div>
                            <div className="pbdropdownContent">
                              <label className="labelstyle">Years-to-Mature</label>
                              <MultiSelect
                                value={selectedYearsToMature}
                                options={yearsToMature}
                                onChange={(e) => setSelectedYearsToMature(e.value)}  
                                optionLabel="name"
                                placeholder="Select"
                                panelHeaderTemplate={createPanelHeaderTemplate(setSelectedYearsToMature)}
                                filter
                                className="multiselect-custom"
                                resetFilterOnHide
                                maxSelectedLabels={2}

                              />
                            </div>
                            {/* <div className="pbdropdownContent">
                              <label className="labelstyle">Loan to Value</label>
                              <div className="loancheckbox">
                              <div className="loanbtn">
                              <RadioButton inputId="Yes" name="Yes" value="Yes" onChange={(e) => setSelectedLoantoValue(e.value)} checked={selectedLoantoValue === 'Yes'} />
                             <label htmlFor="Yes" className="rd-2">Yes</label>
                            </div>
                            <div className="loanbtn">
                              <RadioButton inputId="No" name="No" value="No" onChange={(e) => setSelectedLoantoValue(e.value)} checked={selectedLoantoValue === 'No'} />
                             <label htmlFor="No" className="rd-2">No</label>
                            </div>
                            </div>
                            </div> */}
                            <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Loan Amount</label>
                              <Tooltip className="slidertooltip" target=".lamtslider>.p-slider-handle-start" content={`$${selectedLoanAmount[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".lamtslider>.p-slider-handle-end" content={`$${selectedLoanAmount[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinLoanAmtChange(e.value)}  />
                              <Slider min={minLoanAmt} max={maxLoanAmt} step={1} value={selectedLoanAmount} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedLoanAmount(e.value);setLoanAmount(e.value)}} className="lamtslider w-14rem" range />
                              <InputNumber placeholder="Max" onValueChange={(e)=> onMaxLoanAmtChange(e.value)}/>
                           </div>      
                            </div>
                            <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Term ( In Months )</label>
                              <Tooltip className="slidertooltip" target=".ltslider>.p-slider-handle-start" content={`${selectedLoanTerm[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".ltslider>.p-slider-handle-end" content={`${selectedLoanTerm[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinLoanTermChange(e.value)}  />
                              <Slider min={minTerm} max={maxTerm} step={1} value={selectedLoanTerm} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedLoanTerm(e.value);setLoanTerm(e.value)}} className="ltslider w-14rem" range />
                              <InputNumber placeholder="Max" onValueChange={(e)=> onMaxLoanTermChange(e.value)}/>
                           </div>      
                            </div>
                            {/* <div className="pbdropdownContent sliderinput">
                              <div style={{display:"flex",alignItems:"center"}}>
                              <label className="labelstyle">Interest Rate<i className="pi pi-angle-right"></i></label>
                              <div className="slidercontainer">                             
                              <InputNumber placeholder="Value" onValueChange={(e)=> setSelectedInterestRate(e.value)}/>
                           </div> 
                           </div>     
                            </div> */}
                          </div>
                        </div>
                </div>
                <div className="rbpropcard">
                <div className="propertyContainerOne">
                          <h5>Owner Details</h5>
                          <div className="dropdownPropertyBasic">
                            <div className="pbdropdownContent">
                              <label className="labelstyle">Owner</label>
                              <MultiSelect
                                value={selectedOwner}
                                options={OwnerData}
                                onChange={(e) => setSelectedOwner(e.value)}  
                                optionLabel="owner_name"
                                placeholder="Select"
                                display="chip"
                                filter
                                panelHeaderTemplate={SearchOwnerPanelHeaderTemplate}
                                className="multiselect-custom"
                                maxSelectedLabels={1}
                                selectionLimit={5}
                                panelFooterTemplate={SearchOwnerPanelFooterTemplate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="propertyContainerOne">
                          <h5>Household</h5>
                          <div className="dropdownPropertyBasic">
                          <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Household Count</label>
                              <Tooltip className="slidertooltip" target=".hcslider>.p-slider-handle-start" content={`${selectedHouseholdCount[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".hcslider>.p-slider-handle-end" content={`${selectedHouseholdCount[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinHCountChange(e.value)}  />
                              <Slider min={minHCount} max={maxHCount} step={1} value={selectedHouseholdCount} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedHouseholdCount(e.value);setHouseholdCount(e.value)}} className="hcslider w-14rem" range />
                              <InputNumber placeholder="Max" onValueChange={(e)=> onMaxHCountChange(e.value)}/>
                           </div>      
                            </div>
                            <div className="pbdropdownContent rbslider sliderinput">
                              <label className="labelstyle">Household 5 Year Forecast Count</label>
                              <Tooltip className="slidertooltip" target=".hfcslider>.p-slider-handle-start" content={`${selectedHouseholdFYFCount[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".hfcslider>.p-slider-handle-end" content={`${selectedHouseholdFYFCount[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinHFCountChange(e.value)}  />
                              <Slider min={minHFCount} max={maxHFCount} step={1} value={selectedHouseholdFYFCount} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedHouseholdFYFCount(e.value);setHouseholdFYFCount(e.value)}} className="hfcslider w-14rem" range />
                               <InputNumber placeholder="Max" onValueChange={(e) => onMaxHFCountChange(e.value)} 
                               />
                              </div>
                            </div>
                            <div className="pbdropdownContent rbslider sliderinput">
                              <label className="labelstyle">Average Household Income</label>
                              <Tooltip className="slidertooltip" target=".ahislider>.p-slider-handle-start" content={`$${selectedAvgHouseholdIncome[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".ahislider>.p-slider-handle-end" content={`$${selectedAvgHouseholdIncome[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinAvgHIChange(e.value)}  />
                              <Slider min={minAvgHI} max={maxAvgHI} step={1} value={selectedAvgHouseholdIncome} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedAvgHouseholdIncome(e.value);setAvgHouseholdIncome(e.value)}} className="ahislider w-14rem" range />
                               <InputNumber placeholder="Max" onValueChange={(e) => onMaxAvgHIChange(e.value)} 
                               />
                              </div>
                            </div>
                            <div className="pbdropdownContent sliderinput">
                              <label className="labelstyle">Median Household Income</label>
                              <Tooltip className="slidertooltip" target=".mhislider>.p-slider-handle-start" content={`$${selectedMedianHouseholdIncome[0]}`} position="top" event="both" />
                              <Tooltip className="slidertooltip"  target=".mhislider>.p-slider-handle-end" content={`$${selectedMedianHouseholdIncome[1]}`} position="top" event="both" />
                              <div className="slidercontainer">
                              <InputNumber placeholder="Min" onValueChange={(e) => onMinMedHIChange(e.value)}  />
                              <Slider min={minMedHI} max={maxMedHI} step={1} value={selectedMedianHouseholdIncome} aria-labelledby="label_number"
                              onChange={(e) => {setSelectedMedianHouseholdIncome(e.value);setMedianHouseholdIncome(e.value)}} className="mhislider w-14rem" range />
                              <InputNumber placeholder="Max" onValueChange={(e)=> onMaxMedHIChange(e.value)}/>
                           </div>      
                            </div>
                          </div>
                        </div>
                </div>
                </div>
                <div className="search">
                        {propdownloadtoast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Waiting to be Exported..." />
                          </div>
                         : null}
                        {exportPtoast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="success" detail="Exported Successfully" />
                          </div>
                         : null}
                          {selectAnyFilterToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Select any filter" />
                          </div>
                         : null}     
                         {idtoast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Property ID Not Available" />
                          </div>
                         : null}
                          {leadToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="info" detail="Property already available as a Lead" />
                          </div>
                         : null}
                          {addLeadSuccessToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="success" detail="Property added as a Lead" />
                          </div>
                         : null}
                          {addLeadFailedToast === true ? 
                           <div style={{display:"flex",justifyContent:"flex-end"}}>
                           <ToastComponent severity="error" detail="Issue adding property as a Lead" />
                          </div>
                         : null}
                        <button onClick={getProperty}className="searchbtn">SEARCH</button>
                      <div className="export">
                           <button onClick={getExportPropertyData} className="searchbtn">EXPORT</button>
                             <CSVLink
                             data={ExportPropertyData}
                             filename="property.csv"
                             className="hidden"
                             ref={csvPropertyDownloadRef}
                             target="_blank" 
                             />
                             </div> 
                        </div>
           {pshow === true && propertyData?.length > 0 && advancedPropDataLoad === false ?
           <>
              <div className="exportcount">
                <label className="totalrecord">Total Records Found :<label className="totalrecordcount">{propertyLength}</label></label>
                          </div>
              <div className="rbproptable">
                <div className="report-container">
                    <>
                      <DataTable scrollable scrollHeight="350px" className="reportlisttable" value={propertyData} >
                      <Column bodyClassName="reportlisttablestyle"   style={{ width: '50px',textAlign:"center"}} body={addTemplate}></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '200px' }} field="property_name" body={PropNameTemplate} header="Property Name"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '300px' }} field="msa_name" body={MSANameTemplate} header="MSA"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '100px' }} field="region" body={RegionTemplate} header="Region"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '200px' }} field="address"  body={AddressTemplate} header="Address"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '100px' }}  field="city"  body={CityTemplate} header="City"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ width: '100px' }} field="state"  body={StateTemplate} header="State"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ width: '100px' }} field="zip"  body={ZipTemplate} header="Zip"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ minWidth: '100px' }} field="latest_occupancy_rate"  body={OccuTemplate} header="Occupancy"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '100px'}}  field="latest_monthly_rent"  body={RentTemplate} header="Rental Rate"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ width: '100px' }} field="units_count"  body={UnitsCountTemplate} header="Units"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ minWidth: '200px' }}  field="amount"  body={LoanAmountTemplate}  header="Loan Value"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable style={{ minWidth: '200px' }} field="document_recorded_date"  body={loanOriginationTemplate} header="Loan Origination Date"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable style={{ minWidth: '200px' }} field="mortgage_due_date"  body={dateTemplate} header="Loan Maturity Date"></Column>
                        <Column bodyClassName="reportlisttablestyle"  sortable  style={{ minWidth: '200px' }} field="last_sale_amount"  body={LastSaleValueTemplate}  header="Last Sale Amount"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable  style={{ minWidth: '200px' }} field="owner_name"  body={OwnerNameTemplate} header="Owner Name"></Column>
                        <Column bodyClassName="reportlisttablestyle" sortable  style={{ minWidth: '200px' }} field="last_sale_date"  body={LastSaleDateTemplate} header="Last Sale Date"></Column>
                      </DataTable>
                      <div className="paginationLead">
                        <Button  onClick={previousPropertyclick} disabled={offset>= 100 ? false : true}>Previous</Button><Button onClick={nextPropertyclick} disabled={propertyData.length > 99 ? false : true}>Next</Button>
                           </div>
                    </>
                </div>
              </div>
              </> : 
              pNoDataShow === true ?
              <div className="nodata">No Data Available</div>
              :advancedPropDataLoad === true ?
              <Loader />
                    :null}
              </> 
              : 
             <Loader />
               }
           {/* <Sidebar id="testId" visible={visibleLeft} fullScreen onHide={() => setVisibleLeft(false)} baseZIndex={1001} className="leads-sidebar" >
           <ViewDetailsDatahub leadsInfo={leadsInfo} mapURL={mapURL} formatCurrency={formatCurrency} nearbymapURL={nearbymapURL} userToken={userToken} numberWithCommas={numberWithCommas} />
          </Sidebar> */}
          {addNotesDialogue()}
            </TabPanel>
          </TabView> 
          <Sidebar id="testId" visible={visibleLeft} fullScreen onHide={() => setVisibleLeft(false)} baseZIndex={1001} className="leads-sidebar" >
           <ViewDetailsDatahub leadsInfo={leadsInfo} mapURL={mapURL} formatCurrency={formatCurrency} nearbymapURL={nearbymapURL} userToken={userToken} numberWithCommas={numberWithCommas} />
          </Sidebar>
        </div>     
  );
};

export default Datahub;