import axios from 'axios';

const useAddAction = (userToken) => {
  const handleAddAction = async (addaction) => {
    try {
      // const { actions } = addaction;
      const response = await axios.post(`${process.env.REACT_APP_ADD_ACTION}`, addaction,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log('Response:', response.data);
      return 'added successfully';
    } catch (error) {
      console.error('Error adding action:', error);
      return 'error adding action';
    }
  };

  return { handleAddAction };
};

export default useAddAction;