import React, { useState } from "react";
import "./newsfeed.css";
import ReadMoreReact from "read-more-react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

const Newsfeed = ({ feeds }) => {
  const[displayFeeds, setDisplayFeeds] = useState(false);
  const[targetedUrl, setTargetedUrl] = useState([]);
  const getReadMoreInfo = (data) =>{
    setTargetedUrl(data);
    setDisplayFeeds(true);
  }
  // const feedsdate = (rowData) => {
  //   return (
  //     <span>  
  //       <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{moment(rowData.date).format("MM/DD/YYYY")}</p>
  //     </span>
  //   );
  // };
  // const feedsDescription = (rowData) => {
  //   return (
  //     <span>  
  //       <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{rowData.description}</p>
  //     </span>
  //   );
  // };

  const viewFeedsDialog = () => {
    return <>
      <Dialog className="newsfeedsdialog"  keepInViewport header="Feeds" visible={displayFeeds} draggable={false} style={{ width: '85vw', height:"85vh"}} onHide={() => setDisplayFeeds(false)}>
       <>
        <iframe style={{minHeight:"100%",minWidth:"100%"}} src={targetedUrl}></iframe>
       </>
      </Dialog>
    </>
  } 

  return (
    <>
    <div className="newsfeed">
      
      {feeds.map((f) => (
        <div key={f.headline} className="feedcontent">
          <p className="ficon">
          <>
              <img src="\images\lattest\newsfeed.png" width='23px'/>
              </>
            {/* {f?.description?.length % 3 === 0 ? (
              <>
                <img src="\images\lattest\leased.svg" width='25px'/>
              </>
            ) : f.description.length % 2 === 0 ? (
              <>
              <img src="\images\lattest\occupancy rate new.svg" width='25px'/>
              </>
            ) : (
              <>
              <img src="\images\lattest\market growth.svg" width='25px'/>
              </>
            )} */}
            {/* {f.news_feed} */}
          </p>
          
          
          <p className="feedsp">
            {/* <ReadMoreReact text={f.news_feed} min={20} max={80} ideal={50} /> */}
            <div><label>{f.headline}</label><br />
            <label style={{color:"lightgrey",marginRight:"10px"}}>{moment(f.date).format("MM/DD/YYYY")}</label>
            <label  onClick={() => getReadMoreInfo(f.url)} style={{color:"#F98436",cursor:"pointer",textDecoration:"underline"}}>See more info</label></div>
            {/* <a href={f.url} target="_blank" rel="noopener noreferrer" style={{color:"#F98436",cursor:"pointer",textDecoration:"underline"}}>See more info</a></div> */}
          </p>
          
        </div>
      ))
      
   
      }



    </div>
    {viewFeedsDialog()}
    </>
  );
};
export default Newsfeed;
