import { Component } from 'react';

class FormMiddleware extends Component {
  handleUserInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  confirmPassword = (e) => {
    this.handleUserInput(e);
    const { password } = this.state;
    const { verificationcode } = this.state;
    const confirmPassword = e.target.value;
    if (password === confirmPassword && verificationcode) {
      this.setState({
        confirmPasswordValidate: true,
      });
    } else {
      this.setState({
        confirmPasswordValidate: false,
      });
    }
  };

  showMessage = (e) => {
    const message = document.querySelector('#message');
    message.style.display = 'block';
    this.setState({
      passwordValidate: false,
    });
  };

  hideMessage = (e) => {
    const message = document.querySelector('#message');
    message.style.display = 'none';
    this.setState({
      passwordValidate: true,
    });
  };

  validatePattern = (pattern, value, targetId) => {
    if (value.match(pattern)) {
      targetId.classList.remove('invalid');
      targetId.classList.add('valid');
      setTimeout(() => {
        targetId.style.display = 'none';
        return true;
      }, 2000);
    } else {
      targetId.classList.remove('valid');
      targetId.classList.add('invalid');
      setTimeout(() => {
        targetId.style.display = 'block';
        return false;
      }, 2000);
    }
  };

  validateLength = (length, value, targetId) => {
    if (value.length > length) {
      targetId.classList.remove('invalid');
      targetId.classList.add('valid');
      setTimeout(() => {
        targetId.style.display = 'none';
        return true;
      }, 2000);
    } else {
      targetId.classList.remove('valid');
      targetId.classList.add('invalid');
      setTimeout(() => {
        targetId.style.display = 'block';
        return false;
      }, 2000);
    }
  };

  passwordValidate = (e) => {
    this.handleUserInput(e);
    const value = e.target.value;
    const letter = document.getElementById("letter");
    const capital = document.getElementById("capital");
    const number = document.getElementById("number");
    const length = document.getElementById("length");
    const specialCharacter = document.getElementById("specialChareacter");
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    // eslint-disable-next-line
    const specialCharacterLetter = /[!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]+/;

    if (this.validatePattern(lowerCaseLetters, value, letter)) {
      this.hideMessage();
    } else {
      this.showMessage();
    }
    if (this.validatePattern(upperCaseLetters, value, capital)) {
      this.hideMessage();
    } else {
      this.showMessage();
    }
    if (this.validatePattern(numbers, value, number)) {
      this.hideMessage();
    } else {
      this.showMessage();
    }
    if (this.validatePattern(specialCharacterLetter, value, specialCharacter)) {
      this.hideMessage();
    } else {
      this.showMessage();
    }
    if (this.validateLength(8, value, length)) {
      this.hideMessage();
    } else {
      this.showMessage();
    }

    if (
      this.validatePattern(lowerCaseLetters, value, letter) &&
      this.validatePattern(upperCaseLetters, value, capital) &&
      this.validatePattern(numbers, value, number) &&
      this.validatePattern(specialCharacterLetter, value, specialCharacter) &&
      this.validateLength(8, value, length)
    ) {
      this.hideMessage();
    } else {
      this.showMessage();
    }
  };

  handleBlur(event) {
    const { name } = event.target;
    this.validateField(name);
    return;
  }

  validateField = (name) => {
    let isValid = false;
    if (name === "username") isValid = this.validateUserName();
    else if (name === "verificationcode") isValid = this.validateVerificationCode();
    else if (name === "password") isValid = this.validatePassword();
    else if (name === "confpassword") isValid = this.validatePasswordConfirmation();
    else if (name === 'businessname') isValid = this.validateBusinnesName();
    else if (name === 'businessdescription') isValid = this.validateBusinnesDescription();
    else if (name === 'companyname') isValid = this.validateCompanyName();
    else if (name === 'firstname') isValid = this.validateFirstName();
    else if (name === 'lastname') isValid = this.validateLastName();
    else if (name === 'email') isValid = this.validateEmail();
    else if (name === 'roleid') isValid = this.validateRoleName();
    return isValid;
  }

  validateRoleName = () => {
    let roleiderror = "";
    const value = this.state.roleid;
    if (value.trim() === "") roleiderror = "Role is required";

    this.setState({
      roleiderror
    });
    return roleiderror === "";
  }

  validateEmail = () => {
    let emailerror = "";
    // eslint-disable-next-line
    const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const value = this.state.email;
    if (value.trim() === "") emailerror = "Email is required";
    else if (!emailValidator.test(value)) emailerror = "Email is not valid";

    this.setState({
      emailerror
    });
    return emailerror === "";
  }

  validateFirstName = () => {
    let firstnameerror = "";
    const value = this.state.firstname;
    if (value.trim() === "") firstnameerror = "First is required";

    this.setState({
      firstnameerror
    });
    return firstnameerror === "";
  }

  validateLastName = () => {
    let lastnameerror = "";
    const value = this.state.lastname;
    if (value.trim() === "") lastnameerror = "Last is required";

    this.setState({
      lastnameerror
    });
    return lastnameerror === "";
  }
  validateCompanyName = () => {
    let companynameerror = "";
    const value = this.state.companyname;
    if (value.trim() === "") companynameerror = "Company is required";

    this.setState({
      companynameerror
    });
    return companynameerror === "";
  }

  validateBusinnesName = () => {
    let businessnameerror = "";
    const value = this.state.businessname;
    if (value.trim() === "") businessnameerror = "Business Unit Name Is Required";

    this.setState({
      businessnameerror
    });
    return businessnameerror === "";
  }

  validateBusinnesDescription = () => {
    let businessdescriptionerror = "";
    const value = this.state.businessdescription;
    if (value.trim() === "") businessdescriptionerror = "Description is required";

    this.setState({
      businessdescriptionerror
    });
    return businessdescriptionerror === "";
  }
  validateUserName = () => {
    let usernameerror = "";
    const value = this.state.username;
    if (value.trim() === "") usernameerror = <div style={{padding:"5px"}}><i class="pi pi-exclamation-circle"  ></i>  Username required</div>;

    this.setState({
      usernameerror
    });
    return usernameerror === "";
  }

  validateVerificationCode = () => {
    let verificationcodeerror = "";
    // const codevalidator = /^[0-9]{6,}$/;
    const value = this.state.verificationcode;
    if (value.trim() === "") verificationcodeerror = "Verification Code is required.";
    // else if (!codevalidator.test(value))
    //   verificationcodeerror = "Please enter Valid verification code";

    this.setState({
      verificationcodeerror
    });
    return verificationcodeerror === "";
  }

  validatePasswordConfirmation = () => {
    let passwordConfirmationError = "";
    if (this.state.password !== this.state.confpassword)
      passwordConfirmationError = "Password does not match Confirmation";

    this.setState({
      passwordConfirmationError
    });
    return passwordConfirmationError === "";
  }

  validatePassword = () => {
    let passworderror = "";
    const value = this.state.username;
    if (value.trim() === "") passworderror =
<div style={{padding:"5px"}}><i class="pi pi-exclamation-circle"  ></i>  Password required</div>;

    this.setState({
      passworderror
    });
    return passworderror === "";
  }

  recaptchaValidate = (e) => {
    if (e) {
      this.setState({ recaptchaValidate: true });
      this.setState({
        recaptchaerror: ''
      });
    } else {
      this.setState({ recaptchaValidate: false });
    }
  }

}

export default FormMiddleware;
