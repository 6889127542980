import React from 'react'
import "./Loader.css";
import { Skeleton } from 'primereact/skeleton';

const NormalLoader = ({ type, count, contentHeight }) => {
    const LoaderBlock = () => (
        <>
            {type === 'type1' && (
                <>
          <div className="titleloader">
                <Skeleton width="40%" height="100%" />
                </div>
                <div className="contentloader">
            <Skeleton width="100%" height={contentHeight} />
          </div>
              </>
            )}
            {type === 'type2' && (
              <div className="overviewl">
              <div className="titleloader">
              <Skeleton width="40%" height="100%" />
              <Skeleton width="40%" height="100%" />
              </div>
              <div className="contentloader">
              <Skeleton width="100%" height="100%" />
            </div>
          </div>
            )}
         </>
      );
    
      const ReusableLoader = () => (
        <>
          {[...Array(count)].map((_, index) => (
            <LoaderBlock key={index} />
          ))}
          </>
      );
    
      return (
        <ReusableLoader />
      );
    };
  
export default NormalLoader
