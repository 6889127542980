
import React, { useState, useEffect } from "react";
import "./loginold.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { PhotoService } from '../service/PhotoService';
import { Galleria } from 'primereact/galleria';
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import ToastComponent from "../components/AAANEWCOMPONENT/toast";
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from "primereact/progressspinner";

const VerifyMail = () => {
  const[usererror,setUserError]=useState(false);
  const[usererrormsg,setUserErrorMessage]=useState("");
    const[images,setImages] = useState([]);
    const[username,setUsername]=useState("");
    const[usernameplaceholder,setUsernamePlaceholder]=useState("");
    const[mailerrormsg,setMailErrorMsg]=useState(false);
    const[submitUser,setSubmitUser] = useState(false);
  const galleriaService = new PhotoService();
  const navigate = useNavigate();


   const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  const itemTemplate = (item) => {
    return <img className="gi" src={item.image} alt={item.name} />;
  }
  useEffect(() => {
    galleriaService.getImages().then(data => setImages(data));
}, [])
  const caption = (item) => {
    return (
      <React.Fragment>
        <h4 className="p-mb-2">{item.title}</h4>
        <p>{item.alt}</p>
      </React.Fragment>
    );
  }

  const validateUser = async (e) => {
    //validate user
    e.preventDefault();
    setSubmitUser(true);
    sessionStorage.setItem('useremail',username)
    const newItem = {
        "email": username,
      };
      if (username !== '') {
        await axios.post(`${process.env.REACT_APP_USER_VERIFY}`, newItem,
        ).then((data) => {
            if(data.status === 200){
                setUserError(false);
                setSubmitUser(false);
             if(data.data === false){
                navigate('/login')
             }else{
                navigate('/addpassword')
             }
            }
            return data
        })
        .catch((err) =>{
          let error = err.response.data.error;
          setUserError(true);
          setSubmitUser(false);
          if(error.message === "Invalid email / Email not available on Database")
          {setUserErrorMessage("Invalid email / Email not available");}
          else{setUserErrorMessage(error.message);}
        });
        setUsernamePlaceholder("");
      }else{
        setMailErrorMsg(true);
        setSubmitUser(false);
      }
  };


 

  return (
    <div className="mainHeader">
    <Row className="mainlogin">
    <Col className="loging">
      <Galleria className="logingallery" value={images} responsiveOptions={responsiveOptions} numVisible={1} circular autoPlay transitionInterval={5000}
        item={itemTemplate}
        // caption={this.caption}
        thumbnail={false} showThumbnails={false} showThumbnailNavigators={false} showIndicators changeItemOnIndicatorHover showIndicatorsOnItem   />
      </Col>
     
      <>
      <Col  className="loginf" xs={12} md={5}>
          <form onSubmit={(e) => validateUser(e)}>
            <div className="image">
              <img src="./images/newlogo.png" alt="logo" />
            </div>
            {usererror === true ? 
          <ToastComponent
          severity="error"
          detail={usererrormsg}
           /> : null }
            <div className="logintext">
            </div>
                <div className="p-field Reset">
                <InputText className="inputlogin"
                    name="username"
                    placeholder="Email Address"
                    id="username"
                    value={username}
                    required
                    onChange={(e) => {setUsername(e.target.value);setUsernamePlaceholder(e.target.value)}}
                    type="email"
                  />
               
             {mailerrormsg ? <label className="error">Enter Valid UserName</label> : null}
          </div>
          {submitUser ? 
          <div className="spinnerAlign">
                <ProgressSpinner
                  style={{ width: "40px", height: "40px" }}
                  strokeWidth="5"
                />
              </div> :
            <Button id="loginSubmit" className="btnLogin" type="submit" label="Submit" />
          }
            {/* <div className="poweredBy">
          <b>Powered by</b>
          <img className="goldimg" src="./images/FG.png" alt="Goldfinch" style={{width:"100px", height:"22px"}} />
           </div> */}
          </form>
        </Col>
      </>
     
        
    </Row>
  </div>
  );
}

export default VerifyMail;