import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css'; // Theme
import 'primereact/resources/primereact.min.css'; // Core CSS
import 'primeicons/primeicons.css'; // Icons
import 'primeflex/primeflex.css'; // PrimeFlex for responsiveness
import './Table.css';

const ReusableTable = ({data, columns, title, paginated, className, bodyClassName, scrollHeight, rows }) => {

  const renderCell = (rowData, column) => {
    if (column.body) {
      return column.body(rowData);
    }
    return rowData[column.field];
  };
 
 

  return (      
      <DataTable scrollable scrollHeight={scrollHeight} className={className} value={data} responsiveLayout="scroll" paginator={paginated} rows={rows}>
        {columns.map((col, index) => (
          <Column
          style={col.style}
            bodyClassName={bodyClassName}
            key={index}
            field={col.field}
            header={col.header}
            sortable={col.sortable !== undefined ? col.sortable : true}
            body={(rowData) => renderCell(rowData, col)}
          />
        ))}
      </DataTable>
  );
};

ReusableTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string, 
      header: PropTypes.string,
      sortable: PropTypes.bool,
      body: PropTypes.func,
    })
  ).isRequired,
  title: PropTypes.string,
  paginated: PropTypes.bool,
};

ReusableTable.defaultProps = {
  paginated: true,
};

export default ReusableTable;
