import { Outlet,useLocation, Navigate } from 'react-router-dom'
import Headertop from './components/AAANEWCOMPONENT/header';
import SideNav from './components/AAANEWCOMPONENT/sidenav';
import { useEffect, useState } from 'react';
import NoSubscription from './pages/MarketInsights/NoSubscription';
import axios from "axios";


const pageDetails = {
  '/market': { title: 'Market Intelligence', imageUrl: './images/lattest/market intelligence_active.svg' },
  '/leads': { title: 'Intelligent Leads', imageUrl: './images/lattest/leadsactive.svg' },
  '/user': { title: 'User Engagement', imageUrl: './images/lattest/user engagement_active.svg' },
  '/datahub': { title: 'Data Hub', imageUrl: './images/lattest/buyers n sellers_active.svg' },
  '/rbac': { title: 'Access Control', imageUrl: './images/lattest/Access_active.svg' },
};
const PrivateRoutes = () => {
  

  let auth =  sessionStorage?.getItem("logged")
  const userData = JSON.parse(sessionStorage.getItem("userdata"));
  const location = useLocation();
  const [subscriptionError, setSubscriptionError] = useState(true);
  const { title, imageUrl } = pageDetails[location.pathname] || {};
  useEffect(()=>{
    if(auth === "true"){
    let subauth = userData.data;
    if(subauth?.message === "Please add a Subscription" || subauth?.message === "Subscription Expired please renew"){
    setSubscriptionError(true);
    }else{
      setSubscriptionError(false);
    }
  }
  },[])
 
  useEffect(()=>{
    if(auth === "true"){
    getUserData();
  }
  },[title])
 
  const getUserData = async () =>{
    const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
    const userToken = userDetails.data.token;
     await axios.get(`${process.env.REACT_APP_USERME}`,
     {
       headers: {
         Authorization: `Bearer ${userToken}`,
       },
     }
     ).then((data) => {
      let i = data.data;
      if(i.message === "Please add a Subscription" || i?.message === "Subscription Expired please renew"){
          setSubscriptionError(true); 
          sessionStorage.setItem(
            "userdata",
            JSON.stringify(data)
          );
      }
     return data
     
     })
  } 
    return(
        auth === "true" ? 
      <div className='mainPageContainer'>
      <div className="lmenu">
      <SideNav />
      </div>
      <div className="pcontent">
        <Headertop pageTitle={title} imageUrl={imageUrl} />
     <div className="icontent">
    {subscriptionError === false ? 
   <Outlet /> :
   <NoSubscription />
          }
   </div>
          </div>
      </div>  
         : <Navigate to="/"/>
    )
}

export default PrivateRoutes