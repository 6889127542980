// useLogout.js
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const useremail = sessionStorage.getItem('useremail');
  const navigate = useNavigate();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const handleLogout = async () => {
    try {
      const newItem = { email: useremail };
      const response = await axios.post(`${process.env.REACT_APP_USER_LOGOUT}`, newItem);
      if (response.status === 200) {
        sessionStorage.setItem('logged', false);
        setIsLoggedOut(true);
        navigate('/');
      }
      else {
        setIsLoggedOut(false);
      }
    } catch (err) {
      let error = err.response.data.error;
          if(error.message === "The User is already logged out"){
            sessionStorage.setItem('logged',false)
            setIsLoggedOut(true);
            navigate('/');
          }
        
      console.error('Logout error:', err);
      setIsLoggedOut(false);
    }
  };

  return { handleLogout };
};

export default useLogout;
