import React from 'react'
import "./Loader.css";
import { Skeleton } from 'primereact/skeleton';

const FeedsLoader = () => {
  return (
                <div className="feedsl">
                  <div className="titleloader">
                    <Skeleton width="40%" height="100%"></Skeleton>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  <div className="feedsloader">
                    <Skeleton shape="circle" size="2rem"></Skeleton>
                    <div className="listloader">
                      <div className="listcontentloader"><Skeleton width="100%"></Skeleton></div>
                      <div><Skeleton className="listcontentloader" width="80%"></Skeleton></div>
                    </div>
                  </div>
                  
                </div>
  )
}

export default FeedsLoader
